import { withJsonFormsControlProps } from '@jsonforms/react';
import React from "react";
import ColourSelectComponent from "./ColourSelectComponent";

const ColourControl = ({ data, handleChange, path, label }) => (
    <ColourSelectComponent
        label={label}
        value={data}
        onChange={(newValue) => handleChange(path, newValue)}
    />
)

export default withJsonFormsControlProps(ColourControl);