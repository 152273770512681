import React, { useEffect, useState } from 'react';
import {
    Box, Collapse, IconButton, List, ListItem, ListItemText,
    MenuItem, TextField, Typography, useTheme, Chip
} from '@mui/material';
import {UserListSelect} from "../ListBoxes/UserSelect";


import TimeZoneService from "../Timezone/timezone";

import firebase from "firebase/compat/app";
import { ssUserSessionId, useFirebase } from "../App/App";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { httpsCallable } from "firebase/functions";


const userTypeScopes = {
    viewer: { name: 'Viewer', scopes: ['read'] },
    therapist: { name: 'Therapist', scopes: ['read', 'write','therapist'] },
    triage: { name: 'Triage', scopes: ['read', 'write','therapist','manage'] },
    admin: { name: 'Admin', scopes: ['read', 'write','delete','manage'] },
    developer: { name: 'Developer', scopes: ['read', 'write','delete','manage','developer'] }
};

const UserTypeSelect = ({ userType, onChange }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <TextField
                id="userTypeSelect"
                select
                label="User Type"
                value={userType}
                onChange={onChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
            >
                {Object.entries(userTypeScopes).map(([key, value]) => (
                    <MenuItem key={key} value={key}>{value.name}</MenuItem>
                ))}
            </TextField>
            <TextField
                label="Scopes"
                fullWidth
                variant="outlined"
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <Box sx={{ display: 'flex', gap: 1, whiteSpace: 'nowrap', p: 1 }}>
                            {userTypeScopes[userType].scopes.map(scope => (
                                <Chip key={scope} label={scope} />
                            ))}
                        </Box>
                    ),
                }}
            />
        </Box>
    );
};

const SessionInfo = ({ userId }) => {
    const [sessions, setSessions] = useState({});
    const [openSessionId, setOpenSessionId] = useState(null);

    useEffect(() => {
        if (!userId) return;
        const sessionsRef = firebase.database().ref(`/presence/${userId}/sessions`);
        const onSessionChange = sessionsRef.on('value', (snapshot) => {
            const sessionsData = snapshot.val();
            setSessions(sessionsData || {});
        });
        return () => sessionsRef.off('value', onSessionChange);
    }, [userId]);

    const handleClick = (sessionId) => {
        setOpenSessionId(openSessionId === sessionId ? null : sessionId);
    };

    const handleDeleteSession = async (sessionId) => {
        const sessionRef = firebase.database().ref(`/presence/${userId}/sessions/${sessionId}`);
        await sessionRef.update({ isValid: false });
    };

    const currentSessionId = sessionStorage.getItem(ssUserSessionId);

    return (
        <Box sx={{
            width: '100%',
            mt: 2,
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            position: 'relative',
        }}>
            <Typography
                variant="overline"
                component="div"
                sx={{
                    position: 'absolute',
                    top: -12,
                    left: 14,
                    backgroundColor: 'background.paper',
                    px: 1,
                }}
            >
                Active Sessions
            </Typography>
            <Box sx={{ maxHeight: '190px', overflowY: 'auto', margin: '10px', pt: 2 }}>
                <List component="nav" aria-labelledby="nested-list-subheader">
                    {Object.entries(sessions).map(([sessionId, sessionInfo]) => (
                        <React.Fragment key={sessionId}>
                            <ListItem button onClick={() => handleClick(sessionId)}>
                                <ListItemText primary={`${sessionInfo.os} - ${sessionInfo.browser}`} />
                                {currentSessionId !== sessionId && (
                                    <IconButton onClick={() => handleDeleteSession(sessionId)} sx={{ color: 'red' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                                {openSessionId === sessionId ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openSessionId === sessionId} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem><Typography variant="body2" sx={{ pl: 4 }}>SessionID: {sessionId}</Typography></ListItem>
                                    <ListItem><Typography variant="body2" sx={{ pl: 4 }}>Status: {sessionInfo.status}</Typography></ListItem>
                                    <ListItem><Typography variant="body2" sx={{ pl: 4 }}>Last Active: {new Date(sessionInfo.lastActive).toLocaleString()}</Typography></ListItem>
                                    <ListItem><Typography variant="body2" sx={{ pl: 4 }}>Activity: {sessionInfo.activity || 'none'}</Typography></ListItem>
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default function Profiles({selectedActiveUser,setSelectedActiveUser}) {
    const theme = useTheme();
    const [selectedTimeZone, setSelectedTimeZone] = useState('');
    const { functions } = useFirebase();
    const setUserTypeFunction = httpsCallable(functions, 'setUserType');

    const handleTimeZoneChange = async (event) => {
        setSelectedTimeZone(event.target.value);
        if (selectedActiveUser?.id) {
            try {
                await firebase.firestore().collection('users').doc(selectedActiveUser.id).update({ timeZone: event.target.value });
                console.log('Time zone updated successfully');
            } catch (error) {
                console.error('Error updating time zone:', error);
                setSelectedTimeZone(selectedActiveUser.timeZone);
            }
        }
    };

    const onActiveUserSelect = (user) => {
        setSelectedActiveUser(user);
        setUserType(user.userType);
    };

    const handleNameChange = (event) => {
        setSelectedActiveUser(prevState => ({ ...prevState, name: event.target.value }));
    };

    const timeZones = TimeZoneService.getAllTimeZones()
    const [userType, setUserType] = useState('viewer');
    const [pendingUserType, setPendingUserType] = useState(null);

    const updateUserType = async (newUserType) => {
        if (selectedActiveUser) {
            setPendingUserType(newUserType);

            // Get corresponding scopes for the new userType
            const newScopes = userTypeScopes[newUserType]?.scopes || [];

            try {
                // Call the cloud function to update userType
                const result = await setUserTypeFunction({
                    uid: selectedActiveUser.id,
                    userType: newUserType
                });
                console.log(result.data);

                // Update Firestore with the new scopes
                await firebase.firestore().collection('users').doc(selectedActiveUser.id).update({
                    scopes: newScopes
                });

                // Update local state
                setUserType(newUserType);
                setPendingUserType(null);
            } catch (error) {
                console.error("Error updating user type or scopes:", error);
                setPendingUserType(null);
            }
        }
    };

    const handleUserChange = (event) => {
        const newUserType = event.target.value;
        setUserType(newUserType);
        updateUserType(newUserType);
    };

    useEffect(() => {
        if (selectedActiveUser?.id) {
            const userRef = firebase.firestore().collection('users').doc(selectedActiveUser.id);
            const unsubscribe = userRef.onSnapshot(doc => {
                const updatedUserData = doc.data();
                if (updatedUserData) {
                    //setSelectedActiveUser(prevState => ({ ...prevState, ...updatedUserData }));
                    if (!pendingUserType) {
                        setUserType(updatedUserData.userType || 'viewer');
                    }

                    setSelectedTimeZone(updatedUserData.timeZone);
                }
            });
            return () => unsubscribe();
        }
    }, [selectedActiveUser, pendingUserType]);

    return (<div style={{display: 'flex', flex: 1}}>
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, position: 'relative'}}>
            <div style={{overflowY: 'auto', flex: 1, padding: '0 10px', maxHeight: 'calc(85vh - 60px - 75px)',
                border: '1px solid grey', borderRadius: '8px', overflow: 'none'
            }}>
                <Box position="relative">
                    <Box mt={4}>
                        <TextField label="Name" value={selectedActiveUser.name || ''} variant="filled" fullWidth sx={{ mb: 2 }} onChange={handleNameChange} />
                        <TextField label="Email" value={selectedActiveUser.email || ''} variant="filled" fullWidth sx={{ mb: 2 }} />
                        <UserTypeSelect userType={userType} onChange={handleUserChange} />
                        <TextField select label="Time Zone" value={selectedTimeZone || ''} onChange={handleTimeZoneChange} variant="outlined" fullWidth sx={{ mb: 2 }}>
                            {timeZones.map((tz) => (
                                <MenuItem key={tz.name} value={tz.name}>{tz.currentTimeFormat}</MenuItem>
                            ))}
                        </TextField>
                        <SessionInfo userId={selectedActiveUser.id} />
                    </Box>
                </Box>
                <Typography variant="h6" sx={{ position: 'absolute', top: -14, left: '10px', background: theme.palette.background.default, px: 1 }}>Details</Typography>
            </div>
        </div>
    </div>);
}
