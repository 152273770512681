import React, {useMemo} from "react";
import styled from "@emotion/styled";
import {TableCell} from "@mui/material";
import {getContrastingColor} from "../Color/color";
import {halfLuminance, RenderCodeBadge} from "./codebadge";
import Avatar from "@mui/material/Avatar";
import Timezone from "../Timezone/timezone";
import {DateTime} from "luxon";


export const CustomGridCell = styled(TableCell, {
    shouldForwardProp: (prop) =>
        !['isSelected', 'showTriangle', 'triangleColor', 'backgroundColor', 'color', 'cellIsToday', 'hour', 'localTimeZone', 'selectedTimeZone'].includes(prop),
})(({ theme, isSelected, showTriangle, triangleColor, backgroundColor, color, hour, localTimeZone, selectedTimeZone }) => {

    // Memoize timezone conversion to avoid unnecessary re-renders
    const cellHour = useMemo(() =>
            DateTime.fromObject({ hour }, { zone: localTimeZone })
                .setZone(selectedTimeZone).hour,
        [hour, localTimeZone, selectedTimeZone]);

    const isOutOfHours = (localTimeZone !== selectedTimeZone) && (cellHour >= 8 && cellHour < 20);

    return {
        position: "relative",
        backgroundColor: isOutOfHours ? color : backgroundColor,
        userSelect: "none", // Disable text selection
        cursor: "pointer",

        // Overlay when selected
        ...(isSelected?.selected && {
            "&::after": {
                content: '""',
                position: "absolute",
                inset: 0,
                backgroundColor: isSelected.source === "me" ? "rgba(0, 255, 0, 0.3)" : "rgba(220, 220, 220, 0.3)", // Selection overlay
                pointerEvents: "none",
                zIndex: 1,
            }
        }),

        // Triangle indicator
        ...(showTriangle && {
            "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                borderTop: `10px solid ${triangleColor || "rgba(255, 255, 0, 0.8)"}`, // Default triangle color
                borderLeft: "10px solid transparent",
                width: 0,
                height: 0,
                display: "block",
                zIndex: 2,
            }
        }),
    };
});

export function getCellStatus(hour, idx) {
    // Check if 'status' exists, is not undefined, and is an object
    if (hour && hour.status !== undefined && typeof hour.status === 'object') {
        // Check if the key 'idx' exists in the 'status' map
        if (hour.status.hasOwnProperty(idx)) {
            return hour.status[idx].codeId;
        }
    }
    // Return undefined if 'status' is undefined, not an object, or 'idx' is not a key in the map
    return undefined;
}


export const getCellData = (dayData, hour, codes, displayMode, isDarkMode) => {
    const hourData = dayData?.hours[hour] || {status: {}, notes: ''};
    const statusEntries = Object.entries(hourData.status); // Convert status map to an array of entries
    const baseColor = isDarkMode ? '#333' : '#fff';
    const baseTextColor = isDarkMode ? '#fff' : '#000';

    // Create an array of data objects, one for each status entry
    return statusEntries.map(([index, status]) => {
        const noteForHour = hourData.notes || '';
        const codeEntry = codes.find(c => c.id === status.codeId);
        if (codeEntry && codeEntry.emoji === '') {
            codeEntry.emoji = codeEntry.code;
        }

        const hasNotes = noteForHour.trim() && noteForHour.trim() !== "" && noteForHour.trim() !== "<p></p>";

        let hl = '';
        if (codeEntry && codeEntry.background) {
            hl = codeEntry.halfLuminance
        }

        return {
            code: codeEntry?.code || '',
            content: displayMode === 'code' ? codeEntry?.code : codeEntry?.emoji,
            backgroundColor: codeEntry?.background || baseColor,
            foregroundColor: codeEntry?.foreground || baseTextColor,
            description: codeEntry?.text || '',
            emoji: codeEntry?.emoji || '',
            hasNote: hasNotes,
            showTriangle: hasNotes,
            triangleColor: getContrastingColor(codeEntry?.background || baseColor) || baseTextColor,
            halfLuminance: hl,
            duration: status.minutes,
        };
    });
};


export const useGridCellData = (dayData, hour, codes, displayMode, isDarkMode, isHeatMap) => {
    return useMemo(() => getCellData(dayData, hour, codes, displayMode, isDarkMode, isHeatMap), [dayData, hour, codes, displayMode, isDarkMode, isHeatMap]);
};


function isToday(selectedDate, day) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based index
    const currentDate = today.getDate();
    const dateParts = selectedDate.split('-');
    const year = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1
    return currentYear === year && currentMonth === month && currentDate === day;
}


export const GridCell = React.memo(({
                                        selectedDate,
                                        day,
                                        dayData,
                                        hour,
                                        codes,
                                        displayMode,
                                        isDarkMode,
                                        onMouseDown,
                                        onMouseEnter,
                                        onMouseUp,
                                        onMouseLeave,
                                        onClick,
                                        onDoubleClick,
                                        isSelected,
                                        showTimes,
                                        cellSize,
                                        localTimeZone = "",
                                        dataTimeZone = "",
                                        zoom,
                                        onHoverChange
                                    }) => {

    const cellData = useGridCellData(dayData, hour, codes, displayMode, isDarkMode);
    const cellIsToday = isToday(selectedDate, day) && !isSelected.selected;

    const overlayStyle = cellIsToday ? {
        backgroundColor: 'rgba(0, 147, 203, 0.2)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    } : {};

    const commonStyles = {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };


    const allCodes = useMemo(() => cellData.map(data => ({
        code: displayMode === "code" ? data.code : data.emoji,
        duration: data.duration || 60,
        background: data.backgroundColor,
        foreground: data.foregroundColor,
        halfLuminance: data.halfLuminance,
    })).filter(c => c.code), [cellData, displayMode]);


    let cumulativeMinutes = 0;
    const selectedDateTime = DateTime.fromJSDate(new Date(selectedDate), {zone: localTimeZone});

    const tooltipContent = useMemo(() => {
        if (allCodes.length === 0) {
            let slotDateTime = selectedDateTime.set({
                day: day,
                hour: hour,
                minute: 0,
                second: 0,
                millisecond: 0
            });
            let slotDate = ""
            if (localTimeZone !== dataTimeZone) {
                slotDateTime = Timezone.convertTimeZone(slotDateTime, localTimeZone, dataTimeZone);

                slotDate = DateTime.fromISO(slotDateTime)
                    .set({day, hour, minute: 0, second: 0, millisecond: 0})
                    .setZone(dataTimeZone).toFormat('dd-MM');
            }
            const isDayDifferent = slotDateTime.day !== day;
            return (
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', userSelect: 'none'}}>
                    <span>{Timezone.formatTime(slotDateTime, dataTimeZone, localTimeZone)}</span>
                    {isDayDifferent && (<span>{slotDate}</span>)}
                </div>
            )
        }

        return allCodes.map((c, index) => {
            const foundCode = codes.find(code => code.code === c.code);
            const codename = foundCode?.text || "Unknown";

            let startHour = hour + Math.floor(cumulativeMinutes / 60);
            let startMinutes = cumulativeMinutes % 60;
            cumulativeMinutes += c.duration;
            let endHour = hour + Math.floor(cumulativeMinutes / 60);
            let endMinutes = cumulativeMinutes % 60;

            let startDateTime = selectedDateTime.set({
                hour: startHour,
                minute: startMinutes,
                second: 0,
                millisecond: 0
            });

            let endDateTime = selectedDateTime.set({
                hour: endHour,
                minute: endMinutes,
                second: 0,
                millisecond: 0
            });

            if (localTimeZone !== dataTimeZone) {
                startDateTime = Timezone.convertTimeZone(startDateTime, localTimeZone, dataTimeZone);
                endDateTime = Timezone.convertTimeZone(endDateTime, localTimeZone, dataTimeZone);
            }

            const copiedCodes = allCodes.map(entry => ({
                ...entry,
                code: "",
                background: "#FFFFFF"
            }));

            copiedCodes[index] = {...allCodes[index]};

            return (
                <div key={c.code + index}
                     style={{display: 'flex', alignItems: 'center', gap: '8px', userSelect: 'none'}}>
                    <RenderCodeBadge width={32} height={32} radius={14} codes={copiedCodes} hasBackground={false}/>
                    <span>{codename}</span>
                    <span>{`(${Timezone.formatTime(startDateTime, dataTimeZone, localTimeZone)} - ${Timezone.formatTime(endDateTime, dataTimeZone, localTimeZone)})`}</span>
                </div>
            );
        });
    }, [allCodes, codes, selectedDate, hour, localTimeZone, dataTimeZone]);

    let blockColor = "#f1f1f1"
    if (isDarkMode) {
        blockColor = "#555555"
    }

    const handleMouseEnter = (event) => {
        if (onMouseEnter) onMouseEnter(event);
        onHoverChange({ content: tooltipContent, anchorEl: event.currentTarget });
    };

    const handleMouseLeave = (event) => {
        if (onMouseLeave) onMouseLeave(event);
        onHoverChange(null);
    };

    const handleMouseUp = (event) => {
        if (onMouseUp) onMouseUp(event);
    };

    const handleMouseDown = (event) => {
        if (onMouseDown) onMouseDown(event);
    }

    const getBorderStyles = (isSelected) => {
        if (!isSelected.selected) {
            return {
                border: '1px dashed rgba(224, 224, 224, 1)', // Default dashed border for grid
            };
        }

        const inside = '1px dashed rgba(180, 180, 180, 0.5)'
        let outside = '2px solid black'

        if (isDarkMode) {
            outside = '2px solid white'
        }

        return {
            borderLeft: !isSelected.topNeighbor?.selected ? outside : inside,
            borderRight: !isSelected.bottomNeighbor?.selected ? outside : inside,
            borderTop: !isSelected.leftNeighbor?.selected ? outside : inside,
            borderBottom: !isSelected.rightNeighbor?.selected ? outside : inside,
        };
    };


    const slotDateTime = DateTime.fromISO(selectedDate)
        .set({day, hour, minute: 0, second: 0, millisecond: 0})
        .setZone(dataTimeZone);
    const formattedDateTime = slotDateTime.toFormat('dd-MM');

    const isDayDifferent = slotDateTime.day !== day;

    return (
        <>

            <CustomGridCell
                hour={hour}
                localTimeZone={localTimeZone}
                selectedTimeZone={dataTimeZone}
                backgroundColor='defaultColor'
                color={blockColor}

                sx={{
                    ...getBorderStyles(isSelected),
                    height: `${cellSize}px`,
                    maxWidth: `${cellSize}px`,
                    justifyContent: 'center',
                    alignItems: 'center',

                }}

                showTriangle={allCodes?.length && cellData[0].showTriangle}
                triangleColor={allCodes?.length && cellData[0].triangleColor}
                onMouseDown={handleMouseDown}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onDoubleClick={onDoubleClick}
                onClick={onClick}
                isSelected={isSelected}
                cellIsToday={cellIsToday}>
                {cellIsToday && <div style={overlayStyle}></div>}


                {allCodes.length === 0 && showTimes && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            userSelect: 'none',
                            whiteSpace: 'nowrap',
                            gap: `${0.5 * Math.max(zoom, 1)}rem` // Ensure minimum zoom of 1
                        }}
                    >
                    <span style={{
                        fontSize: '0.65rem',
                        margin: '0',
                        transform: `scale(${Math.max(zoom * 0.8, 1)})`, // Ensures minimum scale of 1
                        transformOrigin: 'center',
                        lineHeight: `${0.8 * Math.max(zoom, 1)}rem` // Ensure proper spacing
                    }}>
                      {Timezone.formatTime(slotDateTime, dataTimeZone, localTimeZone,false)}
                    </span>
                        {isDayDifferent && (
                            <span style={{
                                fontSize: '0.65rem',
                                margin: '0',
                                transform: `scale(${Math.max(zoom * 0.8, 1)})`, // Ensures minimum scale of 1
                                transformOrigin: 'center',
                                lineHeight: `${0.8 * Math.max(zoom, 1)}rem` // Adjust line height
                            }}>
                            {formattedDateTime}
                    </span>
                        )}
                    </div>
                )}


                {allCodes.length !== 0 && (
                    <div style={{
                        ...commonStyles,
                    }}>
                        <RenderCodeBadge width={cellSize} height={cellSize} radius={((cellSize) / 2) - 2}
                                         codes={allCodes} hasBackground={!isSelected.selected}/>
                    </div>
                )}


                {isSelected.selected && isSelected.source !== "me" && (
                    <div style={{
                        ...commonStyles,
                    }}>
                        <Avatar src={isSelected.photoURL} alt="User Profile"
                                sx={{width: 24, height: 24, opacity: 1.0}}></Avatar>
                    </div>
                )}
            </CustomGridCell>
        </>

    )
});


export const GridCellMemoized = React.memo(GridCell, (prevProps, nextProps) => {
    const changes = [];

    if (prevProps.day !== nextProps.day) {
        changes.push("day");
    }

    if (prevProps.hour !== nextProps.hour) {
        changes.push("hour");
    }

    if (prevProps.selectedDate !== nextProps.selectedDate) {
        changes.push("selectedDate");
    }

    if (prevProps.isSelected?.selected !== nextProps.isSelected?.selected) {
        changes.push("isSelected.selected");
    }

    if (prevProps.isSelected?.source !== nextProps.isSelected?.source) {
        changes.push("isSelected.source");
    }

    if (prevProps.cellSize !== nextProps.cellSize) {
        changes.push("cellSize");
    }

    if (prevProps.zoom !== nextProps.zoom) {
        changes.push("zoom");
    }

    if (prevProps.showTimes !== nextProps.showTimes) {
        changes.push("showTimes");
    }

    if (prevProps.dayData !== nextProps.dayData) {
        changes.push("dayData");
    }

    if (JSON.stringify(prevProps.codes) !== JSON.stringify(nextProps.codes)) {
        changes.push("codes");
    }

    if (prevProps.displayMode !== nextProps.displayMode) {
        changes.push("displayMode");
    }


    if (JSON.stringify(prevProps.isSelected) !== JSON.stringify(nextProps.isSelected)) {
        changes.push("isSelected (full object)");
    }

    if (changes.length > 0) {
        console.log(`GridCell re-rendering: day=${nextProps.day}, hour=${nextProps.hour}, changes:`, changes);
        return false; // Allow re-render
    }

  //  console.log(`GridCell skipped re-render: day=${nextProps.day}, hour=${nextProps.hour}`);
    return true; // Skip re-render
});

