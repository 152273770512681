import React, {useState} from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import {Button, ButtonGroup, Paper, ToggleButton, ToggleButtonGroup, Typography, useTheme} from "@mui/material";

import 'react-datepicker/dist/react-datepicker.css';
import './AdvancedDatePicker.css'

const CustomInput = React.forwardRef(({value, onClick}, ref) => (
    <Button variant="contained" onClick={onClick}>
        {value}
    </Button>
));

export const AdvancedDatePickerComponent = ({onDateChange, defaultStartDate = new Date(), defaultEndDate = new Date(), defaultMode = 'month'}) => {
    // Datepicker Control
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    // Mode control
    const [mode, setMode] = useState(defaultMode);

    function handleDateChange(dates, modeOverride = null) {
        let start, end;

        let activeMode = modeOverride ?? mode;

        if(activeMode === 'month') {
            start = dates;
            end = new Date(dates.getFullYear(), dates.getMonth()+1, 0);
        } else if (activeMode === 'week') {
            end = new Date(dates.getFullYear(), dates.getMonth(), dates.getDate()+6);
            start = dates;
        } else {
            start = dates[0];
            end = dates[1];
        }

        setStartDate(start);
        setEndDate(end);

        if(start != null && end != null) {
            onDateChange(start,end);
        }
    }

    function handleQuickRange(endOffsetDays) {
        const startDate = new Date();
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + endOffsetDays);

        setMode('range');
        handleDateChange([startDate,endDate], 'range');
    }

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";
    const dpcBackgroundCol = isDarkMode ? "#595959" : "#FAFAFA";
    const textColour = isDarkMode ? "white" : "black";

    const DatePickerContainer = ({ className, children }) => {
        return (
            <Paper elevation={5}>
                <div className={'datePickerContainer'} style={{backgroundColor: dpcBackgroundCol, color: textColour}}>
                    <div className={'datePickerSection'}>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>Quick Ranges</Typography>
                        <div className={'datePickerRangeButtonContainer'}>
                            <ButtonGroup
                                aria-label="past date ranges"
                                orientation="vertical"
                                color="primary"
                                variant="text"
                            >
                                <Button onClick={()=>{handleQuickRange(-7)}}>Last 7 Days</Button>
                                <Button onClick={()=>{handleQuickRange(-30)}}>Last 30 Days</Button>
                                <Button onClick={()=>{handleQuickRange(-90)}}>Last 90 Days</Button>
                            </ButtonGroup>
                            <ButtonGroup
                                aria-label="future date ranges"
                                orientation="vertical"
                                color="primary"
                                variant="text"
                            >
                                <Button onClick={()=>{handleQuickRange(7)}}>Next 7 Days</Button>
                                <Button onClick={()=>{handleQuickRange(30)}}>Next 30 Days</Button>
                                <Button onClick={()=>{handleQuickRange(90)}}>Next 90 Days</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className={'datePickerSection'}>
                        <div className={'datePickerModeButtonContainer'}>
                            <ToggleButtonGroup
                                value={mode}
                                exclusive
                                onChange={(event, newMode) => {if(newMode !== null) setMode(newMode);}}
                                aria-label="date selection mode"
                                color="primary"
                            >
                                <ToggleButton value="month">Month</ToggleButton>
                                <ToggleButton value="week">Week</ToggleButton>
                                <ToggleButton value="range">Range</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <CalendarContainer className={className}>
                            {children}
                        </CalendarContainer>
                    </div>
                </div>
            </Paper>
        );
    };

    return (
        <>
            <DatePicker
                selected={startDate}
                onChange={(dates) => {
                    handleDateChange(dates);
                }}
                startDate={startDate}
                endDate={endDate}
                dateFormat={mode === 'month' ? "MMMM yyyy" : "EEEE, MMMM d"}
                showMonthYearPicker={mode === 'month'}
                showWeekPicker={mode === 'week'}
                calendarContainer={DatePickerContainer}
                customInput={<CustomInput className="example-custom-input" />}
                selectsRange={mode === 'range'}
            />
        </>
    );
};
