import React, {useMemo} from "react";

function isTextOnly(str) {
    // Regex to match common text characters: letters, numbers, punctuation, and some symbols.
    const textRegex = /^[a-zA-Z0-9\s.,!?;:'"(){}[\]/\\@#$%^&*+=-_`~]+$/;
    return textRegex.test(str);
}

const Highlight = React.memo(({ id, x, y, rx, ry }) => (
    <>
        <defs>
            <radialGradient id={id} cx="50%" cy="50%" r="100%" fx="50%" fy="50%">
                <stop offset="0%" stopColor="#ffffff" stopOpacity="0.4" />
                <stop offset="100%" stopColor="#ffffff" stopOpacity="0" />
            </radialGradient>
        </defs>
        <ellipse cx={x} cy={y} rx={rx} ry={ry} fill={`url(#${id})`} />
    </>
));

const Badge = React.memo(({ cx, cy, r, color, hl }) => {
    const gradientId = `gradient-${cx}-${cy}`;
    return (
        <>
            <defs>
                <radialGradient id={gradientId} cx="50%" cy="50%" r="50%" fx="50%" fy="30%">
                    <stop offset="0%" stopColor={color} stopOpacity={1} />
                    <stop offset="85%" stopColor={color} stopOpacity={1} />
                    <stop offset="100%" stopColor={hl} stopOpacity={1} />
                </radialGradient>
            </defs>
            <circle cx={cx} cy={cy} r={r} fill={`url(#${gradientId})`} />
        </>
    );
});



export function RenderCodeBadge({width, height, radius, codes, hasBackground = true, hasKey = true}) {
    const highlightId = useMemo(() => `highlight-${Date.now()}`, []);
    const highlightOffset = radius * 0.5; // Offset from center towards top
    const highlightRadius = radius * 0.3; // Highlight radius as a fraction of main radius
    const strokeWidth = 1; // Width of the stroke for the border
    const circumRadius = Math.sqrt(width ** 2 + height ** 2) / 2;

    const svgStyles = useMemo(() => ({
        width: `${width}px`,
        height: `${height}px`,
        viewBox: `0 0 ${width} ${height}`,
    }), [width, height]);

    const clipPathId = useMemo(() => `clip-${Date.now()}`, []);
    const cornerRadius = height / 10;
    const centerx = width / 2
    const centery = height / 2
    const minFontSize = 5;

    const slices = useMemo(() => {
        let startAngle = 0;
        return codes.map((code, index) => {
            const endAngle = startAngle + (code.duration / 60) * 360;
            const slicePath = describeArc(centerx, centery, radius * 0.97, startAngle, endAngle);
            const midAngle = (startAngle + endAngle) / 2;
            startAngle = endAngle;

            const sliceArea = (Math.PI * ((radius * 0.97) ** 2)) * (code.duration / 360);
            let fontSize = Math.sqrt(sliceArea) / 1.6;
            fontSize = Math.max(fontSize, minFontSize);

            // Adjust midpoint based on font size
            const textOffset = fontSize * 0.3; // Moves larger text inward
            const adjustedRadius = radius * 0.6 - textOffset;
            const midPoint = polarToCartesian(centerx, centery, adjustedRadius, midAngle);

            return (
                <React.Fragment key={index}>
                    <path d={slicePath} fill={code.background} stroke={"#000"} strokeWidth={1} opacity={0.7} />
                    {hasKey &&( <text x={midPoint.x} y={midPoint.y} fill={code.foreground} fontSize={`${fontSize}px`} textAnchor="middle"  dominantBaseline="middle">
                        {code.code}
                    </text>)}
                </React.Fragment>
            );
        });
    }, [codes, radius, centerx, centery]);


    const slicesLarge = useMemo(() => {
        let startAngle = 0
        return codes.map((code, index) => {
        let endAngle = startAngle + (code.duration / 60) * 360;
        const slicePath = describeArc(centerx, centery, circumRadius, startAngle, endAngle);
        startAngle = endAngle;
        let color = code.halfLuminance;

        if (code.code === "") {
            color = "white"; // theme.palette.background.paper
        }

        return (
            <path
                key={`large-slice-${index}`}
                d={slicePath}
                fill={color}
                stroke={color}
                opacity={1.0}
                strokeWidth={strokeWidth}
            />
        );
    })},[circumRadius,codes, centerx, centery]);

    const singleBadgeMemo = useMemo(() => {
        if (codes.length !== 1 || codes[0].duration !== 60) return null;

        return (
            <svg {...svgStyles}>
                <defs>
                    <clipPath id={clipPathId}>
                        <rect x="0" y="0" width={width} height={height} rx={cornerRadius} ry={cornerRadius} />
                    </clipPath>
                </defs>
                <g clipPath={`url(#${clipPathId})`}>
                    {hasBackground && <circle cx={centerx} cy={centery} r={circumRadius} opacity={1.0} fill={codes[0].halfLuminance} />}
                    <Badge cx={centerx} cy={centery} r={radius * 0.97} color={'#fff'} hl={'#000'} />
                    <circle cx={centerx} cy={centery} r={radius} opacity={0.7} fill={codes[0].background} />
                    <text
                        x={centerx}
                        y={centery}
                        fill={codes[0].foreground}
                        fontSize={isTextOnly(codes[0].code) ? radius * 0.6 : radius * 1.2}
                        textAnchor="middle"
                        dominantBaseline="central"
                        userselect="none">
                        {codes[0].code}
                    </text>
                    <Highlight
                        id={highlightId}
                        x={centerx}
                        y={centery - highlightOffset}
                        rx={highlightRadius * 2.5}
                        ry={highlightRadius * 1.5}
                    />
                    <circle cx={centerx} cy={centery} r={radius} fill="none" stroke="black" strokeWidth="1" />
                </g>
            </svg>
        );
    }, [codes, width, height, radius, hasBackground, centerx, centery, circumRadius, highlightOffset, highlightRadius, highlightId, clipPathId]);


    const multipeCodesBadge = useMemo(() => (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <defs>
                <clipPath id={clipPathId}>
                    <rect x="0" y="0" width={width} height={height} rx={cornerRadius} ry={cornerRadius} />
                </clipPath>
            </defs>
            <g clipPath={`url(#${clipPathId})`}>
                {hasBackground && slicesLarge}
                <Badge cx={centerx} cy={centery} r={radius * 0.95} color={'#fff'} hl={'#000'} />
                {slices}
                <Highlight
                    id={highlightId}
                    x={centerx}
                    y={centery - highlightOffset}
                    rx={highlightRadius * 2.5}
                    ry={highlightRadius * 1.5}
                />
            </g>
        </svg>
    ), [width, height, cornerRadius, clipPathId, hasBackground, slicesLarge, slices, centerx, centery, highlightId, highlightOffset, highlightRadius, radius]);

    if (singleBadgeMemo) return singleBadgeMemo;

    return multipeCodesBadge;
}


export function describeArc(x, y, radius, startAngle, endAngle) {
    const toRadians = Math.PI / 180;
    const startRadians = (endAngle - 90) * toRadians;
    const endRadians = (startAngle - 90) * toRadians;

    const startX = x + radius * Math.cos(startRadians);
    const startY = y + radius * Math.sin(startRadians);
    const endX = x + radius * Math.cos(endRadians);
    const endY = y + radius * Math.sin(endRadians);

    const largeArcFlag = endAngle - startAngle > 180 ? "1" : "0";

    return [
        "M", x, y,
        "L", startX, startY,
        "A", radius, radius, 0, largeArcFlag, 0, endX, endY,
        "L", x, y,
        "Z"
    ].join(" ");
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const radians = (angleInDegrees - 90) * Math.PI / 180;
    return { x: centerX + radius * Math.cos(radians), y: centerY + radius * Math.sin(radians) };
}

