import React, {useEffect, useState, useRef} from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"; // Ensure firestore is also imported for compatibility
import {Button} from "../Styles/buttons";
import {Arrow} from "../Styles/menu";
import { FlatRectangleWithTitle, RaisedRectangle} from "../Styles/rectangle";
import {
    AppBar,
    Box,
    Collapse,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, Toolbar
} from "@mui/material";
import Typography from "@mui/material/Typography";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import CloseButton from "../Components/closeButton";
import CreateIcon from "@mui/icons-material/Create";


// Define styles as constants
const styles = {
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    thTd: {
        padding: '4px',
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
    },
    modal: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto', // Allows scrolling within the modal backdrop if needed
        backgroundColor: 'rgba(0,0,0,0.4)',
        display: 'flex',
        alignItems: 'center', // This centers the modal content vertically
        justifyContent: 'center', // And horizontally
        zIndex: 100,
    },
    modalContent: {
        backgroundColor: '#fefefe',
        margin: 'auto', // Centers the modal content; adjust as needed
        padding: '20px',
        border: '1px solid #888',
        width: '50%', // Adjust width as needed
        maxHeight: '90vh', // Prevents the modal from being taller than the viewport
        overflowY: 'auto', // Allows scrolling within the modal content itself
        boxSizing: 'border-box', // Ensures padding is included in the width calculation
    },
    closeButton: {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    pre: {
        backgroundColor: '#f4f4f4',
        padding: '10px',
    },
    comparisonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px',
    },
    comparisonColumn: {
        flex: '1',
        padding: '0 10px', // Adjust as needed for spacing
    },
    detailsRow: {
        display: 'none',
    },
    detailsContainer: {
        position: 'relative',
    },
    detailsSection: {
        position: 'relative',
        left: '0',
        right: '0',
        backgroundColor: '#fefefe',
        border: '1px solid #888',
        padding: '20px',
        marginTop: '10px',
    },

};

const actionColors = {
    create: 'lightgreen', // Light green
    delete: 'lightcoral', // Light red
    update: 'yellow', // Light blue
};


const tagDifferences = (before, after) => {
    let tagged = Array.isArray(after) ? [] : {};

    if (typeof before === 'undefined') {
        return after
    }

    // Tag differences in 'after'
    Object.keys(after).forEach(key => {

        if (before.hasOwnProperty(key)) {
            if (typeof after[key] === "object" && after[key] !== null && typeof before[key] === "object" && before[key] !== null) {
                const nestedTagged = tagDifferences(before[key], after[key]);
                // Only add nestedTagged if it's not empty to avoid unnecessary highlight tagging
                if (Object.keys(nestedTagged).length > 0) {
                    tagged[key] = nestedTagged;
                } else {
                    tagged[key] = after[key]; // Copy over as is if no changes
                }
            } else if (after[key] !== before[key]) {
                tagged[key] = {value: after[key], highlight: 'modified'}; // Tag modified items
            } else {
                // Copy the value directly if unchanged
                tagged[key] = after[key];
            }
        } else {
            tagged[key] = {value: after[key], highlight: 'added'}; // Tag added items
        }

    });

    // Tag deleted items in 'before'
    Object.keys(before).forEach(key => {
        if (!after.hasOwnProperty(key)) {
            tagged[key] = {value: before[key], highlight: 'deleted'}; // Tag deleted items
        }
    });

    return tagged;
};



const renderFields = (data, isTopLevel = true) => {
    const keys = Object.keys(data).sort((a, b) => a.localeCompare(b));
    return keys.map((key) => {
        const item = data[key];
        const isObject = typeof item === 'object' && item !== null && !Array.isArray(item);
        const hasHighlight = isObject && 'value' in item && 'highlight' in item;

        let fieldValue, highlightColor;
        if (hasHighlight) {
            fieldValue = item.value;
            highlightColor = item.highlight === 'modified' ? 'yellow'
                : item.highlight === 'added' ? 'lightgreen'
                    : item.highlight === 'deleted' ? 'lightcoral'
                        : null;
        } else {
            fieldValue = item;
        }

        const fieldValueComponent = isObject && !hasHighlight
            ? <Box sx={{ ml: 2 }}>{renderFields(fieldValue, false)}</Box>
            : <Box sx={{
                backgroundColor: highlightColor,
                overflow: 'auto',
                maxWidth: '100%',
            }}>
                <Typography component="div" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {JSON.stringify(fieldValue, null, 2)}
                </Typography>
            </Box>;

        return (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', my: 1, overflow: 'auto' }}>
                {isTopLevel || !isObject ? (
                    <Typography component="div" variant="body1" sx={{ fontWeight: 'bold', mr: 1, flexShrink: 0 }}>
                        {key}:
                    </Typography>
                ) : null}
                {fieldValueComponent}
            </Box>
        );
    });
};

const AuditLogViewer = ({onCloseWindow}) => {
    const [logs, setLogs] = useState([]);
    const [expandedLogs, setExpandedLogs] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const hasFetched = useRef(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [pageSnapshots, setPageSnapshots] = useState([]);
    const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalLogs, setTotalLogs] = useState(0);


    useEffect(() => {
        if (!hasFetched.current) {
            loadLogs();
            hasFetched.current = true;
        }
    });

    const loadLogs = (direction = 'next') => {
        setIsLoading(true);
        let query = firebase.firestore().collection('auditLogs').orderBy('timestamp', 'desc');

        if (direction === 'next') {
            if (pageSnapshots[currentPageIndex]) {
                query = query.startAfter(pageSnapshots[currentPageIndex]).limit(rowsPerPage);
            } else {
                query = query.limit(rowsPerPage);
            }
        } else if (direction === 'prev' && currentPageIndex > 0) {
            setIsMoreDataAvailable(true);
            setCurrentPageIndex(prev => prev - 1);
            query = firebase.firestore().collection('auditLogs')
                .orderBy('timestamp', 'desc')
                .endBefore(pageSnapshots[currentPageIndex - 1])
                .limitToLast(rowsPerPage);
        } else if (direction === 'reset') {
            query = query.limit(rowsPerPage);
        }

        query.get().then(snapshot => {
            if (!snapshot.empty) {
                const newLogs = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setLogs(newLogs);

                if (direction === 'next') {
                    setIsMoreDataAvailable(snapshot.docs.length === rowsPerPage);
                    const newSnapshots = [...pageSnapshots];
                    if (snapshot.docs.length > 0) {
                        newSnapshots[currentPageIndex + 1] = snapshot.docs[snapshot.docs.length - 1];
                    }
                    setPageSnapshots(newSnapshots);
                    setCurrentPageIndex(prev => prev + 1);

                } else if (direction === 'reset') {
                    setPageSnapshots([snapshot.docs[snapshot.docs.length - 1]]);
                    setCurrentPageIndex(0);
                }
            } else {
                if (direction === 'reset') {
                    setLogs([]);
                    setPageSnapshots([]);
                    setCurrentPageIndex(0);
                }
            }

            setIsLoading(false);
        });
    };

    const toggleDetails = (logId) => {
        setExpandedLogs(prevState => ({
            ...prevState,
            [logId]: !prevState[logId],
        }));
    };

    const handleNext = (e) => {
        loadLogs('next');
    };

    const handlePrevious = (e) => {
        loadLogs('prev');
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setCurrentPage(0); // Reset back to the first page
    };


    const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
            loadLogs('prev')
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
            loadLogs('next')
        };

        return (
            <div style={{ flexShrink: 0, marginLeft: 2.5 }}>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
            </div>
        );
    };


    const [openRowId, setOpenRowId] = useState(null);

    return (
        <Paper className="window-container" sx={{overflow:'auto',width: '90%', height:'90%',padding: 2,maxWidth:'90%',userSelect: 'none'}}>
            <AppBar position="static">
            <Toolbar>
                <CreateIcon/>
                <Grid container alignItems="center" padding={1} spacing={2}>
                    <Grid item sx={{flexGrow: 1}}>
                        <Typography variant="h6">
                            Logs
                        </Typography>
                    </Grid>
                    <Grid item>
                     <CloseButton onCloseWindow={onCloseWindow}/>
                    </Grid>
                </Grid>
            </Toolbar>
            </AppBar>
            <TableContainer component={Paper} sx={{height:'80%'}}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>No.</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Collection</TableCell>
                            <TableCell>Document ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs.map((log, index) => {
                            const isRowOpen = openRowId === log.id;
                            return (
                                <React.Fragment key={log.id}>
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <TableCell>
                                            <IconButton aria-label="expand row" size="small" onClick={() => setOpenRowId(isRowOpen ? null : log.id)}>
                                                {isRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {currentPage * rowsPerPage + index + 1}
                                        </TableCell>
                                        <TableCell>{new Date(log.timestamp.seconds * 1000).toLocaleString()}</TableCell>
                                        <TableCell>{log.action}</TableCell>
                                        <TableCell>{log.collection}</TableCell>
                                        <TableCell>{log.docId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
                                                <Box sx={{margin: 1}}>
                                                 <ExpandedRowContent log={log}></ExpandedRowContent>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={10000} // Total number of items
                rowsPerPage={rowsPerPage} // Rows per page
                page={currentPage} // Current page
                onPageChange={handleChangePage} // Handle page change
                onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
                ActionsComponent={TablePaginationActions} // Use the custom actions component
                // Optionally hide rows per page selector and other elements you don't need
                rowsPerPageOptions={[]} // Hide rows per page selector
                labelDisplayedRows={() => ''} // Hide row display label
            />
        </Paper>
    );
};


const ExpandedRowContent = ({ log }) => {
    const beforeRef = useRef(null);
    const afterRef = useRef(null);
    const processedData = tagDifferences(log.before, log.after);

    // Sync scroll positions
    useEffect(() => {
        const syncScroll = (e, syncedRef) => {
            const { scrollTop } = e.target;
            if (syncedRef.current) {
                syncedRef.current.scrollTop = scrollTop;
            }
        };

        const beforeEl = beforeRef.current;
        const afterEl = afterRef.current;

        if (beforeEl && afterEl) {
            beforeEl.addEventListener('scroll', (e) => syncScroll(e, afterRef));
            afterEl.addEventListener('scroll', (e) => syncScroll(e, beforeRef));
        }

        // Cleanup
        return () => {
            if (beforeEl && afterEl) {
                beforeEl.removeEventListener('scroll', (e) => syncScroll(e, afterRef));
                afterEl.removeEventListener('scroll', (e) => syncScroll(e, beforeRef));
            }
        };
    }, []);

    return (
        <Box sx={{ margin: 1 }}>
            <Box sx={{ display: 'flex', width: '100%', overflowX: 'auto', maxHeight: '300px' }}>
                {log.action !== 'create' && (
                    <Paper elevation={3} sx={{ width: '50%', overflowY: 'auto', m: 1, p: 1 }} ref={beforeRef}>
                        <Typography variant="subtitle1" gutterBottom>
                            Before:
                        </Typography>
                        {log.before && <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{renderFields(log.before)}</Box>}
                    </Paper>
                )}
                <Paper elevation={3} sx={{ width: '50%', overflowY: 'auto', m: 1, p: 1 }} ref={afterRef}>
                    <Typography variant="subtitle1" gutterBottom>
                        After:
                    </Typography>
                    <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{renderFields(processedData)}</Box>
                </Paper>
            </Box>
        </Box>
    );
};






export default AuditLogViewer;


