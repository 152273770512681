import React, {useEffect, useRef} from 'react';
import {DataLoadingState, useUsers} from './userService';
import {Box, Chip, Typography} from '@mui/material';
import {updateUserSelected} from "../App/App";
import {isUserOnline, useOnlineUsers, userLastActive} from "../Presence/presence";
import BuildIcon from '@mui/icons-material/Build';
import timezone from "../Timezone/timezone";
import {OnlineAvatar} from "../Users/OnlineAvatar";
import {filterUsersByScope} from "../Users/userUtils";
import GenericSelector, {GenericListSelector} from "./GenericSelector";

function renderOption(user, onlineUsers, baseTimezone = null, size = 'small'){
    let hasTimeOffset = baseTimezone != null && user.timeZone != null && user.timeZone !== baseTimezone;
    let tzOffset = null;
    let isDSTActive = false
    if(hasTimeOffset) {
        tzOffset = timezone.getTimezoneOffset(baseTimezone, user.timeZone);
        isDSTActive = timezone.isDSTActive(user.timeZone)
    }

    return (<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
        <OnlineAvatar
            user={user}
            online={isUserOnline(user, onlineUsers)}
            lastActive={userLastActive(user, onlineUsers)}
            size={size}
        />

        <Typography noWrap sx={{ display: 'flex', alignItems: 'center' }}>
            {user?.name}
            {user?.scopes?.includes('developer') && (
                <BuildIcon fontSize="small" sx={{ marginLeft: 0.5, verticalAlign: 'middle' }} />
            )}
        </Typography>

        {hasTimeOffset && tzOffset && (<>
            <div style={{flex: '1 1 auto'}}></div>
            <Chip label={`${tzOffset.length > 6 ? tzOffset.substring(0, 6) : tzOffset}${isDSTActive ? " (DST)" : ""}`} />
        </>)}
    </Box>);
}

export function NoDefaultsUserDropdownSelect({onSelectUser, selectedUser, scopeFilter, baseTimezone = null, tooltipText = "Select the therapist, whose data you would like to see.", inputLabel = "Therapist"}){
    const multiselect = Array.isArray(selectedUser);

    const { users, loadingState } = useUsers(true);
    const filteredUsers = filterUsersByScope(users, scopeFilter)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const {onlineUsers} = useOnlineUsers();

    const onSelectionChanged = (selection) => {
        if(!multiselect){
            updateUserSelected(selection.email);
        }
    };

    return (<GenericSelector
        allValues={filteredUsers}
        selectedValues={selectedUser}
        setSelectedValues={onSelectUser}
        renderOption={(u) => renderOption(u, onlineUsers, baseTimezone)}
        changeObserver={onSelectionChanged}
        tooltipText={tooltipText}
        inputLabelText={inputLabel}
    />);
}

export function UserDropdownSelect({onSelectUser, selectedUser, scopeFilter, baseTimezone = null, tooltipText = "Select the therapist, whose data you would like to see.", inputLabel = "Therapist"}){
    const multiselect = Array.isArray(selectedUser);

    const { users, loadingState } = useUsers(true);
    const filteredUsers = filterUsersByScope(users, scopeFilter)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const {onlineUsers} = useOnlineUsers();

    const defaultApplied = useRef(false); // Track if default selection has been applied

    useEffect(() => {
        if (loadingState === DataLoadingState.LOADED_REMOTE && filteredUsers.length > 0 && !defaultApplied.current) {

            let selection = null;
            if(multiselect){
                selection = filteredUsers.filter(t => selectedUser.includes(t.id))
            } else {
                if(filteredUsers.some(t => t.id === selectedUser.id)) {
                    selection = selectedUser;
                } else {
                    selection = filteredUsers[0];
                }
            }

            onSelectUser(selection);
            defaultApplied.current = true;

            if(!multiselect){
                updateUserSelected(selection.email);
            }
        }
    }, [users, loadingState, scopeFilter, filteredUsers, onSelectUser]);

    const onSelectionChanged = (selection) => {
        if(!multiselect){
            updateUserSelected(selection.email);
        }
    };

    return (<GenericSelector
        allValues={filteredUsers}
        selectedValues={selectedUser}
        setSelectedValues={onSelectUser}
        renderOption={(u) => renderOption(u, onlineUsers, baseTimezone)}
        changeObserver={onSelectionChanged}
        tooltipText={tooltipText}
        inputLabelText={inputLabel}
    />);
}

export function UserListSelect({isActive, onSelectUser, selectedUser, scopeFilter}){
    const {users} = useUsers(isActive);
    const filteredUsers = filterUsersByScope(users, scopeFilter)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    const {onlineUsers} = useOnlineUsers();

    const defaultApplied = useRef(false);

    useEffect(() => {
        if (filteredUsers.length > 0 && !selectedUser && !defaultApplied.current) {
            onSelectUser(filteredUsers[0]);
            updateUserSelected(filteredUsers[0].email);
            defaultApplied.current = true;
        }
    }, [filteredUsers, selectedUser, defaultApplied, onSelectUser]);

    return (<GenericListSelector
        allValues={filteredUsers}
        selectedValues={selectedUser}
        setSelectedValues={onSelectUser}
        renderOption={(user) => renderOption(user,onlineUsers,null,'large')}
    />);
}
