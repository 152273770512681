import {AdvancedDatePickerComponent} from "../Booking/AdvancedDatePicker";
import React, {useEffect, useState} from "react";
import {useCodes} from "../Codes/data";
import {
    countTherapistCodeMinutes,
    countTherapistCodeMinutesPerID, countTherapistCodeSegments, countTherapistCodeSegmentsPerID,
    transformCountsToChartData,
    transformForStackedBarChart
} from "./helpers";
import {PieChart} from "./piechart";
import {StackedBarChart} from "./stackedbar";
import {ReportOptions} from "./reportoptions";
import {ReportUnit} from "./reportunit";
import {BarChart} from "./barchart";
import {useUsers} from "../ListBoxes/userService";
import html2canvas from "html2canvas";
import {AppBar, Button, IconButton, Paper, Toolbar, Typography, Grid, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {UsersWithSameActivity} from "../Presence/presence";
import firebase from 'firebase/compat/app';
import NameDialog from "../Components/nameDialog";
import ConfirmDialog from "../Components/confirmDialog";
import {saveReport, deleteReport} from "./data";
import SaveIcon from "@mui/icons-material/Save"
import DeleteIcon from "@mui/icons-material/Delete"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import {UserDropdownSelect} from "../ListBoxes/UserSelect";
import {CodeDropdownSelect} from "../ListBoxes/CodeSelect";

export const useFetchAllTherapistsDataInRange = (therapists, startDate, endDate) => {
    const [allTherapistsData, setAllTherapistsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    endDate = endDate ?? startDate;

    useEffect( () => {
        setIsLoading(true);
        const db = firebase.firestore();

        let fetchPromises = [];
        therapists?.map(therapist => {
            let currDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
            while(currDate.getFullYear() <= endDate.getFullYear() && currDate.getMonth() <= endDate.getMonth()) {
                let datesCollectionPath = `bookings/${therapist.id}/${currDate.getFullYear()}/${(currDate.getMonth()+1).toString().padStart(2, '0')}/dates`;
                fetchPromises.push(db.collection(datesCollectionPath).get().then(querySnapshot => {
                    const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(element => {
                        let parts = element.id.split('-');
                        let docDate = new Date(parseInt(parts[0]), parseInt(parts[1])-1, parseInt(parts[2]));
                        return (
                            docDate.getFullYear() >= startDate.getFullYear() && docDate.getFullYear() <= endDate.getFullYear() &&
                            docDate.getMonth() >= startDate.getMonth() && docDate.getMonth() <= endDate.getMonth() &&
                            docDate.getDate() >= startDate.getDate() && docDate.getDate() <= endDate.getDate()
                        );
                    });
                    return { therapistId: therapist.id, data: dates };
                }).catch(err => {
                    setError(err); // This simplistic error handling will stop at the first error
                    return { therapistId: therapist.id, data: [], error: err };
                }));
                currDate = new Date(currDate.getFullYear(), currDate.getMonth()+1, 1);
            }
        });

        Promise.all(fetchPromises).then(results => {
            setAllTherapistsData(results);
            setIsLoading(false);
        }).catch(err => {
            setError(err);
            setIsLoading(false);
        });
    }, [therapists, startDate, endDate]);

    return { allTherapistsData, isLoading, error };
};

export const OverviewCustomChart = ({onCloseWindow, savedReportId = null, defaultStartDate, defaultEndDate, defaultTherapists = [], defaultCodes = [], defaultChart = 'pie', initialReportName = null, initialReportUnit = 'session-count'}) => {
    const [startDate, setStartDate] = useState(defaultStartDate ?? new Date());
    const [endDate, setEndDate] = useState(defaultEndDate ?? new Date());

    const [reportName, setReportName] = useState(initialReportName);

    const [reportUnit, setReportUnit] = useState(initialReportUnit);

    const [selectedCodes, setSelectedCodes] = useState([]);
    const [selectedReportOptions, setSelectedReportOptions] = useState(null);
    const {codes} = useCodes();
    const {users: therapists} = useUsers(true);
    const [data, setData] = useState([]);
    const [keys, setKeys] = useState([]);

    const [selectedTherapists, setSelectedTherapists] = useState([]);
    const {allTherapistsData, isLoading, error} = useFetchAllTherapistsDataInRange(selectedTherapists, startDate, endDate);

    const [saveReportDialogOpen, setSaveReportDialogOpen] = useState(false);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [reportId, setReportId ] = useState(savedReportId);

    useEffect(() => {
        const values = therapists.filter(t=>defaultTherapists.includes(t.id));
        setSelectedTherapists(values);
    }, [defaultTherapists,therapists]);

    useEffect(() => {
        const values = codes.filter(t=>defaultCodes.includes(t.id));
        setSelectedCodes(values);
    }, [codes]);

    useEffect(() => {
        if (codes === undefined || allTherapistsData.length === 0) {
            setData([])
            return
        }

        if (selectedReportOptions.value === 'stacked' || selectedReportOptions.value === 'group') {
            let individualCounts = {};

            if(reportUnit === 'session-count') {
                individualCounts = countTherapistCodeSegmentsPerID(allTherapistsData, selectedCodes);
            } else {
                individualCounts = countTherapistCodeMinutesPerID(allTherapistsData, selectedCodes);
            }

            let individualChartData = transformForStackedBarChart(individualCounts, therapists, codes);

            if (individualChartData.length) {
                // Initialize a Set to collect unique keys
                const keySet = new Set();
                // Iterate over each entry to collect keys
                individualChartData.forEach(entry => {
                    Object.keys(entry).forEach(key => {
                        // Add key if it does not match the exclusion criteria
                        if (key !== 'therapist' && !key.endsWith('Color')) {
                            keySet.add(key);
                        }
                    });
                });

                // Convert the Set of keys to an array
                const newKeys = Array.from(keySet);
                // Now set the data and keys in state
                setData(individualChartData);
                setKeys(newKeys); // Set keys based on the new data
            } else {
                setData([])
            }
        } else {
            let counts = {};
            if(reportUnit === 'session-count'){
                counts = countTherapistCodeSegments(allTherapistsData, selectedCodes);
            } else {
                counts = countTherapistCodeMinutes(allTherapistsData, selectedCodes);
            }

            let chartData = transformCountsToChartData(counts, codes)

            setData(chartData)
        }

    }, [allTherapistsData, selectedCodes, codes, selectedReportOptions]);

    const handleTherapistSelection = (options) => {
        setSelectedTherapists(options);
    };
    const handleCodeSelection = (options) => {
        setSelectedCodes(options);
    };
    const handleReportOptions = (options) => {
        setSelectedReportOptions(options)
    };
    const handleReportUnit = (unit) => {
        setReportUnit(unit);
    }
    const handleDateChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const exportToPNG = async () => {
        const input = document.getElementById('graphContainer');
        const exportButton = document.getElementById('exportButton'); // Ensure your export button has this ID

        // Temporarily hide the export button
        if (exportButton) exportButton.style.visibility = 'hidden';

        const canvas = await html2canvas(input);

        // Show the export button again after capturing
        if (exportButton) exportButton.style.visibility = 'visible';

        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'graph.png'; // Set the download file name
        document.body.appendChild(link); // Append the link to the document
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up and remove the link
    };

    const handleSaveReport = async (name)=> {
        if(!name){
            setSaveReportDialogOpen(true);
        } else if(saveReportDialogOpen) {
            const docId = await saveReport(name,
                selectedReportOptions.value,
                selectedTherapists.map((t)=>t.id),
                selectedCodes.map((c)=>c.id),
                reportUnit,
                startDate,
                endDate
            );

            setReportName(name);
            setReportId(docId);
            setSaveReportDialogOpen(false);
        }
    }

    function handleDeleteReport(confirmed = false){
        if(!confirmed){
            setConfirmDeleteDialogOpen(true);
        } else {
            if (reportId) {
                deleteReport(reportId);
            }

            setConfirmDeleteDialogOpen(false);
            onCloseWindow();
        }
    }

    return (<Paper elevation={3} sx={{padding: 3}} id="graphContainer">
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        {reportName ? `Report - ${reportName}` : 'Report'}
                    </Typography>
                    <UsersWithSameActivity currentActivity={'chart-custom'}/>
                    <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: '10px', margin: '10px 0 10px 0'}}>
                <UserDropdownSelect scopeFilter={"therapist"} onSelectUser={handleTherapistSelection} selectedUser={selectedTherapists} tooltipText={null} />
                <CodeDropdownSelect setSelectedCode={handleCodeSelection} selectedCode={selectedCodes} tooltipText={null} inputLabelText="Code" />
                <ReportOptions onOptionsChange={handleReportOptions}
                                   defaultSelection={defaultChart}
                           customStyles={{
                               control: (provided) => ({
                                   ...provided,
                                   minWidth: '150px',
                                   maxWidth: '150px',
                               }),
                           }}/>
                <ReportUnit onUnitChange={handleReportUnit} defaultSelection={initialReportUnit} customStyles={{
                    control: (provided) => ({
                        ...provided,
                        minWidth: '150px',
                        maxWidth: '150px',
                    }),
                }}/>
            </div>

            {
                selectedReportOptions && selectedReportOptions.value === 'bar' ? (
                    <BarChart data={data} key="bar-chart" reportUnit={reportUnit}/>
                ) : selectedReportOptions && selectedReportOptions.value === 'pie' ? (
                    <PieChart data={data} key="pie-chart" reportUnit={reportUnit}/>
                ) : selectedReportOptions && selectedReportOptions.value === 'stacked' ? (
                    <StackedBarChart data={data} keys={keys} key="stacked-chart" reportUnit={reportUnit}/>
                ) : selectedReportOptions && selectedReportOptions.value === 'group' ? (
                    <StackedBarChart data={data} keys={keys} style={'grouped'} key="grouped-chart" reportUnit={reportUnit}/>
                ) : <>No Selection Made</>
            }

            <AppBar position="static" color="primary" sx={{top: 'auto', bottom: 0}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{display: 'inline'}}>
                        <Tooltip title={"Save Report"}>
                            <Button id="saveReportButton" variant="contained" size="small" style={{marginRight: '10px'}}
                                onClick={() => handleSaveReport()}>
                                <SaveIcon/>
                            </Button>
                        </Tooltip>
                        { reportId ?
                            <Tooltip title={"Delete Report"}>
                                <Button id="deleteReportButton" variant="contained" size="small" color="error"
                                        onClick={()=>{handleDeleteReport();}}>
                                    <DeleteIcon/>
                                </Button>
                            </Tooltip> : <></>
                        }
                    </div>
                    <div><AdvancedDatePickerComponent onDateChange={handleDateChange}
                                                      defaultStartDate={defaultStartDate}
                                                      defaultEndDate={defaultEndDate}/></div>
                    <div>
                        <Tooltip title={"Export Report"}>
                            <Button id="exportButton" variant="contained" size="small" onClick={exportToPNG}>
                                <FileDownloadIcon />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </AppBar>

            <NameDialog
                open={saveReportDialogOpen}
                setOpen={setSaveReportDialogOpen}
                setName={(name) => handleSaveReport(name)}
            />
            <ConfirmDialog
                open={confirmDeleteDialogOpen}
                setOpen={setConfirmDeleteDialogOpen}
                onConfirm={() => handleDeleteReport(true)}
            />
        </Paper>
    )
};