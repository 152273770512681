import React, { useEffect } from 'react';
import {MenuItem, FormControl, InputLabel, Select, Paper} from '@mui/material';

const options = [
    { value: 'session-count', label: 'Session Count' },
    { value: 'duration-long', label: 'W D hh mm ss' },
    { value: 'duration-short', label: 'hh mm ss' },
];

export const ReportUnit = ({ onUnitChange, customStyles, defaultSelection = 'session-count' }) => {
    useEffect(() => {
        // Trigger initial option change with the default option
        onUnitChange(defaultSelection);
    }, []);

    return (
        <Paper sx={{width: '100%', padding: 1, userSelect : 'none'}}>
            <FormControl variant="standard" style={customStyles} fullWidth>
                <InputLabel id="report-unit-select-label">Report Unit</InputLabel>
                <Select
                    labelId="report-unit-select-label"
                    id="report-unit-select"
                    defaultValue={defaultSelection}
                    onChange={(e) => onUnitChange(e.target.value)}
                    label="Chart Units"
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
};
