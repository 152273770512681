import {Avatar, Badge, useTheme} from "@mui/material";
import React from "react";
import {calculateTimeDifferenceInMinutes} from "./userUtils";

export function OnlineAvatar({user, online, lastActive, size}) {
    const theme = useTheme();
    const timeDiff = calculateTimeDifferenceInMinutes(lastActive);
    let background = '#00aa00';
    if (timeDiff > 10) {
        background = '#aaaaaa';
    }

    let drawSize = '48px';
    let onlineBadgeStyle = {
        height: '14px',
        width: '14px',
        borderRadius: '50%',
        backgroundColor: background,
        border: `2px solid ${theme.palette.background.paper}`,
        right: 4,
        bottom: 4,
    };

    if (size === 'small') {
        onlineBadgeStyle.height = '10px';
        onlineBadgeStyle.width = '10px';
        onlineBadgeStyle.right = 1;
        onlineBadgeStyle.bottom = 1;
        drawSize = '24px';
    }

    return online ? (
        <Badge
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            variant="dot"
            badgeContent=" "
            sx={{'& .MuiBadge-dot': onlineBadgeStyle}}>
            <Avatar src={user?.photoURL} sx={{width: drawSize, height: drawSize}} imgProps={{referrerPolicy: "no-referrer"}}/>
        </Badge>
    ) : (
        <Avatar src={user?.photoURL} sx={{width: drawSize, height: drawSize}} imgProps={{referrerPolicy: "no-referrer"}}/>
    );
}