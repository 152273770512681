import React, { useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const allOptions = [
//    { label: 'Emojis', value: 'emojis' },
//    { label: 'Labels', value: 'labels' },
//    { label: 'Align Emojis', value: 'aligned' },
    { label: 'Numerals', value: 'numerals' },
    { label: 'No Hands', value: 'nohands' },
    { label: 'Smooth', value: 'smooth' },
];

export const defaultSelectedOptions = [
//    { label: 'Emojis', value: 'emojis' },
//    { label: 'Labels', value: 'labels' },
    { label: 'Smooth', value: 'smooth' },
];

export const TodayOptions = ({ selectedOptions, setSelectedOptions }) => {

    useEffect(() => {
        setSelectedOptions(selectedOptions);
    }, [selectedOptions,setSelectedOptions]);

    const filteredOptions = allOptions.filter(option =>
        !selectedOptions.some(selected => selected.value === option.value)
    );

    const handleOnChange = (event, newValue) => {
        setSelectedOptions(newValue);
    };

    return (
        <Autocomplete
            multiple
            options={filteredOptions}
            getOptionLabel={(option) => option.label}
            value={selectedOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Display options..."
                />
            )}
            onChange={handleOnChange}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableCloseOnSelect
        />
    );
};
