import {materialRenderers} from "@jsonforms/material-renderers";
import EmojiControlTester from "./EmojiFormControl/EmojiControlTester";
import EmojiRenderer from "./EmojiFormControl/EmojiRenderer";
import ColourControlTester from "./ColourFormControl/ColourControlTester";
import ColourRenderer from "./ColourFormControl/ColourRenderer";
import OpenLinkRenderer from "./OpenLinkFormControl/OpenLinkRenderer";
import OpenLinkTester from "./OpenLinkFormControl/OpenLinkTester";
import PatientIdTester from "./PatientIdFormControl/PateintIdTester"
import PatientIdRenderer from "./PatientIdFormControl/PatientIdRenderer";

const renderers = [
    ...materialRenderers,
    { tester: EmojiControlTester, renderer: EmojiRenderer },
    { tester: ColourControlTester, renderer: ColourRenderer },
    { tester: OpenLinkTester, renderer: OpenLinkRenderer },
    { tester: PatientIdTester, renderer: PatientIdRenderer }
];

export default renderers;