import styled from "@emotion/styled";
import React, {useEffect, useState} from "react";
import {Fade, Paper, Popper} from "@mui/material";

const ArrowWrapper = styled("div")({
    position: "absolute",
    width: "8px",
    height: "8px",
    backgroundColor: "#7D9EC0", // Same as tooltip background
    transform: "rotate(45deg)",
    zIndex: -1,
});

const Arrow = ({placement}) => {
    if (!placement) return null;

    return (
        <ArrowWrapper
            style={{
                ...(placement === "top" && {bottom: "-4px", left: "50%", transform: "translateX(-50%) rotate(45deg)"}),
                ...(placement === "bottom" && {top: "-4px", left: "50%", transform: "translateX(-50%) rotate(45deg)"}),
                ...(placement === "left" && {right: "-4px", top: "50%", transform: "translateY(-50%) rotate(45deg)"}),
                ...(placement === "right" && {left: "-4px", top: "50%", transform: "translateY(-50%) rotate(45deg)"}),
            }}
        />
    );
};


export const FloatingTooltip = ({tooltipData}) => {
    const [placement, setPlacement] = useState("top");
    const [currentData, setCurrentData] = useState(tooltipData);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!tooltipData || !tooltipData.anchorEl) {
            setIsVisible(false);
            return;
        }

        // Fade out, update content, then fade in
        setIsVisible(false);
        const timeout = setTimeout(() => {
            setCurrentData(tooltipData);
            setIsVisible(true);
        }, 500);

        return () => clearTimeout(timeout);
    }, [tooltipData]);

    // Hide tooltip when mouse leaves the anchor element
    const handleMouseLeave = () => {
        setIsVisible(false);
    };

  //  console.log(currentData)


    if (!currentData || !currentData.anchorEl) return null;

    return (
        <Popper
            open={isVisible}
            anchorEl={currentData.anchorEl}
            placement={placement}
            transition
            disablePortal={false} // Keep tooltip within DOM structure
            sx={{
                position: "absolute",
                zIndex: 1000,
                pointerEvents: "none", // Allow interactions to pass through the tooltip
            }}
            modifiers={[
                {
                    name: "flip",
                    enabled: true,
                    options: {
                        fallbackPlacements: ["bottom", "left", "right"],
                    },
                },
                {
                    name: "arrow",
                    enabled: true,
                    options: {
                        padding: 6,
                    },
                },
                {
                    name: "offset",
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: "updatePlacement",
                    enabled: true,
                    phase: "main",
                    fn: ({state}) => {
                        setPlacement(state.placement);
                    },
                },
            ]}
        >
            {({TransitionProps}) => (
                <Fade {...TransitionProps} in={isVisible} timeout={300}>
                    <div
                        style={{
                            position: "relative",
                            zIndex: 1300,
                            pointerEvents: "none", // Allow interactions to pass through
                        }}
                        onMouseEnter={() => setIsVisible(true)} // Keep tooltip open if hovered
                        onMouseLeave={handleMouseLeave} // Hide tooltip when leaving
                    >
                        <Paper
                            sx={{
                                padding: "4px 8px",
                                backgroundColor: "#7D9EC0",
                                color: "#fff",
                                borderRadius: "4px",
                                boxShadow: "2px 4px 6px rgba(0,0,0,0.3), 4px 8px 12px rgba(0,0,0,0.2)",
                                fontSize: "0.8rem",
                                whiteSpace: "nowrap",
                                position: "relative",
                                pointerEvents: "auto", // Allow clicks inside the tooltip
                            }}
                        >
                            {currentData.content}
                        </Paper>
                        <Arrow placement={placement}/>
                    </div>
                </Fade>
            )}
        </Popper>
    );
};


