import { withJsonFormsControlProps } from '@jsonforms/react';
import React from "react";
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

const OpenLinkControl = ({ data, handleChange, path, label }) => (
    <FormControl style={{ marginBottom: '5px', width: '100%'}} variant="outlined">
        <InputLabel htmlFor={path}>{label}</InputLabel>
        <OutlinedInput
            id={path}
            type={'text'}
            value={data}
            onChange={(event) => {
                handleChange(path, event.target.value);
            }}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={() => {
                        if(!data.startsWith('http')) {
                            data = `https://${data}`;
                        }
                        window.open(data, '_blank');
                    }}>
                        <OpenInBrowserIcon/>
                    </IconButton>
                </InputAdornment>
            }
            label={label}
        />
    </FormControl>
)

export default withJsonFormsControlProps(OpenLinkControl);