import {deleteDoc, doc, setDoc} from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import {setHourCode} from "../Booking/dataServce";


let bankHolidaysCache = null

export const createDateStructure = async (sessionUser,therapist, year, month, codeMap) => {
    const db = firebase.firestore(); // Get a reference to the Firestore.js service
    const daysInMonth = new Date(Date.UTC(year, month, 0)).getUTCDate();

    for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(Date.UTC(year, month - 1, day));
        const dayOfWeek = date.toLocaleString('en-GB', { weekday: 'long' });
        const workingHoursForDay = therapist.workingHours[dayOfWeek];

        const dateFormatted = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const dateDocRef = doc(db, `bookings/${therapist.id}/${year}/${month.toString().padStart(2, '0')}/dates`, dateFormatted);

        let hoursData = {};
        for (let hour = 0; hour < 24; hour++) {
            const isWorkingHour = workingHoursForDay[hour];
            const statusCode = isWorkingHour ? codeMap.get("AV") : '' //codeMap.get("NW").id;
            if(statusCode) {
                hoursData[hour] = {
                    code: statusCode,
                };
            }
        }

        const isHoliday = await isBankHoliday(date.toISOString().substr(0,10));
        if (isHoliday) {
            for (let hour = 0; hour < 24; hour++) {
                const isWorkingHour = workingHoursForDay[hour];
                if (isWorkingHour) {
                    hoursData[hour] = {
                        code: codeMap.get("BH"),
                    };
                }
            }
        }

        await setDoc(dateDocRef, {hours:{}
        }, { merge: true });

        for (let hour = 0; hour < 24; hour++) {
            if(hoursData[hour]?.code) {
                setHourCode(sessionUser,therapist, year, month.toString(), day.toString(), hour.toString(),   [{idx:0,code:hoursData[hour].code,minutes:60}])
            }
        }
    }
};




export const CreateDateRange = async (sessionUser, therapist, codes,startMonth, startYear, endMonth, endYear, updateProgressCallback) => {
    const startMonthIndex = startMonth - 1;
    const endMonthIndex = (endMonth - 1) + ((endYear - startYear) * 12);
    const totalMonths = endMonthIndex - startMonthIndex + 1; // Total number of months to process

    // Fetch the IDs for "Available","Bank Holiday"
    // These will be reserved with the following codes.
    // AV,BH

    const codeMap = new Map();
    ["AV", "BH"].forEach(codeKey => {
        const codeObj = codes.find(code => code.code === codeKey);
        if (codeObj) {
            codeMap.set(codeKey, codeObj);
        }
    });

    for (let monthIndex = startMonthIndex, processedMonths = 0; monthIndex <= endMonthIndex; monthIndex++, processedMonths++) {
        try {
            // Calculate the actual year and month number (1-12)
            const year = startYear + Math.floor(monthIndex / 12);
            const month = (monthIndex % 12) + 1; // Adjust for 1-12 month range

            // Call the createDateStructure function with the year and month
            await createDateStructure(sessionUser, therapist, year, month, codeMap);

            // Update progress after each month is processed
            const progress = Math.round((processedMonths / totalMonths) * 100);
            updateProgressCallback(progress);

        } catch (error) {
            console.error('Failed to create date structure:', error);
            break; // Exit the loop on the first error encountered
        }
    }

    // Ensure progress is set to 100% upon completion
    updateProgressCallback(100);

    console.log('All date structures created successfully.');
/*
    // Convert startMonthIndex to month number (1-12) for JavaScript Date
    let earliestDate = `${startYear}-${String(startMonthIndex + 1).padStart(2, '0')}-01`;

    // Calculate the last day of the endMonth
    // First, get the first day of the next month, then subtract one day to get the last day of endMonth
    const nextMonth = (endMonthIndex % 12) + 1; // Calculate next month, considering wrapping
    const nextMonthYear = endMonthIndex % 12 === 11 ? endYear + 1 : endYear; // Increment year if December
    const firstDayNextMonth = new Date(nextMonthYear, nextMonth, 1); // First day of next month
    const lastDayEndMonth = new Date(firstDayNextMonth - 1); // Subtract one day to get last day of endMonth
    let latestDate = lastDayEndMonth.toISOString().split('T')[0]; // Format to YYYY-MM-DD

    const db = firebase.firestore();
    const therapistRef = db.collection(userCollectionPath).doc(therapist.id);
    // Update the therapist document with the earliest and latest date
    await therapistRef.update({
        earliestDate: firebase.firestore.Timestamp.fromDate(new Date(earliestDate)),
        latestDate: firebase.firestore.Timestamp.fromDate(new Date(latestDate))
    });*/
}


// Convert a bit pattern to a boolean array
const bitsToBoolArray = (bits) => Array.from({length: 24}).map((_, index) => !!(bits & (1 << index)));

// Convert a boolean array to a bit pattern
//const boolArrayToBits = (boolArray) => boolArray.reduce((acc, val, index) => acc | (val ? 1 << index : 0), 0);

// Utility function to unpack working hours from bit field to boolean array
// Ensuring a specific order of days
export const unpackWorkingHours = (workingHoursBitField) => {
    // Define the order of the days as you'd like to maintain in your UI
    const daysOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // Use daysOrder to map through each day and unpack accordingly
    return daysOrder.reduce((acc, day) => {
        // If the day exists in the workingHoursBitField, unpack it; otherwise, use a default value
        acc[day] = workingHoursBitField && workingHoursBitField[day]
            ? bitsToBoolArray(workingHoursBitField[day])
            : new Array(24).fill(false); // Default to false if day is not present
        return acc;
    }, {});
};


async function isBankHoliday(dateToCheck) {
    const datesArray = await fetchBankHolidays(); // Now uses the cache if available
    return datesArray.includes(dateToCheck); // Check if the date exists in the array
}

async function fetchBankHolidays() {
    // Return the cache if it's already populated
    if (bankHolidaysCache !== null) {
        return bankHolidaysCache;
    }

    const db = firebase.firestore();
    const docRef = db.collection('bankHolidays').doc('englandWalesHolidays');

    const doc = await docRef.get();
    if (doc.exists) {
        const data = doc.data();
        bankHolidaysCache = data.dates; // Populate the cache with the retrieved data
        return bankHolidaysCache;
    } else {
        console.log("No such document!");
        return []; // Return an empty array if the document does not exist
    }
}


export const deleteMonthForTherapist = async (therapistId, year, month) => {
    const db = firebase.firestore(); // Get a reference to the Firestore service

    // Format the month to ensure it's in MM format (e.g., "04" for April)
    const formattedMonth = month.toString().padStart(2, '0');

    // Define the path to the document or collection you want to delete
    // Note: If deleting a collection, Firestore requires deleting all documents in the collection manually
    const path = `bookings/${therapistId}/${year}/${formattedMonth}/dates`;

    // Retrieve all documents from the specified path
    const querySnapshot = await db.collection(path).get();

    // Delete each document in the collection
    querySnapshot.forEach(async (document) => {
        await deleteDoc(doc(db, path, document.id));
    });

    console.log(`Deleted all bookings for therapist ${therapistId} for ${year}-${formattedMonth}.`);
};


