import React from 'react';
import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { GraphContainerStyle } from "../Styles/reporting";
import { calculateChartLabel } from "./helpers";

export const StackedBarChart = ({data,keys,reportUnit,style = 'stacked',colors}) => {
    const theme = useTheme();

    // Custom theme for Nivo chart
    const nivoTheme = {
        axis: {
            ticks: {
                text: {
                    fill: theme.palette.text.primary, // Adapt to MUI theme
                },
            },
        },
        tooltip: {
            container: {
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
                border: `1px solid ${theme.palette.divider}`,
            },
        },
    };

    // Function to determine bar color
    const getColor = bar => bar.data[`${bar.id}Color`] || colors;

    return (
        <div style={GraphContainerStyle}>
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy="therapist"
                colors={getColor}
                margin={{ top: 40, right: 40, bottom: 80, left: 60 }}
                padding={0.3}
                groupMode={style}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                theme={nivoTheme}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 46,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                valueFormat={v => calculateChartLabel(v, reportUnit,style === 'grouped')}
                tooltip={({ id, value, color }) => (
                    <div
                        style={{
                            padding: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            background: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: '2px',
                        }}
                    >
                        <span style={{ backgroundColor: color, width: '20px', height: '20px' }} />
                        <strong>{id}:</strong> {calculateChartLabel(value, reportUnit)}
                    </div>
                )}
            />
        </div>
    );
};
