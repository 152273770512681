import React, {useEffect, useState} from "react";
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Toolbar,
    Typography,
} from "@mui/material";
import {TimeCircle} from "../SlotConfig/slotconfig";
import CloseButton from "../Components/closeButton";
import {UsersWithSameActivity} from "../Presence/presence";
import {useFeatureFlags} from "../FeatureFlags/FeatureFlags";
import TimeZoneService from "../Timezone/timezone";
import {motion, AnimatePresence} from "framer-motion";


export const CustomiseHourSlotModal = ({
                                           isOpen,
                                           onClose,
                                           sessionUser,
                                           selectedTherapist,
                                           year,
                                           month,
                                           day,
                                           hour,
                                           data,
                                           codes,
                                           onSave,
                                           localTimezone,
                                           therapistTimezone
                                       }) => {
    const [title, setTitle] = useState("");
    const [presenceLabel, setPresenceLabel] = useState("");
    const flags = useFeatureFlags();
    const canEdit = sessionUser.scopes?.includes('manage') || sessionUser.id === selectedTherapist.id;

    useEffect(() => {
        if (isOpen) {
            const initialPresence = `${year}-${month}-${day} ${hour}:00`;
            setPresenceLabel(initialPresence);
            setTitle(initialPresence);
        }
    }, [isOpen, year, month, day, hour]);

    const handleSegmentSelect = (segment) => {
        const newTitle = TimeZoneService.getFormattedDate(year, month, day, hour, segment.start, segment.end,localTimezone,therapistTimezone);
        setTitle(newTitle);
        setPresenceLabel(TimeZoneService.getFormattedDate(year, month, day, hour));
    };

    return (
        <AnimatePresence>
            <div sx={{userSelect: 'none'}}>
                <Dialog
                    open={isOpen}
                    fullWidth
                    maxWidth="md"
                    onClose={onClose}
                    PaperProps={{
                        component: motion.div, // Use motion.div inside PaperProps
                        initial: {opacity: 0, scale: 0},
                        animate: {opacity: 1, scale: 1},
                        exit: {opacity: 0, scale: 0},
                        transition: {duration: 0.3, ease: "easeInOut"},

                    }}
                >
                    <DialogTitle>
                        <AppBar position="static">
                            <Toolbar>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item sx={{flexGrow: 1, userSelect: 'none'}}>
                                        <Typography variant="h6">{title}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <UsersWithSameActivity
                                            currentActivity={presenceLabel}
                                            email={selectedTherapist?.email}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CloseButton onCloseWindow={onClose}/>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={flags.bookinginfo ? 8 : 12}>
                                <TimeCircle
                                    sessionUser={sessionUser}
                                    selectedTherapist={selectedTherapist}
                                    year={year}
                                    month={month}
                                    day={day}
                                    hour={hour}
                                    data={data}
                                    codes={codes}
                                    onSaveProp={onSave}
                                    onSegmentSelect={handleSegmentSelect}
                                    canEdit={canEdit}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        </AnimatePresence>
    );
};

