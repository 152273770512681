import React, {useEffect, useMemo, useRef, useState} from 'react';
import './Grid.css';

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";


import {Timeline,} from '@mui/lab';

import {CustomTimelineItem} from "./timeline";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {ssUserSessionId} from "../App/App";

import TimeZoneService from "../Timezone/timezone";
import {DateTime} from "luxon";

import {GridCellMemoized} from "./gridcell";
import {FloatingTooltip} from "./floatingToolTip";

export const defaultCellSize = 48

export const getDataForDayHour = (year, month, day, hour, datesWithHours) => {
    if (day === undefined || hour === undefined || datesWithHours.length < 1) {
        return null
    }
    const dateStr = `${year}-${(month).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const data = datesWithHours.find(d => d.id === dateStr);
    const hourData = data?.hours[hour]
    return hourData
};


export const MonthlyGridMemoized = React.memo(MonthlyGrid, (prevProps, nextProps) => {
    const changes = [];

    if (prevProps.selectedDate !== nextProps.selectedDate) {
        changes.push("selectedDate");
    }

    if (prevProps.selectedTherapist?.id !== nextProps.selectedTherapist?.id) {
        changes.push("selectedTherapist");
    }

    if (prevProps.zoom !== nextProps.zoom) {
        changes.push("zoom");
    }

    if (prevProps.displayMode !== nextProps.displayMode) {
        changes.push("displayMode");
    }

    if (prevProps.isDarkMode !== nextProps.isDarkMode) {
        changes.push("isDarkMode");
    }

    if (prevProps.historyVisible !== nextProps.historyVisible) {
        changes.push("historyVisible");
    }

    if (prevProps.showTimes !== nextProps.showTimes) {
        changes.push("showTimes");
    }

    if (prevProps.updateData !== nextProps.updateData) {
        changes.push("updateData");
    }

    if (JSON.stringify(prevProps).codes !== JSON.stringify(nextProps.codes)) {
        changes.push("codes");
    }

    if (JSON.stringify(prevProps.datesWithHours) !== JSON.stringify(nextProps.datesWithHours)) {
        changes.push("datesWithHours");
    }

    if (changes.length > 0) {
        // console.log("MonthlyGrid re-rendering due to changes:", changes);
        return false; // Allow re-render
    }

    //  console.log("MonthlyGrid skipped re-render");
    return true; // Skip re-render
});


function MonthlyGrid({
                         selectedTherapist,
                         selectedDate,
                         onSelectionChange,
                         selectionRef,
                         datesWithHours,
                         codes,
                         displayMode,
                         isDarkMode = false,
                         showTimes = false,
                         setOverlay,
                         overlay,
                         zoom,
                         updateData,
                         onDoubleClick,
                         activeUsers,
                         historyVisible,
                         localTimeZone,
                         setContextMenu
                     }) {
    const initialDateParts = selectedDate.split('-');
    const initialYear = parseInt(initialDateParts[0], 10);
    const initialMonth = parseInt(initialDateParts[1], 10) - 1; // Convert to 0-based index
    const [year, setYear] = useState(initialYear);
    const [month, setMonth] = useState(initialMonth);
    const [daysInMonth, setDaysInMonth] = useState(new Date(year, month, 0).getUTCDate());
    const [selection, setSelection] = useState({start: null, end: null});
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based index
    const currentDate = today.getDate();
    const theme = useTheme();
    const gridRef = useRef(null);

    const [tooltipData, setTooltipData] = useState(null);

    const handleTooltipChange = (data) => {
        //  console.log("Tooltip triggered:", data);

        if (!data || !data.content) {
            setTooltipData({isVisible: false});
            return;
        }

        setTooltipData({
            ...data,
            isVisible: true,
        });
    };
    useEffect(() => {
        if (gridRef.current) {

            const grid = gridRef.current;

            const updateScrollPosition = () => {
                const viewportWidth = grid.clientWidth;
                const viewportHeight = grid.clientHeight;

                // Determine average positions of the selection's start and end
                const avgDay = ((selection.start?.day + selection.end?.day) / 2) - 1; // Average day, converted to 0-based index
                const avgHour = (selection.start?.hour + selection.end?.hour) / 2; // Average hour

                // Calculate the center position of the average day and hour, accounting for zoom
                const newTopPosition = Math.round(avgHour * defaultCellSize * zoom);
                const newLeftPosition = Math.round(avgDay * defaultCellSize * zoom);

                // Center these positions in the viewport
                const centeredTop = Math.round(newTopPosition - (viewportHeight / 2) + (defaultCellSize * zoom / 2));
                const centeredLeft = Math.round(newLeftPosition - (viewportWidth / 2) + (defaultCellSize * zoom / 2));

                // Ensure scrolling does not exceed bounds of the grid
                const safeScrollTop = Math.max(0, Math.min(centeredTop, grid.scrollHeight - viewportHeight));
                const safeScrollLeft = Math.max(0, Math.min(centeredLeft, grid.scrollWidth - viewportWidth));

                grid.scrollTo({
                    top: safeScrollTop,
                    left: safeScrollLeft,
                    behavior: 'instant'
                });
            };

            requestAnimationFrame(updateScrollPosition);
        }
    }, [zoom]);


    useEffect(() => {
        // Update year and month based on the selectedDate changes
        const dateParts = selectedDate.split('-');
        const year = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        setYear(year);
        setMonth(month);
        const days = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
        setDaysInMonth(days)
    }, [selectedDate]);


    const [cellSize, setCellSize] = useState(48);

    const computeCellSize = () => {
        if (!gridRef.current) return;

        // Get the full scrollable grid size
        const totalWidth = gridRef.current.scrollWidth;
        const totalHeight = gridRef.current.scrollHeight;

        const totalDays = new Date(year, month, 0).getDate(); // Get days in the month
        const totalHours = 24; // Always 24 hours in a day

        // Compute cell size dynamically based on the full scrollable area
        const calculatedCellWidth = Math.floor(totalWidth / (totalDays + 1)); // +1 for header
        const calculatedCellHeight = Math.floor(totalHeight / totalHours);

        setCellSize({width: calculatedCellWidth, height: calculatedCellHeight});
    };


    useEffect(() => {
        computeCellSize(); // Compute when component mounts

        window.addEventListener("resize", computeCellSize); // Recompute on resize
        return () => window.removeEventListener("resize", computeCellSize);
    }, [year, month]);


    const updateMenuPosition = (isVisible = false) => {
        if (!gridRef.current || !selection.start || !selection.end) return;

        const grid = gridRef.current;
        const gridRect = grid.getBoundingClientRect(); // Grid's position in viewport

        // Get accurate selection bounds using **computed cell size**
        const startDay = Math.min(selection.start.day, selection.end.day);
        const endDay = Math.max(selection.start.day, selection.end.day);
        const startHour = Math.min(selection.start.hour, selection.end.hour);
        const endHour = Math.max(selection.start.hour, selection.end.hour);

        // **Dynamically compute grid cell size**
        const cellWidth = grid.scrollWidth / (daysInMonth + 1); // +1 for headers
        const cellHeight = grid.scrollHeight / 24; // Always 24 hours

        // **Convert selection to viewport coordinates**
        const selectionLeft = gridRect.left + (startDay * cellWidth) - grid.scrollLeft;
        const selectionRight = gridRect.left + (endDay * cellWidth) - grid.scrollLeft + cellWidth;
        const selectionTop = gridRect.top + (startHour * cellHeight) - grid.scrollTop;
        const selectionBottom = gridRect.top + (endHour * cellHeight) - grid.scrollTop + cellHeight;

        // **Try placing menu to the right of selection**
        let finalLeft = selectionRight;
        let finalTop = selectionTop;

        const menuWidth = 200;  // Adjust this based on actual menu size
        const menuHeight = 100; // Adjust this based on actual menu size

        // **Check if menu overflows right, move left if needed**
        if (finalLeft + menuWidth > window.innerWidth) {
            finalLeft = selectionLeft - menuWidth; // Shift left
        }

        // **Check if menu overflows bottom, move above if needed**
        if (finalTop + menuHeight > window.innerHeight) {
            finalTop = selectionBottom - menuHeight;
        }

        // **Ensure menu stays inside the viewport**
        finalLeft = Math.max(10, Math.min(finalLeft, window.innerWidth - menuWidth - 10));
        finalTop = Math.max(10, Math.min(finalTop, window.innerHeight - menuHeight - 10));

        setContextMenu(finalLeft + 30, finalTop + 30, isVisible);
    };

    const handleDoubleClick = (e, day, hour) => {
        e.preventDefault();
        if (e.button !== 0) return;
        updateMenuPosition();
        onDoubleClick(e, day, hour);
    };

    const isSelectingRef = useRef(false);

    // Handle mouse down event
    const handleMouseDown = (e, day, hour) => {
        e.preventDefault();
        if (e.button !== 0) return;
        isSelectingRef.current = true;
        selectionRef.current.start = {day, hour};
        selectionRef.current.end = {day, hour};
        setSelection({start: selectionRef.current.start, end: selectionRef.current.end});
        onSelectionChange(selectionRef.current);
        updateMenuPosition();
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        if (e.button !== 0) return;

        isSelectingRef.current = false;

        let {start, end} = selectionRef.current;

        // Ensure start is always the smaller day and hour
        if (start.day > end.day || (start.day === end.day && start.hour > end.hour)) {
            [start, end] = [end, start]; // Swap values
        }

        // Update the selection reference with the normalized start and end
        selectionRef.current = {start, end};

        // Update state and trigger necessary updates
        setSelection({start, end});
        onSelectionChange(selectionRef.current);
        updateMenuPosition(true);
    };


    const handleMouseEnter = (e, day, hour) => {
        if (!isSelectingRef.current) return;

        if (
            selectionRef.current.end?.day === day &&
            selectionRef.current.end?.hour === hour
        ) {
            return;
        }

        selectionRef.current.end = {day, hour};
        setSelection((prevSelection) => {
            if (
                prevSelection.end?.day === day &&
                prevSelection.end?.hour === hour
            ) {
                return prevSelection; // Avoid unnecessary state updates
            }
            return {start: selectionRef.current.start, end: selectionRef.current.end};
        });

        onSelectionChange(selectionRef.current);
        updateMenuPosition();
    };

    const selectedCells = useMemo(() => {
        if (!selection.start || !selection.end) return new Map();

        const {start, end} = selection;
        let selected = new Map();

        for (let d = Math.min(start.day, end.day); d <= Math.max(start.day, end.day); d++) {
            for (let h = Math.min(start.hour, end.hour); h <= Math.max(start.hour, end.hour); h++) {
                const key = `${selectedTherapist.id}-${d}-${h}`;
                selected.set(key, {selected: true, source: 'me'});
            }
        }

        //   console.log("Updated selectedCells for Monthly Grid:", selected);
        return selected;
    }, [selection]);


    const userSelections = useMemo(() => {
        let userSelected = new Map();

        activeUsers?.forEach(user => {
            const currentSessionId = sessionStorage.getItem(ssUserSessionId);

            user.positions.forEach(position => {
                if (position.sessionId === currentSessionId) {
                    return false;
                }

                //     console.log("Checking position:", position);
                //      if (!position.therapistId) {
                //        console.error(`MISSING therapistId for user ${user.id} in position:`, position);
                //       return;
                //     } else {
                //        console.log(`TherapistId FOUND in position: ${position.therapistId}`);
                //     }

                const posStartDay = Math.min(position.start.day, position.end.day);
                const posEndDay = Math.max(position.start.day, position.end.day);
                const posStartHour = Math.min(position.start.hour, position.end.hour);
                const posEndHour = Math.max(position.start.hour, position.end.hour);

                for (let d = posStartDay; d <= posEndDay; d++) {
                    for (let h = posStartHour; h <= posEndHour; h++) {
                        const key = `${position.therapistId}-${d}-${h}`; // Ensure therapist ID is in key

                        //           console.log(`Storing selection with key: ${key} for user ${user.id}`);

                        if (!userSelected.has(key)) {
                            userSelected.set(key, {
                                selected: true,
                                source: 'user',
                                userId: user.id,
                                therapistId: position.therapistId,
                                photoURL: user.photoURL
                            });
                        }
                    }
                }
            });
        });

        //   console.log("Final userSelections for Monthly Grid:", userSelected);
        return userSelected;
    }, [activeUsers]);

    const isCellSelected = (day, hour) => {
        if(!selectedTherapist) {
            return false;
        }

        const key = `${selectedTherapist.id}-${day}-${hour}`;

        //  console.log(`Checking selection for therapist: ${selectedTherapist.id}, key: ${key}`);

        const currentSelection = selectedCells.get(key) || userSelections.get(key) || {
            selected: false,
            source: null
        };

        return {
            ...currentSelection,
            topNeighbor: selectedCells.get(`${selectedTherapist.id}-${day - 1}-${hour}`) || userSelections.get(`${selectedTherapist.id}-${day - 1}-${hour}`) || {selected: false},
            bottomNeighbor: selectedCells.get(`${selectedTherapist.id}-${day + 1}-${hour}`) || userSelections.get(`${selectedTherapist.id}-${day + 1}-${hour}`) || {selected: false},
            leftNeighbor: selectedCells.get(`${selectedTherapist.id}-${day}-${hour - 1}`) || userSelections.get(`${selectedTherapist.id}-${day}-${hour - 1}`) || {selected: false},
            rightNeighbor: selectedCells.get(`${selectedTherapist.id}-${day}-${hour + 1}`) || userSelections.get(`${selectedTherapist.id}-${day}-${hour + 1}`) || {selected: false},
        };
    };


    const memoizedIsCellSelected = useMemo(() => {
        return (day, hour) => {
            return isCellSelected(day, hour);
        };
    }, [selection, userSelections]);


    const updateOverlay = async (year, month, day, hour, datesWithHours) => {
        let hourData = getDataForDayHour(year, month, day, hour, datesWithHours)
        if (hourData?.status === null) {
            hourData = undefined
        }

        const formattedDate = TimeZoneService.getFormattedDate(year, month, day, hour)

        setOverlay({
            data: hourData,
            title: formattedDate,
            day: day,
            hour: hour,
        });
    }


    useEffect(() => {
        if (selection.start !== null) {
            const day = selection?.start?.day
            const hour = selection?.start?.hour
            updateOverlay(year, month + 1, day, hour, datesWithHours)
        }
    }, [updateData, selection, datesWithHours]);


    let stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 100,
        borderRight: `1px solid ${theme.palette.divider}`,
        userSelect: 'none', // Standard syntax
        WebkitUserSelect: 'none', // Safari
        MozUserSelect: 'none', // Firefox
        msUserSelect: 'none', // Internet Explorer/Edge
    };


    let stickyColumnStyleBlocker = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 200,
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '48px'
    };

    let cellBack = `${theme.palette.background.paper}`
    let weekendColor = '#eeeeee'

    if (isDarkMode) {
        weekendColor = '#888888'
    }

    const [isCollapsed, setIsCollapsed] = React.useState(false);

    useEffect(() => {
        if (!isCollapsed) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
    }, [historyVisible]);


    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={isCollapsed ? 12 : 8}>
                <TableContainer ref={gridRef} component={Paper}
                                sx={{maxHeight: 'calc(100vh - 250px)', willChange: 'transform, scroll-position'}}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow sx={{'& > *': {padding: '0px'}}}>
                                <TableCell sx={stickyColumnStyleBlocker}></TableCell>
                                {Array.from({length: daysInMonth}, (_, index) => {
                                    const date = new Date(year, month, index + 1);
                                    const isToday = currentYear === year && currentMonth === month && currentDate === (index + 1);
                                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                                    return (
                                        <TableCell
                                            key={index}
                                            align="center"
                                            sx={{
                                                backgroundColor: isToday ? '#4CAF50' : isWeekend ? weekendColor : cellBack,
                                                color: isToday ? 'white' : isWeekend ? 'black' : undefined,
                                                zIndex: 150,
                                                userSelect: 'none', // Standard syntax
                                                WebkitUserSelect: 'none', // Safari
                                                MozUserSelect: 'none', // Firefox
                                                msUserSelect: 'none', // Internet Explorer/Edge
                                                width: `${defaultCellSize * zoom}px`,
                                                minWidth: `${defaultCellSize * zoom}px`,
                                                margin: 0,
                                                padding: 0,
                                            }}>
                                            {date.getDate()}<br/>
                                            <span>{date.toLocaleDateString('en-GB', {weekday: 'short'})}</span>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {Array.from({length: 24}, (_, hour) => (
                                <TableRow key={hour}>

                                    <TableCell
                                        sx={{
                                            ...stickyColumnStyle,
                                            textAlign: "center",
                                            verticalAlign: "middle"
                                        }}>
                                        <div style={{fontSize: "1.0em", opacity: 1.0}}>
                                            {`${hour}:00`}
                                        </div>

                                        {/* Only show the second line if time zones are different */}
                                        {selectedTherapist && localTimeZone !== selectedTherapist.timeZone && (
                                            <div style={{fontSize: "0.8em", opacity: 0.5}}>
                                                {DateTime.fromObject({hour}, {zone: localTimeZone})
                                                    .setZone(selectedTherapist.timeZone)
                                                    .toFormat("HH:00")}
                                            </div>
                                        )}
                                    </TableCell>


                                    {Array.from({length: daysInMonth}, (_, dayIndex) => (
                                        <GridCellMemoized
                                            key={`day-${dayIndex + 1}-hour-${hour}`}
                                            isSelected={memoizedIsCellSelected(dayIndex + 1, hour)}
                                            selectedDate={selectedDate}
                                            day={dayIndex + 1}
                                            dayData={datesWithHours[dayIndex]}
                                            hour={hour}
                                            codes={codes}
                                            displayMode={displayMode}
                                            isDarkMode={isDarkMode}
                                            onMouseDown={(e) => {
                                                if (e.button === 0) {
                                                    handleMouseDown(e, dayIndex + 1, hour);
                                                }
                                            }}
                                            onMouseEnter={(e) => handleMouseEnter(e, dayIndex + 1, hour)}
                                            onMouseUp={(e) => handleMouseUp(e)}
                                            onDoubleClick={(e) => handleDoubleClick(e, dayIndex + 1, hour)}
                                            cellSize={defaultCellSize * zoom}
                                            localTimeZone={localTimeZone}
                                            dataTimeZone={selectedTherapist?.timeZone ?? localTimeZone}
                                            showTimes={showTimes}
                                            zoom={zoom}
                                            onHoverChange={handleTooltipChange}
                                        />

                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FloatingTooltip tooltipData={tooltipData}/>
            </Grid>


            <Grid item xs={12} sm={isCollapsed ? 0 : 4} maxHeight='calc(100vh - 300px)'>
                {!isCollapsed && (
                    <div>
                        <AppBar position="static">
                            <Toolbar style={{minHeight: '48px'}}>

                                <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="h6" component="div"
                                                style={{display: isCollapsed ? 'none' : 'block'}}>
                                        {overlay.title}
                                    </Typography>
                                </Box>
                            </Toolbar>
                        </AppBar>

                        <Paper elevation={1} sx={{
                            maxHeight: 'calc(100vh - 300px)',
                            overflowY: 'auto',
                            justifyContent: 'centre',
                            alignItems: 'centre'
                        }}>
                            {selection && (
                                <Timeline position="alternate">
                                    <CustomTimelineItem
                                        status={overlay.data?.status}
                                        codes={codes}
                                        isLast={!overlay.data?.changes.length}
                                        position={'left'}
                                        displayMode={displayMode}
                                    />

                                    {overlay.data?.changes.slice().reverse().map((change, index, reversedArray) => {
                                        const position = index % 2 === 0 ? 'right' : 'left';
                                        return (
                                            <CustomTimelineItem
                                                key={index}
                                                status={change.status}
                                                codes={codes}
                                                isLast={index === reversedArray.length - 1}
                                                position={position}
                                                displayMode={displayMode}
                                            />

                                        );
                                    })}
                                </Timeline>
                            )}
                        </Paper>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};


export default MonthlyGrid;
