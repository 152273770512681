import React, {useEffect, useState} from 'react';
import {
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import TodayIcon from '@mui/icons-material/Today';
import SpaIcon from '@mui/icons-material/Spa';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';
import CreateIcon from '@mui/icons-material/Create';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import {useCustomSavedReports} from "../Reports/data";
import {useFloatingMenuContext} from "../Booking/floatingMenu";

export const Menu = ({user, selectedContent, setSelectedContent, drawerOpen, setDrawerOpen}) => {
    const drawerCloseDelay = 3000;
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [openOverview, setOpenOverview] = useState(true);
    const [openSettings, setOpenSettings] = useState(true);
    const [openReport, setopenReport] = useState(true);
    const [openDevelopment, setOpenDevelopment] = useState(true);
    const {allSavedReports} = useCustomSavedReports();
    const { hideMenu } = useFloatingMenuContext();


    useEffect(() => {
        let timer;
        // Only start the timer if the drawer is open and the mouse is not over it
        if (drawerOpen && !isMouseOver) {
            timer = setTimeout(() => {
                setDrawerOpen(false); // This will close the drawer after 3 seconds
            }, drawerCloseDelay);
        }
        // Cleanup timer on unmount or if drawerOpen/isMouseOver state changes
        return () => clearTimeout(timer);
    }, [drawerOpen, isMouseOver, drawerCloseDelay, setDrawerOpen]);


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuItemClick = (content) => {
        hideMenu()
        setSelectedContent(content); // Set the selected content based on the clicked menu item
        setDrawerOpen(false); // Close the drawer
    };

    const handleOverviewClick = () => {
        setOpenOverview(!openOverview); // Toggle the Overview submenu
    };

    const handleSettingsClick = () => {
        setOpenSettings(!openSettings); // Toggle the Overview submenu
    };

    const handleReportClick = () => {
        setopenReport(!openReport); // Toggle the Overview submenu
    };

    const handleDevelopmentClick = () => {
        setOpenDevelopment(!openDevelopment); // Toggle the Overview submenu
    };


    return (
        <>
            <div className="menu-button-container">
                <IconButton onClick={handleDrawerToggle} color="inherit" aria-label="open drawer"
                            sx={{display: 'inline-flex'}}>
                    <MenuIcon/>
                </IconButton>
            </div>

            <Drawer
                variant="temporary"
                open={drawerOpen}
                onClose={handleDrawerToggle} // This allows the drawer to close when clicking outside of it
                ModalProps={{keepMounted: true}}
                PaperProps={{
                    onMouseEnter: () => {
                        setIsMouseOver(true);
                    },
                    onMouseLeave: () => {
                        setIsMouseOver(false);
                    },
                }}
                sx={{
                    width: 250, // Set the fixed width you want
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 250, // Ensure the drawer's paper also gets the fixed width
                    },
                    userSelect:'none'
                }}>

                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2}}>
                    <IconButton onClick={() => handleMenuItemClick('profile')}>
                        <Avatar src={user?.photoURL} alt="User Profile" sx={{width: 64, height: 64}}
                                imgProps={{referrerPolicy: "no-referrer"}}/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{textAlign: 'center'}}>
                        {user?.name}
                    </Typography>
                    <Typography
                        variant="body"
                        sx={{textAlign: 'center'}}>
                        {user?.email}
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{textAlign: 'center'}}>
                        {user?.timeZone}
                    </Typography>
                </Box>

                <List>
                    <Divider/>
                    <ListItem onClick={handleOverviewClick} key={'availability-toggle'}>
                        <ListItemIcon>
                            <SpaIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Availability"/>
                        {openOverview ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Divider/>
                    <Collapse in={openOverview} timeout="auto" unmountOnExit>
                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('therapists')} key={'availability-monthly'}>
                            <ListItemIcon>
                                <CalendarMonthIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Monthly"/>
                        </ListItem>
                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('triage')} key={'availability-daily'}>
                            <ListItemIcon>
                                <CalendarViewDayIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Daily"/>
                        </ListItem>
                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('today')} key={'availability-today'}>
                            <ListItemIcon>
                                <TodayIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Today"/>
                        </ListItem>
                    </Collapse>

                    <Divider/>

                    <ListItem onClick={handleSettingsClick} key={'settings-toggle'}>
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Settings"/>
                        {openSettings ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Divider/>
                    <Collapse in={openSettings} timeout="auto" unmountOnExit>

                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('users')} key={'settings-users'}>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Users"/>
                        </ListItem>

                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('user-profiles')} key={'settings-profiles'}>
                            <ListItemIcon>
                                <AccountBoxIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profiles"/>
                        </ListItem>

                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('codes')} key={'settings-codes'}>
                            <ListItemIcon>
                                <InsertEmoticonIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Booking Codes"/>
                        </ListItem>
                        {/*
                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('hours')}>
                            <ListItemIcon>
                                <AccessTimeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Slot Configs"/>
                        </ListItem>
                        */}
                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('audit')} key={'settings-logs'}>
                            <ListItemIcon>
                                <CreateIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logs"/>
                        </ListItem>

                    </Collapse>
                    <Divider/>
                    <ListItem onClick={handleReportClick} key={'reports-toggle'}>
                        <ListItemIcon>
                            <QueryStatsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Report"/>
                        {openReport ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Divider/>
                    <Collapse in={openReport} timeout="auto" unmountOnExit>
                        <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
                                  onClick={() => handleMenuItemClick('chart-custom')} key={'reports-new'}>
                            <ListItemIcon>
                                <CalendarMonthIcon/>
                            </ListItemIcon>
                            <ListItemText primary="New Report"/>
                        </ListItem>

                        <Divider />

                        {
                            allSavedReports.map(r => (
                                <ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}} key={r.id}
                                          onClick={() => {
                                              handleMenuItemClick(['chart-custom',r]);
                                          }}>
                                    <ListItemIcon>
                                        <CalendarMonthIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={r.name}/>
                                </ListItem>
                            ))
                        }
                    </Collapse>
                </List>
            </Drawer>
        </>
    );
};

/*
<ListItem sx={{pl: 4, cursor: 'pointer', '&:hover': {bgcolor: 'action.hover'}}}
          onClick={() => handleMenuItemClick('setup')}>
    <ListItemIcon>
        <InfoIcon/>
    </ListItemIcon>
    <ListItemText primary="Therapist Info"/>
</ListItem>
 */
