import {TextField} from "@mui/material";
import React from "react";

const ColourSelectComponent = ({value, onChange, label}) => {

    return (
        <TextField type="color" name="backgroundColor" value={value} label={label}
                   onChange={(e) => {
                       onChange(e.target.value);
                   }} fullWidth/>
    );
}

export default ColourSelectComponent;