import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { unpackWorkingHours } from "../Users/data";
import {useEffect, useState} from "react";
import {userCollectionPath} from "../Firestore/paths";

export const DataLoadingState = Object.freeze({
    NONE: 0,
    LOADING: 1,
    LOADED_CACHE: 2,
    LOADED_REMOTE: 3,
    ERROR: 4
});

export const getUserById = async (userID) => {
    try {
        const db = firebase.firestore();
        const doc = await db.collection(userCollectionPath).doc(userID).get();
        if (doc.exists) {
            const data = doc.data();
            const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
            return { id: doc.id, ...data, workingHours };
        } else {
            console.log("No such User : ", userID);
            return null;
        }
    } catch (error) {
        console.error("Error getting user : ", error);
        return null;
    }
};

export const useUsers = (isActive) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingState, setLoadingState] = useState(DataLoadingState.LOADING);
    const [error, setError] = useState(null);
    useEffect(() => {
        const db = firebase.firestore();
        setLoading(true);
        setLoadingState(DataLoadingState.LOADING);
        setError(null);

        let ref = db.collection(userCollectionPath);
        if(isActive != null){
            ref = ref.where('active', '==', isActive)
        }

        const unsubscribe = ref.onSnapshot(
            (snapshot) => {
                try {
                    const updatedUsers = snapshot.docs.map(doc => {
                        const data = doc.data();
                        const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
                        // Clone data to add ‘therapist’ scope if simulateAsTherapist is true
                        const modifiedUser = { id: doc.id, ...data, workingHours };
                        if (modifiedUser.simulateAsTherapist && !modifiedUser.scopes?.includes('therapist')) {
                            modifiedUser.scopes = [...(modifiedUser.scopes || []), 'therapist'];
                        }
                        return modifiedUser;
                    }).sort((a, b) => a.name.localeCompare(b.name));

                    console.log("User service finished loading. User Count: ",snapshot.docs.length, updatedUsers.length);

                    setUsers(updatedUsers);
                    setLoading(false);
                    setLoadingState(snapshot.metadata.fromCache ? DataLoadingState.LOADED_CACHE : DataLoadingState.LOADED_REMOTE);
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                    setLoadingState(DataLoadingState.ERROR);
                }
            },
            (err) => {
                setError(err.message);
                setLoading(false);
                setLoadingState(DataLoadingState.ERROR);
            }
        );
        return () => unsubscribe();
    }, [isActive]);
    return { users, loading, error, loadingState };
};

export const getUserByEmail = async (email) => {
    console.log("Fetch : ",email)
    try {
        const db = firebase.firestore();
        const querySnapshot = await db.collection(userCollectionPath).where('email', '==', email).get();
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; // Assuming email is unique and only returns one document
            const data = doc.data();
            const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
            return { id: doc.id, ...data, workingHours };
        } else {
            console.log("No user found with the email:", email);
            return null;
        }
    } catch (error) {
        console.error("Error getting user by email:", error);
        return null;
    }
};
