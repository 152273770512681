import {useState, useEffect, startTransition} from 'react';
import firebase from 'firebase/compat/app';
import {collection, getDocs, getDoc, query, onSnapshot, doc, updateDoc, setDoc} from "firebase/firestore";



export const useFetchAndSubscribeMonthsData = (therapist, year, month) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        setLoading(true);

        if (!therapist || !year || !month) {
            return;
        }

        console.log(`Fetching data for: ${therapist?.id || "No Therapist"}, Year: ${year || "N/A"}, Month: ${month || "N/A"}`);

        const db = firebase.firestore();
        const datesCollectionPath = `bookings/${therapist.id}/${year}/${month.toString().padStart(2, '0')}/dates`;
        const q = query(collection(db, datesCollectionPath));

        // Fetch initial data
        getDocs(q).then(querySnapshot => {
            const dates = [];
            querySnapshot.forEach(doc => {
                dates.push({ id: doc.id, ...doc.data() });
            });
            setData(dates);
            setLoading(false);
        }).catch(err => {
            setError(err);
            setLoading(false);
        });

        // Subscribe to data updates
        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                const dates = [];
                querySnapshot.forEach(doc => {
                    dates.push({ id: doc.id, ...doc.data() });
                });
                setData(dates);
            },
            (error) => {
                setError(error);
            }
        );

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, [therapist, year, month]);

    return { data, loading, error };
};

export const useFetchAndSubscribeDayData = (therapist, year, month, day) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!therapist || !year || !month || !day) {
            setLoading(false);
            return;
        }

        console.log(`Fetching data for: ${therapist?.id}, Year: ${year}, Month: ${month}, Day: ${day}`);

        const db = firebase.firestore();
        // Construct the document path according to the provided example
        const dateDocPath = `bookings/${therapist.id}/${year}/${month.padStart(2, '0')}/dates/${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

        const docRef = doc(db, dateDocPath);

        // Fetch initial data for the day
        getDoc(docRef).then(docSnapshot => {
            if (docSnapshot.exists()) {
                setData({ id: docSnapshot.id, ...docSnapshot.data() });
            } else {
                // Handle the case where there is no data for the day
                setData(null);
            }
            setLoading(false);
        }).catch(err => {
            setError(err);
            setLoading(false);
        });

        // Subscribe to data updates for the day
        const unsubscribe = onSnapshot(docRef,
            (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setData({ id: docSnapshot.id, ...docSnapshot.data() });
                } else {
                    // Handle the case where data gets deleted or does not exist
                    setData(null);
                }
            },
            (error) => {
                setError(error);
            }
        );

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, [therapist, year, month, day]);

    return { data, loading, error };
};



function isEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !isEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}


// Function to convert cumulative minutes to an angle
function cumulativeMinutesToAngle(cumulativeMinutes) {
    const totalMinutesInHour = 60; // Total minutes in an hour
    return (cumulativeMinutes / totalMinutesInHour) * 360;
}

// Function to calculate cumulative minutes in the array
function calculateCumulativeMinutes(array, index) {
    let sum = 0;
    for (let i = 0; i <= index; i++) {
        sum += array[i].minutes;
    }
    return sum;
}



export const setHourCode = async (sessionUser, therapist, year, month, day, hour, updateArray, recordHistory = true) => {
    if (!therapist || !year || !month || !day || hour === undefined || hour < 0 || hour > 23 ) {
        console.error("Invalid input");
        return false;
    }
    const db = firebase.firestore();
    const dateDocPath = `bookings/${therapist.id}/${year}/${month.padStart(2, '0')}/dates/${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    const docRef = doc(db, dateDocPath);


    try {
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const snapshot = docSnapshot.data();
            const existingHourData = snapshot?.hours?.[hour] || { status: {}, changes: [] };
            let statusMap = existingHourData?.status || {};
            let changesArray = existingHourData?.changes || [];

            // Construct the current state snapshot
            let currentStateSnapshot = {
                status: Object.fromEntries(
                    Object.keys(statusMap).map(key => [key, { ...statusMap[key] }])
                )
            };

            // Check if there's a different number of entries
            const differentNumberOfEntries = Object.keys(statusMap).length !== updateArray.length;

            // Check for changes in updateArray against statusMap
            let hasChanges = differentNumberOfEntries || updateArray.some(update => {
                const existingStatus = statusMap[update.idx];
                const isCodeIdChanged = (existingStatus?.codeId || null) !== (update.code?.id || null);
                const isMinutesChanged = existingStatus?.minutes !== update.minutes;
                const isExtraFieldChanged = JSON.stringify(existingStatus?.extraFieldData) !== JSON.stringify(update.extraFieldData);

                return !existingStatus || isCodeIdChanged || isMinutesChanged || isExtraFieldChanged;
            });

            // Early out if there are no changes
            if (!hasChanges) {
                console.log("No changes detected; no update necessary.");
                return false;
            }

            let newStatusMap = {}
            updateArray.forEach(change => {
                newStatusMap[change.idx] = {
                    codeId: change.code?.id && change.code.id.trim() !== '' ? change.code.id : null,
                    minutes: change.minutes,

                    // ToDo : Feature Flag Saving
                    //notes: statusMap[change.idx].notes,
                    //email: statusMap[change.idx].email,
                    //accesscode: statusMap[change.idx].accesscode,

                    author: {
                        user: sessionUser.id,
                        name: sessionUser.name,
                        email: sessionUser.email,
                        photo: sessionUser.photoURL,
                        tz: sessionUser.timeZone,
                        ts: firebase.firestore.FieldValue.serverTimestamp()
                    },
                    ...(change.extraFieldData && {extraFieldData: change.extraFieldData})
                };
            });


            // Push the current snapshot as the changes
            if (Object.keys(currentStateSnapshot.status).length > 0 && recordHistory) {
                changesArray.push(currentStateSnapshot);
            }

            // Add the cumulative minute ranges to the data.
            // To simplify matching code
            let minutesOffset = 0
            Object.keys(newStatusMap).forEach(key => {
                let data = newStatusMap[key];
                data.start = minutesOffset;
                minutesOffset += data.minutes;
            });

            minutesOffset = 0
            Object.keys(statusMap).forEach(key => {
                let data = statusMap[key];
                data.start = minutesOffset;
                minutesOffset += data.minutes;
            });

            Object.keys(newStatusMap).forEach(newKey => {
                let newStatus = newStatusMap[newKey]
                Object.keys(statusMap).forEach(key => {
                    let status = statusMap[key]
                    if(newStatus.start === status.start) {
                        if(newStatus.minutes === status.minutes) {
                            if(newStatus.codeId === status.codeId) {
                                newStatusMap[newKey].author = status.author
                            }
                        }
                    }
                })
            })

            // Remove the start field
            Object.keys(newStatusMap).forEach(newKey => {
                delete newStatusMap[newKey].start
            })

            let updatePayload = {
                [`hours.${hour}`]: {
                    status: newStatusMap,
                    changes: changesArray
                },
            };

            await updateDoc(docRef, updatePayload);
            console.log("Hour code set or marked as deleted successfully, with change history recorded.");

            return true;
        } else {
            console.error("Document does not exist, cannot create or delete");
            return false;
        }
    } catch (error) {
        console.error("Error setting or marking hour code as deleted with change history:", error);
        return false;
    }
};





export const useFetchAllTherapistsData = (therapists, year, month) => {
    const [allTherapistsData, setAllTherapistsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const db = firebase.firestore();

        const fetchPromises = therapists?.map(therapist => {
            const datesCollectionPath = `bookings/${therapist.id}/${year}/${month.toString().padStart(2, '0')}/dates`;
            return db.collection(datesCollectionPath).get().then(querySnapshot => {
                const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                return { therapistId: therapist.id, data: dates };
            }).catch(err => {
                setError(err); // This simplistic error handling will stop at the first error
                return { therapistId: therapist.id, data: [], error: err };
            });
        });

        Promise.all(fetchPromises).then(results => {
            setAllTherapistsData(results);
            setIsLoading(false);
        }).catch(err => {
            setError(err);
            setIsLoading(false);
        });
    }, [therapists, year, month]);

    return { allTherapistsData, isLoading, error };
};
