import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import firebase from "firebase/compat/app";
import styled from "@emotion/styled";
import {CircularProgress} from "@mui/material";
import {getUserByEmail} from "../ListBoxes/userService";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://thrive.uk.com">
                Thrive
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

async function googleSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider();
    // To restrict to your organization's domain
    provider.setCustomParameters({
        'hd': 'thrive.uk.com'
    });

    try {
        const result = await firebase.auth().signInWithPopup(provider);
        // Attempt to get the user with retries
        const user = await getUserWithRetries(result.user.email, 5, 2000); // 5 retries, 2000ms delay

        if (user && typeof user.timeZone === 'undefined') {
          //  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
          //  const userDocRef = firebase.firestore().collection('users').doc(user.id);
          //  await userDocRef.update({
          //      timeZone: tzid
          //  });
        } else if (!user) {
            console.error("User not found after retries");
            throw new Error("User not found after retries");
        }
    } catch (error) {
        console.error("Error during sign in or user update", error);
        throw error;
    }
}

export async function getUserWithRetries(email, retries, delay) {
    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            const user = await getUserByEmail(email);
            if (user) return user; // If user is found, return it
        } catch (error) {
            // Log the error or handle specific cases if necessary
            console.error(`Attempt ${attempt + 1} failed:`, error);
        }
        // Wait for a specified delay before retrying
        await new Promise(resolve => setTimeout(resolve, delay));
    }
    // After all retries, return null or throw an error based on your preference
    return null;
}


const GoogleButton = styled(Button)({
    backgroundColor: '#4285F4',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#357ae8',
    },
    padding: '10px',
    border: 'none',
    borderRadius: '2px',
    fontSize: '16px',
});

const GoogleSignInButton = () => {
    return (
        <GoogleButton
            id="googleSignInButton"
            onClick={googleSignIn}
            startIcon={
                <img
                    src="https://developers.google.com/identity/images/g-logo.png"
                    alt="Google sign-in"
                    style={{ marginRight: '10px', verticalAlign: 'middle', width: '18px', height: '18px' }}
                />
            }
        >
            Sign in with Google
        </GoogleButton>
    );
};

export default function Authentication({mode,login}) {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    const file = mode === 'dark' ? 'url(backdropdark.webp)' : 'url(backdrop.webp)'

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: file,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{overflow: 'hidden'}}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', // Add this line to center content vertically
                        height: '100%', //
                    }}
                >

                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Authentication
                    </Typography>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                        <Box sx={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            {login ? (
                                <CircularProgress/>
                            ) : (
                                <GoogleSignInButton/>
                            )}
                            <br/>
                            <br/>
                            <img src='thrive.png' alt="Logo" style={{maxWidth: '150px', marginBottom: '20px'}}/>
                        </Box>

                        <Typography
                            variant="body2"
                            sx={{mt: 2, textAlign: 'center'}}>
                            Optimising Therapy, Maximising Wellbeing.
                        </Typography>

                        <Copyright sx={{mt: 5}}/>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
