export const transformCountsToChartData = (idCounts, codes) => {
    // Map the idCounts object to the desired array format
    const chartData = Object.entries(idCounts).map(([code, count]) => {

        const thisCode = codes.find(c => c.id === code)

        return {
            id: thisCode.text,
            label: thisCode.code,
            value: count,
            color: thisCode.background
        };
    });

    return chartData;
};

function processTherapistSegments(allTherapistsData, inclusionList, processorFn) {
    for(const therapistData of allTherapistsData){
        for(const dayData of therapistData.data){
            // Now dayData.hours is an object, not an array
            for(const hourData of Object.values(dayData.hours)){
                for (const segment of Object.values(hourData.status)) {
                    // Assuming hourData includes a 'status' property
                    const statusId = segment.codeId;
                    const duration = segment.minutes;
                    if (inclusionList.find(code => code.id === statusId)) {
                        processorFn(therapistData.therapistId, statusId, duration);
                    }
                }
            }
        }
    }
}

export const countTherapistCodeMinutes = (allTherapistsData, inclusionList) => {
    const idCounts = {};

    processTherapistSegments(allTherapistsData, inclusionList, (therapistId, statusId, duration) => {
        idCounts[statusId] = (idCounts[statusId] || 0) + duration;
    });

    return idCounts;
};

export const countTherapistCodeSegments = (allTherapistsData, inclusionList) => {
    const idCounts = {};

    processTherapistSegments(allTherapistsData, inclusionList, (therapistId, statusId, _) => {
        idCounts[statusId] = (idCounts[statusId] || 0) + 1;
    });

    return idCounts;
};

export const countTherapistCodeMinutesPerID = (allTherapistsData, inclusionList) => {
    const therapistCounts = {};

    processTherapistSegments(allTherapistsData, inclusionList, (therapistId, statusId, duration) => {
        if (!therapistCounts[therapistId]) {
            therapistCounts[therapistId] = {};
        }

        therapistCounts[therapistId][statusId] = (therapistCounts[therapistId][statusId] || 0) + duration;
    });

    return therapistCounts;
};

export const countTherapistCodeSegmentsPerID = (allTherapistsData, inclusionList) => {
    const therapistCounts = {};

    processTherapistSegments(allTherapistsData, inclusionList, (therapistId, statusId, duration) => {
        if (!therapistCounts[therapistId]) {
            therapistCounts[therapistId] = {};
        }

        therapistCounts[therapistId][statusId] = (therapistCounts[therapistId][statusId] || 0) + 1;
    });

    return therapistCounts;
};



export const transformForStackedBarChart = (therapistData, therapists, codes) => {
    let transformedData = [];

    // Iterate over therapistData if it exists
    Object.entries(therapistData || {}).forEach(([therapistId, countsByCode]) => {
        // Find the therapist's name using the therapistId
        const therapistName = therapists.find(t => t.id === therapistId)?.name || 'Unknown';
        let transformedTherapistData = {
            therapist: therapistName,
        };

        // Ensure countsByCode exists and is an object before iterating
        if (countsByCode && typeof countsByCode === 'object') {
            Object.entries(countsByCode).forEach(([codeId, count]) => {
                const codeDetail = codes.find(c => c.id === codeId);

                if (codeDetail) {
                    transformedTherapistData[codeDetail.text] = count;
                    transformedTherapistData[`${codeDetail.text}Color`] = codeDetail.background;
                }
            });
        }

        transformedData.push(transformedTherapistData);
    });

    return transformedData;
};

export function calculateChartLabel(value, reportUnit, small = false){
    if(reportUnit === 'session-count'){
        return `${pluralise(value, 'session')}`;
    }

    let output = '';

    if(reportUnit === 'duration-long') {
        const minutesPerWeek = 60 * 24 * 7;
        if (value >= minutesPerWeek) {
            const weeks = Math.floor(value / minutesPerWeek);
            value -= weeks * minutesPerWeek;

            output = `${small ? `${weeks}w` : pluralise(weeks,'week')}`;
        }

        const minutesPerDay = 60 * 24;
        if (value >= minutesPerDay) {
            const days = Math.floor(value / minutesPerDay);
            value -= days * minutesPerDay;

            output = `${output} ${small ? `${days}d` : pluralise(days,'day')}`;
        }
    }

    const minutesPerHour = 60;
    if(value >= minutesPerHour){
        const hours = Math.floor(value/minutesPerHour);
        value -= hours * minutesPerHour;

        output = `${output} ${small ? `${hours}h` : pluralise(hours,'hour')}`;
    }

    if(value > 0){
        output = `${output} ${small ? `${value}m` : pluralise(value,'minute')}`;
    }

    return output.trim();
}

function pluralise(value, text){
    return `${value} ${text}${value === 1 ? '' : 's'}`;
}