import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Users from "./users";
import {UsersWithSameActivity} from "../Presence/presence";
import {ListItemText, Menu, MenuItem, Switch, Tooltip} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";

export function UserManagementApp({ onCloseWindow }) {
    const [showActiveUsers, setShowActiveUsers] = React.useState(true);
    const [showInactiveUsers, setShowInactiveUsers] = React.useState(true);
    const [showInvitedUsers, setShowInvitedUsers] = React.useState(true);

    const [settingsAnchor, setSettingsAnchor] = React.useState(null);
    const open = Boolean(settingsAnchor);
    const openSettings = (event) => {
        setSettingsAnchor(event.currentTarget);
    };
    const closeSettings = () => {
        setSettingsAnchor(null);
    };

    return (
        <Paper sx={{ width: '90%', height: '85vh', padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <AppBar position="static" style={{marginBottom: '24px'}}>
                <Toolbar>
                    <IconButton color="inherit">
                        <PeopleIcon />
                    </IconButton>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                User Management
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <UsersWithSameActivity currentActivity={'users'}/>
                    </Grid>
                    <Tooltip title="Settings">
                        <IconButton color="inherit" onClick={openSettings}>
                            <SettingsIcon/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={settingsAnchor}
                        open={open}
                        onClose={closeSettings}>

                        <MenuItem onClick={() => {setShowActiveUsers(!showActiveUsers)}} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px'}}>
                            <ListItemText primary="Active Users"/>
                            <Switch checked={showActiveUsers} onChange={(e) => setShowActiveUsers(e.target.checked)} />
                        </MenuItem>
                        <MenuItem onClick={() => {setShowInactiveUsers(!showInactiveUsers)}} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px'}}>
                            <ListItemText primary="Inactive Users"/>
                            <Switch checked={showInactiveUsers} onChange={(e) => setShowInactiveUsers(e.target.checked)} />
                        </MenuItem>
                        <MenuItem onClick={() => {setShowInvitedUsers(!showInvitedUsers)}} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '20px'}}>
                            <ListItemText primary="Invited Users"/>
                            <Switch checked={showInvitedUsers} onChange={(e) => setShowInvitedUsers(e.target.checked)} />
                        </MenuItem>
                    </Menu>
                    <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Users showActive={showActiveUsers} showInactive={showInactiveUsers} showInvited={showInvitedUsers}/>
        </Paper>
    );
}
