import React, {useEffect} from 'react';
import {useCodes} from '../Codes/data';
import {Box, Typography} from '@mui/material';
import {RenderCodeBadge} from "../Booking/codebadge";
import GenericSelector, {GenericListSelector} from "./GenericSelector";

function renderCode(selected){
    if (!selected) return null;
    const codes=[{
            code: selected.code,
            duration: 60,
            background: selected.background,
            foreground: selected.foreground,
            halfLuminance: selected.halfLuminance,
        }]

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <RenderCodeBadge width={32} height={32} radius={15} codes={codes} hasBackground={false}/>
            <Typography>{selected.emoji}</Typography>
            <Typography>{selected.text}</Typography>
        </Box>
    );
}

export function CodeDropdownSelect({ selectedCode, setSelectedCode, tooltipText="Select a predefined code to be applied to a time slot. Codes are managed from Booking Codes.",inputLabelText="Select a code to apply" }){
    const { codes } = useCodes();

    return ( <GenericSelector
        allValues={codes}
        selectedValues={selectedCode}
        setSelectedValues={setSelectedCode}
        renderOption={renderCode}
        tooltipText={tooltipText}
        inputLabelText={inputLabelText}
    /> );
}

export function CodesListSelect({selectedCode, setSelectedCode}){
    const { codes } = useCodes();

    useEffect(() => {setSelectedCode(codes && codes.length > 0 ? codes[0] : "")}, [codes]);

    return <GenericListSelector
        allValues={codes}
        selectedValues={selectedCode}
        setSelectedValues={setSelectedCode}
        renderOption={renderCode}
    />
}
