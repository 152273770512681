import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {DatePickerComponent} from "./DatePicker";
import {getDataForDayHour, MonthlyGridMemoized} from "./MonthlyGrid";
import {setHourCode, useFetchAndSubscribeMonthsData} from "./dataServce";
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkingHoursSelector from "../WorkingHours/workinghours";
import {CreateDateRange, deleteMonthForTherapist} from "../Users/data";
import {useOnlineUsers, UsersWithSameActivity} from "../Presence/presence";
import CloseButton from "../Components/closeButton";
import {updateUserActivity, updateUserPosition, updateUserSelected} from "../App/App";
import {CustomiseHourSlotModal} from "./HourSlotModal";
import {useCodes} from "../Codes/data";
import SettingsIcon from "@mui/icons-material/Settings";
import {NoDefaultsUserDropdownSelect, UserDropdownSelect} from "../ListBoxes/UserSelect";
import Timezone from "../Timezone/timezone";
import {useFloatingMenuContext} from "./floatingMenu";
import {DataLoadingState, useUsers} from "../ListBoxes/userService";
import {filterUsersByScope} from "../Users/userUtils";

export const generateTitleString = (year, month, start, end, isLocalTime, sessionUser, selectedActiveTherapist) => {
    if (!start || !end) return "";

    let title = Timezone.getFormattedDate(
        year, month, start.day, start.hour,
        undefined, undefined,
        !isLocalTime ? sessionUser.timeZone : undefined,
        !isLocalTime ? selectedActiveTherapist.timeZone : undefined
    );

    if (start.day !== end.day || start.hour !== end.hour) {
        title += "\n" + Timezone.getFormattedDate(
            year, month, end.day, end.hour,
            undefined, undefined,
            !isLocalTime ? sessionUser.timeZone : undefined,
            !isLocalTime ? selectedActiveTherapist.timeZone : undefined
        );
    }

    return title;
};

export const Monthly = ({onCloseWindow}) => {
    const {me} = useOnlineUsers();
    const sessionUser = me();
    const {codes} = useCodes();
    const [selectedCode, setSelectedCode] = useState("");
    const selectionRef = useRef({start: null, end: null});
    const [displayMode, setDisplayMode] = useState('code'); // 'code' or 'emoji'
    const [zoom, setZoom] = useState(1);

    const [selectedActiveTherapist, setSelectedActiveTherapist] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substr(0, 10));

    const scopeFilter = "therapist";
    const { users, loadingState } = useUsers(true);

    useEffect(() => {
        if (loadingState === DataLoadingState.LOADED_REMOTE) {
            const filteredUsers = filterUsersByScope(users, scopeFilter)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            if(filteredUsers.some(t => t.id === sessionUser.id)){
                setSelectedActiveTherapist(sessionUser);
            } else {
                setSelectedActiveTherapist(filteredUsers[0]);
            }
        }
    }, [users, loadingState, scopeFilter]);

    const {year, month} = useMemo(() => {
        if (!selectedDate) return {year: '', month: ''};
        return {
            year: selectedDate.substring(0, 4),
            month: selectedDate.substring(5, 7),
        };
    }, [selectedDate]);

    const {data, loading, error} = useFetchAndSubscribeMonthsData(selectedActiveTherapist, year, month);

    const [isTimeCircleModalOpen, setIsTimeCircleModalOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [updateData, setUpdateData] = useState([]);
    const [overlay, setOverlay] = useState({
        visible: false,
        x: 0,
        y: 0,
        data: null,
        title: '',
    });

    const {showMenu, hideMenu, menuState, updateMenuState} = useFloatingMenuContext();
    const [menuTitle, setMenuTitle] = useState("");

    const handleCloseContextMenu = () => {
        hideMenu();
    };

    useEffect(() => {
        if(selectedActiveTherapist) {
            hideMenu()
            updateUserSelected(selectedActiveTherapist.email);
        }
    }, [selectedActiveTherapist]);

    useEffect(() => {
        showMenu(menuState.x, menuState.y, {
            ...menuState,
            selectedCode: selectedCode,
        });
    }, [selectedCode]);


    useEffect(() => {
        updateMenuState({
            title: menuTitle
        }, false)
    }, [menuTitle]);

    const handleFloatingMenu = (x, y, visible) => {
        showMenu(x, y, {
            visible: visible,
            title: menuTitle,
            selectedCode: selectedCode,
            setSelectedCode: setSelectedCode,
            showClock: sessionUser.timeZone !== selectedActiveTherapist.timeZone,
            clockToggle: handleClockToggle,
            onClear: clearSelection,
            onSplit: splitSelection,
            onClose: handleCloseContextMenu,
        })
    }

    const [isLocalTime, setIsLocalTime] = useState(false);

    useEffect(() => {
        UpdateTitle();
    }, [isLocalTime]);

    const handleClockToggle = (value) => {
        setIsLocalTime(value);
    };

    useEffect(() => {
        setSelectedCode(codes && codes.length > 0 ? codes[0] : "")
    }, [codes]);

    const canEdit = useMemo(() => (
        sessionUser.scopes?.includes('manage') || sessionUser.id === selectedActiveTherapist.id
    ), [sessionUser, selectedActiveTherapist]);

    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const [isShowTimes, setIsShowTimes] = React.useState(false);

    const toggleDisplayMode = () => {
        setDisplayMode(prev => prev === 'code' ? 'emoji' : 'code');
    };

    const handleDateChange = (year, month, day) => {
        const date = new Date(Date.UTC(year, month, day));
        const formattedDate = date.toISOString()
        setSelectedDate(formattedDate);
    };

    const clearSelection = async (e) => {
        e.preventDefault();
        if (selectionRef.current?.start) {
            const updatedData = await updateDataInRange(selectionRef.current, '', data, [{
                idx: 0, code: '', minutes: 60
            }])
            updateGridData(updatedData);
        }
    };

    const splitSelection = async (code, time) => {
        if (!selectionRef.current?.start || !code) {
            console.warn("splitSelection: No valid selection or selectedCode", selectionRef.current);
            return;
        }

        const segments = 60 / time;
        const timeSlots = Array.from({length: segments}, (_, idx) => ({
            idx,
            code: code,
            minutes: time
        }));

        const updatedData = await updateDataInRange(selectionRef.current, '', data, timeSlots);
        updateGridData(updatedData);
    };

    const handleActiveTherapistSelection = (therapist) => {
        setSelectedActiveTherapist(therapist);
    }

    const [selectionTrigger, setSelectionTrigger] = useState(0);

    const dayData = useMemo(() => {
        if (
            selectedDate === null ||
            selectionRef.current?.start?.day === undefined ||
            selectionRef.current?.start?.hour === undefined
        ) return null;

        return getDataForDayHour(year, month, selectionRef.current.start.day, selectionRef.current.start.hour, data);
    }, [selectedDate, year, month, data, selectionTrigger]);


    const UpdateTitle = () => {
        if (!selectionRef.current?.start || !selectionRef.current?.end) return;

        const title = generateTitleString(
            year,
            month,
            selectionRef.current.start,
            selectionRef.current.end,
            isLocalTime,
            sessionUser,
            selectedActiveTherapist
        );

        setMenuTitle(title);
        updateMenuState({ title }, false);
    };

    const handleSelectionChange = useCallback((newSelection) => {
        if (!newSelection) {
            selectionRef.current = {start: null, end: null};
            return;
        }
        selectionRef.current = newSelection;
        setSelectionTrigger(prev => prev + 1);

        if (selectionRef.current?.start && selectionRef.current?.end) {
            const {start, end} = selectionRef.current;

            if (start.day === end.day && start.hour === end.hour) {
                const cellData = getDataForDayHour(year, month, start.day, start.hour, data);
                const statusArray = cellData?.status ? Object.values(cellData.status) : [];

                if (statusArray.length > 0) {
                    const firstCode = statusArray[0]?.codeId;
                    const allSameCode = statusArray.every(entry => entry.codeId === firstCode);

                    if (allSameCode) {
                        setSelectedCode(codes.find(code => code.id === firstCode) || codes[0]);
                    } else {
                        setSelectedCode('');
                    }
                }
            }

            const startDay = Math.min(newSelection.start.day, newSelection.end.day);
            const endDay = Math.max(newSelection.start.day, newSelection.end.day);
            const startHour = Math.min(newSelection.start.hour, newSelection.end.hour);
            const endHour = Math.max(newSelection.start.hour, newSelection.end.hour);

            const normalizedSelection = {
                therapistId: selectedActiveTherapist.id,
                start: { day: startDay, hour: startHour },
                end: { day: endDay, hour: endHour }
            };

            updateUserPosition(normalizedSelection);
        }

        UpdateTitle(isLocalTime);

    }, [data, isLocalTime]);


    const updateGridData = useCallback((updatedData) => {
        setUpdateData(updatedData);
    }, []);

    const updateDataInRange = useCallback(async (selection = selectionRef.current, code, dataArray, updateArray) => {
        const {start, end} = selection;
        const year = selectedDate.substring(0, 4);
        const month = selectedDate.substring(5, 7);
        const promises = [];

        for (let day = start.day; day <= end.day; day++) {
            for (let hour = start.hour; hour <= end.hour; hour++) {
                if (day > 0 && hour >= 0 && hour < 24) {
                    const dayIndex = day - 1;
                    if (dataArray[dayIndex]?.hours) {
                        const hourData = dataArray[dayIndex].hours[hour] || {status: null};
                        promises.push(
                            setHourCode(sessionUser, selectedActiveTherapist, year, month, day.toString(), hour.toString(), updateArray).then((operationSuccessful) => {
                                if (operationSuccessful) {
                                    if (code) {
                                        hourData.status = [{codeId: code.id}];
                                    } else {
                                        delete hourData.status;
                                    }
                                }
                            })
                        );
                    }
                }
            }
        }

        await Promise.all(promises);
        return dataArray;
    }, [selectedDate, sessionUser, selectedActiveTherapist]);

    const handleCreate = () => {
        CreateDateRange(sessionUser, selectedActiveTherapist, codes, parseInt(month, 10), parseInt(year, 10), parseInt(month, 10), parseInt(year, 10), (progress) => {
        });
    }

    const handleReset = () => {
        deleteMonthForTherapist(selectedActiveTherapist.id, parseInt(year, 10), parseInt(month, 10))
    }

    const monthName = new Date(0, month - 1).toLocaleString('en-GB', {month: 'long'})
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    // Disable default RMB Menu
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])

    const handleCloseTimeCircleModal = () => {
        setIsTimeCircleModalOpen(false);
        updateUserActivity('therapists')
    };

    const [saveFunc, setSaveFunc] = useState(null);

    const handleSetSaveFunc = useCallback((fn) => {
        console.log("Recieved onSave from child", fn)
        setSaveFunc(() => fn);
    }, []);

    const handleDoubleClick = (e, day, hour) => {

        console.log("DoubleClick", day, hour)

        selectionRef.current.start = {day, hour};
        selectionRef.current.end = {day, hour};

        setIsTimeCircleModalOpen(true);
    };

    const handleToggleSidebar = () => {
        setIsCollapsed(prev => !prev);
    };

    const handleToggleShowTimes = () => {
        setIsShowTimes(prev => !prev);
    };


    const [showNoTherapistsMessage, setShowNoTherapistsMessage] = useState(false);

    useEffect(() => {
        let timer;
        if (loading) {
            timer = setTimeout(() => {
                if (!selectedActiveTherapist) {
                    setShowNoTherapistsMessage(true);
                }
            }, 1000); // 1000ms = 1 second delay
        } else {
            setShowNoTherapistsMessage(false); // Reset when loading completes
        }

        return () => clearTimeout(timer); // Cleanup on unmount or re-render
    }, [loading, selectedActiveTherapist]);


    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const isSettingsMenuOpen = Boolean(settingsAnchorEl);

    const handleSettingsClick = (event) => {
        setSettingsAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const handleCloseWindow = () => {
        hideMenu();
        onCloseWindow();
    };

    return (

        <Paper sx={{
            overflow: 'auto',
            width: '100%',
            maxWidth: '95vw',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            userSelect: 'none'
        }}>

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, flex: 1}}>
                <AppBar position="static">
                    <Toolbar variant='dense' padding={1}>
                        <div className="toolbar-container">
                            <div className="toolbar-element toolbar-start">
                                <div className="toolbar-icon">
                                    <Tooltip
                                        title="Explore the Monthly Overview to see therapists' bookings at a glance. Each cell represents an hour of the day, organised by dates, providing a comprehensive view of availability and appointments throughout the month. Perfect for planning and managing your sessions efficiently."
                                        arrow>
                                        <IconButton color="inherit">
                                            <CalendarMonthIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Typography variant="h6">
                                    Monthly Overview
                                </Typography>
                            </div>
                            <div className="toolbar-element toolbar-centre">
                                <div style={{maxWidth: '300px'}}>
                                    <NoDefaultsUserDropdownSelect
                                        isActive={true}
                                        onSelectUser={handleActiveTherapistSelection}
                                        selectedUser={selectedActiveTherapist}
                                        scopeFilter={scopeFilter}
                                        baseTimezone={sessionUser.timeZone}
                                    />
                                </div>
                                <div style={{maxWidth: '300px'}}><DatePickerComponent onDateChange={handleDateChange}/>
                                </div>
                            </div>
                            <div className="toolbar-element toolbar-end">
                                <UsersWithSameActivity
                                    key={selectedActiveTherapist?.id}
                                    currentActivity={'therapists'}
                                    email={selectedActiveTherapist?.email}
                                    setUserData={setUserData}/>
                                <Tooltip title="Settings">
                                    <IconButton color="inherit" onClick={handleSettingsClick}>
                                        <SettingsIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={settingsAnchorEl}
                                    open={isSettingsMenuOpen}
                                    onClose={handleSettingsClose}
                                >
                                    <MenuItem onClick={() => {
                                        handleToggleSidebar();
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary={!isCollapsed ? "Show History" : "Hide History"}/>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleToggleShowTimes();
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary={!isShowTimes ? "Show Times" : "Hide Times"}/>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        toggleDisplayMode();
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary={displayMode === 'code' ? "Show Emojis" : "Show Codes"}/>
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem onClick={() => {
                                        setZoom(1);
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary="Zoom x1"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setZoom(2);
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary="Zoom x2"/>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setZoom(5);
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary="Zoom x5"/>
                                    </MenuItem>
                                    {canEdit && (<Divider/>)}
                                    {canEdit && (
                                        <MenuItem onClick={() => {
                                            handleReset();
                                            handleSettingsClose();
                                        }}>
                                            <ListItemText primary="Reset Data"/>
                                        </MenuItem>
                                    )}
                                </Menu>
                                <CloseButton onCloseWindow={handleCloseWindow}/>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>

                {/*(loading || !selectedActiveTherapist) ? (
                    showNoTherapistsMessage ? (
                        <Typography variant="h6" align="center">
                            No Therapists Available
                        </Typography>
                    ) : (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                            <CircularProgress/>
                        </Box>
                    )
                ) : null*/}

                {//(!loading) &&
                    <Box sx={{
                        flex: 1,
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                    }}>
                        <div style={{'position': 'relative'}}>
                            {loading && (
                                <div className="expanded-darkener">
                                    <CircularProgress/>
                                </div>
                            )}
                            {!loading && canEdit && (!data || data.length === 0) && (
                                <div className="expanded-darkener">
                                    <Paper sx={{padding: '1em'}}>
                                        <Typography variant="h5" color="text.secondary" align="center">
                                            No data initialised for {monthName} {year}, adjust the working hours where
                                            necessary,
                                            and press the button!
                                            <WorkingHoursSelector selectedUser={selectedActiveTherapist}/>
                                            <Button sx={{maxWidth: '300px'}} variant='contained' color='primary'
                                                onClick={handleCreate}>Initialise {monthName} {year}</Button>
                                        </Typography>
                                    </Paper>
                                </div>
                            )}

                            <MonthlyGridMemoized
                                displayMode={displayMode}
                                selectedTherapist={selectedActiveTherapist}
                                selectedDate={selectedDate}
                                onSelectionChange={handleSelectionChange}
                                selectionRef={selectionRef}
                                datesWithHours={data}
                                codes={codes}
                                isDarkMode={isDarkMode}
                                setOverlay={setOverlay}
                                overlay={overlay}
                                zoom={zoom}
                                updateData={updateData}
                                onDoubleClick={handleDoubleClick}
                                activeUsers={userData}
                                historyVisible={isCollapsed}
                                localTimeZone={sessionUser.timeZone}
                                showTimes={isShowTimes}
                                setContextMenu={handleFloatingMenu}
                            />
                        </div>
                    </Box>
                }
            </Box>

            {isTimeCircleModalOpen && (<CustomiseHourSlotModal
                isOpen={isTimeCircleModalOpen}
                onClose={handleCloseTimeCircleModal}
                sessionUser={sessionUser}
                selectedTherapist={selectedActiveTherapist}
                year={year}
                month={month}
                day={selectionRef.current?.start?.day?.toString() ?? "1"}
                hour={selectionRef.current?.start?.hour?.toString() ?? "0"}
                data={dayData}
                codes={codes}
                onSave={handleSetSaveFunc}
                localTimezone={sessionUser.timeZone}
                therapistTimezone={selectedActiveTherapist.timeZone}
            />)}

        </Paper>
    );
};


