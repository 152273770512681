import React, { useEffect, useState } from 'react';
import {addDoc, collection, onSnapshot} from 'firebase/firestore';
import {
    Alert,
    Button, Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FilledInput, FormControl, FormHelperText, InputAdornment,
    List,
    ListItem, ListItemButton,
    ListItemText, OutlinedInput,
    TextField, Typography
} from "@mui/material";
import firebase from "firebase/compat/app";
import {sendEmailFunction, useFirebase} from "../App/App";
import {httpsCallable} from "firebase/functions"; // Assume you've initialized Firestore as 'db'

export function InvitesList({onSelectInvite, selectedInvite}) {
    const [invites, setInvites] = useState([]);

    useEffect(() => {
        const db = firebase.firestore();
        const unsubscribe = onSnapshot(collection(db, 'invites'), (snapshot) => {
            const invitesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInvites(invitesData);
        });

        return () => unsubscribe(); // Detach listener on unmount
    }, []);

    const handleListItemClick = (id) => {
        onSelectInvite(id);
    };

    return (
        <List dense>
            {invites.map((invite) => (
                <ListItem
                    key={invite.id}
                    disablePadding
                    selected={selectedInvite.id === invite?.id}
                    onClick={() => handleListItemClick(invite)}
                >
                    <ListItemButton>
                        <ListItemText
                            primary={
                                <Typography variant="body1" style={{ fontSize: '1.0rem' }}> {/* Adjust fontSize as needed */}
                                    {invite.email}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}


export const sendEmail = async (sendEmailFunction,email) => {
    const messageBody = `
              <p>You're invited, please login to the <a href="https://dev.therapy.thrive.uk.com">Therapy Booking System</p>
              <p></p>`;
    try {
        const result = await sendEmailFunction({
            to: email,
            subject: "Thrive Therapy - Account Notification",
            message: messageBody
        });

        console.log(result.data); // Log or handle the result as needed
        //  alert("Email sent successfully!"); // Provide user feedback
    } catch (error) {
        console.error("Error sending email:", error);
        // alert("Failed to send email. Please try again."); // Provide user feedback on error
    }
};



export function InviteModal({ open, onClose }) {
    const [emailPrefix, setEmailPrefix] = useState('');
    const [error, setError] = useState('');
    const domain = '@thrive.uk.com';
    const db = firebase.firestore();

    const { functions } = useFirebase();
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');


    useEffect(() => {
        // Clear email prefix (not including domain) every time the modal is opened
        if (open) {
            setEmailPrefix('');
            setError(''); // Also clear any existing error messages
        }
    }, [open]);

    const handleEmailChange = (e) => {
        // Update email prefix based on user input, exclude domain part
        setEmailPrefix(e.target.value.replace(domain, ''));
    };

    const validateEmail = (email) => {
        // This regex checks for a basic structure before the domain part
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleAddInvite = async () => {
        const fullEmail = `${emailPrefix}${domain}`;
        if (validateEmail(fullEmail)) {
            try {
                await addDoc(collection(db, 'invites'), {
                    email: fullEmail,
                    status: 'pending',
                    timestamp: firebase.firestore.FieldValue.serverTimestamp()
                });
                onClose(); // Close the modal after adding
            } catch (error) {
                console.error(error);
                setError('Failed to add the invite. Please try again.');
            }
        } else {
            setError('Please enter a valid email address.');
        }
    };

    const handleSendInvite = async () => {
        const fullEmail = `${emailPrefix}${domain}`;
        await handleAddInvite();
        sendEmail(sendEmailFunction,fullEmail)
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Invite User</DialogTitle>
            <DialogContent>
                <Card variant="outlined" sx={{ p: 2 }}>
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    <OutlinedInput
                        autoFocus
                        margin="dense"
                        id="email"
                        type="email"
                        fullWidth
                        onChange={handleEmailChange}
                        error={!!error}
                        endAdornment={<InputAdornment position="end">{domain}</InputAdornment>}
                        inputProps={{
                            'aria-label': 'weight',
                            style: { textAlign: 'end' }
                        }}
                    />
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddInvite}>Add</Button>
                <Button onClick={handleSendInvite} color="primary" variant="contained">Send Invite</Button>
            </DialogActions>
        </Dialog>
    );
}