import { withJsonFormsControlProps } from '@jsonforms/react';
import React from "react";
import EmojiSelectComponent from "./EmojiSelectComponent";

const EmojiControl = ({ data, handleChange, path }) => (
    <EmojiSelectComponent
        value={data}
        onChange={(newValue) => handleChange(path, newValue)}
    />
);

export default withJsonFormsControlProps(EmojiControl);