import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useUsers} from "../ListBoxes/userService";

import './Grid.css';

import {DatePickerComponent} from "./DatePicker";
import {setHourCode, useFetchAndSubscribeDayData} from "./dataServce";
import {useCodes} from "../Codes/data";


import TimeZoneService from "../Timezone/timezone";
import {
    AppBar,
    Box, Divider, IconButton, ListItemText, Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Toolbar, Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import {OnlineAvatar} from "../Users/OnlineAvatar";
import {isUserOnline, useOnlineUsers, userLastActive, UsersWithSameActivity} from "../Presence/presence";
import CloseButton from "../Components/closeButton";
import {GridCell} from "./gridcell";
import {CustomiseHourSlotModal} from "./HourSlotModal";
import {ssUserSessionId, updateUserActivity, updateUserPosition} from "../App/App";
import BuildIcon from "@mui/icons-material/Build";
import {FloatingTooltip} from "./floatingToolTip";
import {useFloatingMenuContext} from "./floatingMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import {generateTitleString} from "./monthly";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {UserDropdownSelect} from "../ListBoxes/UserSelect";
import {ctxToOneOfEnumCellProps} from "@jsonforms/react";


const DailyRow = ({
                      selectedDate,
                      therapist,
                      codes,
                      year,
                      month,
                      day,
                      displayMode,
                      selectedItem,
                      onSelectItem,
                      onDoubleClick,
                      isDarkMode = false,
                      isModalOpen,
                      setModalOpen,
                      setHoveredCell,
                      showTimes,
                      userSelections
                  }) => {
    const theme = useTheme();
    const {data: dayData, loading, error} = useFetchAndSubscribeDayData(therapist, year, month, day);
    const {data: selectedDayData} = useFetchAndSubscribeDayData(selectedItem.therapist, year, month, day);
    const {onlineUsers, me} = useOnlineUsers();
    const sessionUser = me();
    const {showMenu, hideMenu, menuState, updateMenuState} = useFloatingMenuContext();


    const [saveFunc, setSaveFunc] = useState(null);

    const handleSetSaveFunc = useCallback((fn) => {
        console.log("Recieved onSave from child", fn)
        setSaveFunc(() => fn);
    }, []);

    const handleDoubleClick = (e, data) => {
        if (onDoubleClick) {
            hideMenu()
            onDoubleClick(e, data);
        }
    };


    if (loading) return <tr>
        <td>Loading...</td>
    </tr>;
    if (error) return <tr>
        <td>Error: {error.message}</td>
    </tr>;
    if (!dayData) return null;

    const stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 100,
        minWidth: 160, maxWidth: 250,
        borderRight: `1px solid ${theme.palette.divider}`,
    };

    const handleCloseTimeCircleModal = () => {
        setModalOpen(false);
        updateUserActivity('therapists');
    };

    let timeZone = ""
    if (therapist.timeZone !== sessionUser.timeZone) {
        timeZone = " (" + TimeZoneService.getTimezoneOffset(sessionUser.timeZone, therapist?.timeZone || 'Europe/London') + ')'
    }

    return (
        <TableRow>
            <TableCell sx={stickyColumnStyle}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <OnlineAvatar
                        user={therapist}
                        online={isUserOnline(therapist, onlineUsers)}
                        lastActive={userLastActive(therapist, onlineUsers)}
                        size={'small'}
                    />

                    <Typography noWrap sx={{fontSize: '12px', maxWidth: '100%', userSelect: 'none'}}>
                        {therapist?.name + timeZone}
                        {therapist?.scopes?.includes('developer') && (
                            <BuildIcon fontSize="small" sx={{marginLeft: 0.5, verticalAlign: 'middle'}}/>
                        )}
                    </Typography>
                </Box>

            </TableCell>

            {[...Array(24).keys()].map(hour => {
                const isSelected = (() => {

             //     if (!therapist || !therapist.id) return { selected: false, source: null };

                    const isHourSelected = selectedItem.selectedHour === hour;

                    const key = `${therapist.id}-${day}-${hour}`;
                    let userSelection = null;
                    if (userSelections instanceof Map) {
                        userSelection = userSelections.get(key);
                    }

                    if (userSelection) {
                        return { selected: true, source: 'user', userId: userSelection.userId, photoURL: userSelection.photoURL };
                    }

                    if (!selectedItem || !selectedItem.therapist) return { selected: false, source: null };

                    const isTherapistSelected = selectedItem.therapist.id === therapist.id;

                    if (isTherapistSelected && isHourSelected) {
                        return { selected: true, source: 'me' };
                    }

                    return { selected: false, source: null };
                })();

                const note = dayData.hours[hour]?.notes || '';
                const accessCode = dayData.hours[hour]?.accesscode || '';
                const email = dayData.hours[hour]?.email || '';

                return (
                    <GridCell
                        selectedDate={selectedDate.toISOString()}
                        day={day}
                        dayData={dayData}
                        hour={hour}
                        codes={codes}
                        displayMode={displayMode}
                        isDarkMode={isDarkMode}
                        onClick={(e) => onSelectItem(therapist, hour, e)}
                        onDoubleClick={(e) => handleDoubleClick(e, {note, accessCode, email})}
                        isSelected={isSelected}
                        isHeatMap={false}
                        cellSize={48}
                        localTimeZone={sessionUser?.timeZone && therapist?.timeZone ? sessionUser.timeZone : "Europe/London"}
                        dataTimeZone={sessionUser?.timeZone && therapist?.timeZone ? therapist.timeZone : "Europe/London"}
                        onHoverChange={setHoveredCell}
                        showTimes={showTimes}
                    />

                );
            })}

            {selectedItem?.therapist?.id === therapist.id && selectedItem?.selectedHour !== -1 && (
                <CustomiseHourSlotModal
                    isOpen={isModalOpen}
                    onClose={handleCloseTimeCircleModal}
                    sessionUser={sessionUser}
                    selectedTherapist={selectedItem.therapist}
                    year={selectedDate.toISOString().substring(0, 4)}
                    month={selectedDate.toISOString().substring(5, 7)}
                    day={selectedDate.toISOString().substring(8, 10)}
                    hour={selectedItem?.selectedHour}
                    data={selectedDayData?.hours[selectedItem.selectedHour]}
                    codes={codes}
                    onSave={handleSetSaveFunc}
                    localTimezone={sessionUser.timeZone}
                    therapistTimezone={selectedItem.therapist.timeZone}
                />
            )}

        </TableRow>
    );
};

const Daily = ({onCloseWindow}) => {
    const {me} = useOnlineUsers();
    const sessionUser = me();
    const {users} = useUsers(true);
    const therapists = users.filter(user => user.scopes && user.scopes.includes('therapist'));
    const [selectedItem, setSelectedItem] = useState({therapist: null, selectedHour: -1});
    const [displayMode, setDisplayMode] = useState('code');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCode, setSelectedCode] = useState("");
    const {codes} = useCodes();
    const [isTimeCircleModalOpen, setIsTimeCircleModalOpen] = useState(false);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    useEffect(() => {
        setSelectedCode(codes && codes.length > 0 ? codes[0] : "")
    }, [codes]);

    const toggleDisplayMode = () => setDisplayMode(prevMode => prevMode === 'code' ? 'emoji' : 'code');

    const handleDateChange = (year, month, day) => {
        const newDate = new Date(Date.UTC(year, month, day));
        if (newDate.getTime() !== selectedDate.getTime()) setSelectedDate(newDate);
    };

    const splitSelection = async (code,time) => {
        const isoDate = selectedDate.toISOString().substr(0, 10);
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10);

        const segments = 60 / time;
        const timeSlots = Array.from({length: segments}, (_, idx) => ({
            idx,
            code: code,
            minutes: time
        }));

        await setHourCode(sessionUser, selectedItem.therapist, year, month, day, selectedItem.selectedHour, timeSlots);
    };

    const clearSelection = async (e) => {
        const isoDate = selectedDate.toISOString().substr(0, 10);
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10);
        await setHourCode(sessionUser, selectedItem.therapist, year, month, day, selectedItem.selectedHour, [{
            idx: 0,
            code: null,
            minutes: 60
        }]);
    };

    const {showMenu, hideMenu, menuState, updateMenuState} = useFloatingMenuContext();

    const [userData, setUserData] = useState(null);


    const userSelections = useMemo(() => {
        let userSelected = new Map();
        if (userData === null) {
            console.log("No user data available");
            return userSelected;
        }

   //     console.log("Building userSelections from userData:", userData);

        userData.forEach(user => {
            const currentSessionId = sessionStorage.getItem(ssUserSessionId);
            user.positions.forEach(position => {
                if (position.sessionId === currentSessionId) {
                    console.log(`Skipping own session for user ${user.id}`);
                    return false;
                }

        //        console.log("Checking position:", position);


                const posStartDay = Math.min(position.start.day, position.end.day);
                const posEndDay = Math.max(position.start.day, position.end.day);
                const posStartHour = Math.min(position.start.hour, position.end.hour);
                const posEndHour = Math.max(position.start.hour, position.end.hour);

                for (let d = posStartDay; d <= posEndDay; d++) {
                    for (let h = posStartHour; h <= posEndHour; h++) {
                        const key = `${position.therapistId}-${d}-${h}`;

                        console.log(`Storing selection: ${key} for user ${user.id}`);

                        if (!userSelected.has(key)) {
                            userSelected.set(key, {
                                selected: true,
                                source: 'user',
                                userId: user.id,
                                therapistId: position.therapistId,
                                photoURL: user.photoURL
                            });
                        }
                    }
                }
            });
        });

        console.log("Final userSelections:", userSelected);
        return userSelected;
    }, [userData]);



    const handleSelectItem = (therapist, hour, event) => {
        setSelectedItem({therapist, selectedHour: hour});
        let title = updateTitle(selectedDate, hour, sessionUser.timeZone === therapist.timeZone, sessionUser, therapist);
        showMenu(event.clientX + 50, event.clientY, {
            title:title,
            selectedCode: menuState.selected,
            setSelectedCode: menuState.setSelectedCode,
            onClear: clearSelection,
            onSplit: splitSelection,
            onClose: handleCloseContextMenu,
        });

        const day = selectedDate.toISOString().substring(8, 10);

        const normalizedSelection = {
            start: { day: day, hour: hour },
            end: { day: day, hour: hour },
            therapistId: therapist.id
        };

       // console.log("Setting normalizedSelection with therapistId:", normalizedSelection);

        updateUserPosition(normalizedSelection);
    };


    useEffect(() => {
        if (selectedItem?.therapist && selectedItem.selectedHour !== -1) {
            showMenu(menuState.x, menuState.y, {
                title:menuState.title,
                selectedCode: selectedCode,
                setSelectedCode: setSelectedCode,
                onClear: clearSelection,
                onSplit: splitSelection,
                onClose: handleCloseContextMenu,
            });
        }
    }, [selectedCode, selectedItem]);

    const handleCloseContextMenu = () => {
        hideMenu();
    };


    const updateTitle = (selectedDate, hour, isLocalTime, sessionUser, therapist) => {
        if (!selectedDate || hour === undefined || !therapist) return;

        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
        const day = String(selectedDate.getDate()).padStart(2, "0");

        const start = { day, hour };
        const end = { day, hour};

        return generateTitleString(year, month, start, end, isLocalTime, sessionUser, therapist);
    };


    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const isSettingsMenuOpen = Boolean(settingsAnchorEl);
    const [isShowTimes, setIsShowTimes] = React.useState(true);

    const handleSettingsClick = (event) => {
        setSettingsAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const handleToggleShowTimes = () => {
        setIsShowTimes(prev => !prev);
    };

    const canEdit = sessionUser.scopes?.includes('manage')

    const [hoveredCell, setHoveredCell] = useState(null);

    const handleCloseWindow = () => {
        hideMenu();
        onCloseWindow();
    };

    return (
        <Paper sx={{width: '95%', maxWidth: '95vw', padding: 2, userSelect: 'none',}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <AppBar position="static">
                    <Toolbar variant='dense' padding={1}>
                        <div className="toolbar-container">
                            <div className="toolbar-element toolbar-start">
                                <div className="toolbar-icon">
                                    <Tooltip
                                        title="Explore the Monthly Overview to see therapists' bookings at a glance. Each cell represents an hour of the day, organised by dates, providing a comprehensive view of availability and appointments throughout the month. Perfect for planning and managing your sessions efficiently."
                                        arrow>
                                        <IconButton color="inherit">
                                            <CalendarViewDayIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Typography variant="h6">
                                    Daily Overview
                                </Typography>
                            </div>
                            <div className="toolbar-element toolbar-centre">
                                    <DatePickerComponent onDateChange={handleDateChange} mode="day" />
                            </div>
                            <div className="toolbar-element toolbar-end">
                                <UsersWithSameActivity
                                    key={'triage'}
                                    currentActivity={'triage'}
                                    setUserData={setUserData}/>

                                <Tooltip title="Settings">
                                    <IconButton color="inherit" onClick={handleSettingsClick}>
                                        <SettingsIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={settingsAnchorEl}
                                    open={isSettingsMenuOpen}
                                    onClose={handleSettingsClose}>

                                    <MenuItem onClick={() => {
                                        handleToggleShowTimes();
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary={!isShowTimes ? "Show Times" : "Hide Times"}/>
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        toggleDisplayMode();
                                        handleSettingsClose();
                                    }}>
                                        <ListItemText primary={displayMode === 'code' ? "Show Emojis" : "Show Codes"}/>
                                    </MenuItem>
                                    <Divider/>

                                </Menu>

                                <CloseButton onCloseWindow={handleCloseWindow} />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>

                <TableContainer component={Paper} sx={{maxHeight: '700px'}}>
                    <Table size="small" stickyHeader>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            {[...Array(24).keys()].map(hour => (
                                <TableCell key={hour}>{hour}:00</TableCell>
                            ))}
                        </TableRow>
                        <TableBody>
                            {therapists.length > 0 ? (
                                therapists.map(therapist => (
                                    <DailyRow
                                        selectedDate={selectedDate}
                                        key={therapist.id}
                                        therapist={therapist}
                                        codes={codes}
                                        year={selectedDate.toISOString().substring(0, 4)}
                                        month={selectedDate.toISOString().substring(5, 7)}
                                        day={selectedDate.toISOString().substring(8, 10)}
                                        displayMode={displayMode}
                                        selectedItem={selectedItem}
                                        onSelectItem={handleSelectItem}
                                        onDoubleClick={() => setIsTimeCircleModalOpen(true)}
                                        isDarkMode={isDarkMode}
                                        isModalOpen={isTimeCircleModalOpen}
                                        setModalOpen={setIsTimeCircleModalOpen}
                                        setHoveredCell={setHoveredCell}
                                        showTimes={isShowTimes && sessionUser.timeZone !== therapist.timeZone}
                                        userSelections = {userSelections}
                                    />
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={25} align="center">
                                        No Therapists Available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <FloatingTooltip tooltipData={hoveredCell}/>
                </TableContainer>
            </Box>
        </Paper>
    );
};

export default Daily;
