import React, {createContext, useState, useContext, useEffect, useCallback} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import {Avatar, AvatarGroup} from "@mui/material";
import {useUsers} from "../ListBoxes/userService";
import {calculateTimeDifferenceInMinutes} from "../Users/userUtils";

export const OnlineUsersContext = createContext();

const INACTIVE_MINS = 1

export const OnlineUsersProvider = ({user, children}) => {
    const [onlineUsers, setOnlineUsers] = useState({});
    const me = () => user;

    useEffect(() => {
        const presenceRef = firebase.database().ref('/presence/');

        presenceRef.on('value', snapshot => {
            if (snapshot.exists()) {
                const updatedOnlineUsers = {};

                snapshot.forEach(userSnapshot => {
                    const userId = userSnapshot.key;
                    const userInfo = userSnapshot.val();
                    const sessions = userInfo.sessions || {};

                    let mostRecentLastActive = null;
                    let isUserOnline = false;

                    Object.values(sessions).forEach(session => {
                        const isOnline = session.status === 'online';
                        if (isOnline) {
                            isUserOnline = true;
                            const lastActive = parseInt(session.lastActive, 10);
                            if (!isNaN(lastActive)) {
                                if (mostRecentLastActive === null || lastActive > mostRecentLastActive) {
                                    mostRecentLastActive = lastActive;
                                }
                            }
                        }
                    });

                    if (isUserOnline && mostRecentLastActive !== null) {
                        updatedOnlineUsers[userId] = {
                            ...userInfo,
                            status: 'online',
                            lastActive: mostRecentLastActive,
                        };
                        delete updatedOnlineUsers[userId].sessions;
                    }
                });

                setOnlineUsers(updatedOnlineUsers);
            }
        });

        return () => presenceRef.off('value');
    }, [user]);

    // Function to update lastActive if it's older than 10 minutes
    const updateLastActive = useCallback(() => {
        if (!user?.id) return;

        const presenceRef = firebase.database().ref(`/presence/${user.id}/sessions`);

        presenceRef.once('value', snapshot => {
            if (snapshot.exists()) {
                const updates = {};
                const now = Date.now();
                const minutesago = now - INACTIVE_MINS * 60 * 1000; // 10 minutes in milliseconds

                snapshot.forEach(sessionSnapshot => {
                    const sessionId = sessionSnapshot.key;
                    const sessionData = sessionSnapshot.val();
                    const lastActive = parseInt(sessionData.lastActive, 10) || 0;

                    if (lastActive < minutesago) {
                        updates[`${sessionId}/lastActive`] = now;
                    }
                });

                if (Object.keys(updates).length > 0) {
                    presenceRef.update(updates);
                }
            }
        });

        return () => presenceRef.off('value'); // Cleanup listener
    }, [user]);

    // Attach event listeners for activity detection
    useEffect(() => {
        let lastUpdate = 0;

        const handleActivity = () => {
            const now = Date.now();
            if (now - lastUpdate > 30000) { // Throttle updates (every 30 sec)
                updateLastActive();
                lastUpdate = now;
            }
        };

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('focus', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('focus', handleActivity);
        };
    }, [updateLastActive]);

    return (
        <OnlineUsersContext.Provider value={{onlineUsers, me}}>
            {children}
        </OnlineUsersContext.Provider>
    );
};

export const useOnlineUsers = () => {
    const context = useContext(OnlineUsersContext);
    if (context === undefined) {
        throw new Error('useOnlineUsers must be used within an OnlineUsersProvider');
    }
    return context;
};

export const isUserOnline = (user, onlineUsers) => {
    return onlineUsers[user.id] && onlineUsers[user.id].status === 'online';
};

export const userLastActive = (user, onlineUsers) => {
    return onlineUsers[user.id]?.lastActive;
};

export const UsersWithSameActivity = ({currentActivity, email = '', setUserData = null}) => {
    const [usersHere, setUsersHere] = useState([]);
    const [userPositions, setUserPositions] = useState([]);
    const {users, loading, error} = useUsers(true);
    const {onlineUsers} = useOnlineUsers();

    useEffect(() => {
        const presenceRef = firebase.database().ref('/presence/');

        if (loading || !users || users.length === 0) {
            console.log("⏳ Skipping update: users not ready.");
            return;
        }

        const handlePresenceUpdate = snapshot => {
            const allPresenceData = snapshot.val() || {};
            const filteredUsers = users.map(user => {
                const userInfo = allPresenceData[user.id];
                if (!userInfo || typeof userInfo !== 'object' || !userInfo.sessions) return null;

                let hasMatchingActivity = false;
                let hasPositionDataOnly = false;

                Object.values(userInfo.sessions).forEach(session => {
                    if (session.activity === currentActivity) {
                        hasMatchingActivity = true;
                    }
                    if (session.position) {
                        hasPositionDataOnly = true;
                    }
                });

                if (hasMatchingActivity || hasPositionDataOnly) {
                    return {
                        ...user,
                        hasPositionDataOnly: hasPositionDataOnly && !hasMatchingActivity
                    };
                }

                return null;
            }).filter(Boolean);

            setUsersHere(filteredUsers);

            const usersAndPositions = filteredUsers.map(user => {
                const positions = Object.entries(allPresenceData[user.id].sessions)
                    .filter(([_, session]) => session.position)
                    .map(([sessionId, session]) => ({
                        start: session.position.start,
                        end: session.position.end,
                        sessionId,
                        therapistId: session.position.therapistId
                    }));

                return {
                    id: user.id,
                    photoURL: user.photoURL,
                    positions,
                    hasPositionDataOnly: user.hasPositionDataOnly
                };
            });

            setUserPositions((usersAndPositions))
        };

        presenceRef.on('value', handlePresenceUpdate);

        // Cleanup on component unmount
        return () => presenceRef.off('value', handlePresenceUpdate);
    }, [loading, currentActivity, users, email, onlineUsers]); // Rerun when currentActivity, users, or email changes

    useEffect(() => {
        if (setUserData !== null) {
            setUserData(userPositions)
        }
    }, [userPositions]);

    return (
        <AvatarGroup max={8}>
            {usersHere.filter(user => !user.hasPositionDataOnly).map(user => {
                const lastActive = userLastActive(user, onlineUsers);
                const timeDiff = calculateTimeDifferenceInMinutes(lastActive);
                const isInactive = timeDiff > INACTIVE_MINS; // Consider user inactive if last active was more than 10 minutes ago
                return (
                    <Avatar
                        key={user.id}
                        alt={user.name || 'Unknown'}
                        src={user.photoURL}
                        sx={{
                            width: '32px',
                            height: '32px',
                            filter: isInactive ? 'grayscale(100%)' : 'none',
                        }}
                        title={user.name || 'Unknown User'}
                    />
                );
            })}
        </AvatarGroup>
    );
};
