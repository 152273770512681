import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Profiles from "./profile";
import {useOnlineUsers, UsersWithSameActivity} from "../Presence/presence";
import {UserDropdownSelect} from "../ListBoxes/UserSelect";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

export function ProfileManagementApp({ onCloseWindow }) {
    const {me} = useOnlineUsers();
    const user = me();
    const [selectedActiveUser, setSelectedActiveUser] = useState(user);

    return (
        <Paper sx={{ width: '90%', height: '85vh', padding: '20px', display: 'flex', flexDirection: 'column' }}>
            <AppBar position="static" style={{marginBottom: '24px'}}>
                <Toolbar variant='dense' padding={1}>
                    <IconButton color="inherit">
                        <AccountBoxIcon />
                    </IconButton>
                    <Grid container alignItems="center" padding={1} spacing={2}>
                        <Grid item>
                            <Typography variant="h6" component="div">
                                User Profile Management
                            </Typography>
                        </Grid>
                        <Grid item sx={{display: 'flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                            <div style={{maxWidth: '300px'}}>
                                <UserDropdownSelect
                                    isActive={true}
                                    onSelectUser={setSelectedActiveUser}
                                    selectedUser={selectedActiveUser}
                                    size={0}
                                    baseTimezone={user.timeZone}
                                />
                            </div>
                        </Grid>
                        <Grid item>
                            <UsersWithSameActivity currentActivity={'user-profiles'}/>
                        </Grid>
                    </Grid>
                    <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Profiles selectedActiveUser={selectedActiveUser} setSelectedActiveUser={setSelectedActiveUser} />
        </Paper>
    );
}
