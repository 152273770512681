import React, {useCallback, useEffect, useRef, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {DatePickerComponent} from "./DatePicker";
import {CalendarLabel, CalendarNumbers, Circle, HourCircleContainer} from "../Styles/clock";
import {setHourCode, useFetchAndSubscribeDayData} from "./dataServce";
import {useCodes} from "../Codes/data";
import {defaultSelectedOptions, TodayOptions} from "./todayoptions";
import SettingsIcon from '@mui/icons-material/Settings';
import {AppBar, IconButton, Paper, Popover, Toolbar, Tooltip, Typography, useTheme} from "@mui/material";
import Grid from "@mui/material/Grid";
import TodayIcon from "@mui/icons-material/Today";
import styled from "@emotion/styled";
import {useOnlineUsers, UsersWithSameActivity} from "../Presence/presence";
import CloseButton from "../Components/closeButton";
import {getCellData, getCellStatus} from "./gridcell";
import {RenderCodeBadge} from "./codebadge";
import {CustomiseHourSlotModal} from "./HourSlotModal";
import {NoDefaultsUserDropdownSelect, UserDropdownSelect} from "../ListBoxes/UserSelect";
import {DateTime} from "luxon";
import {updateUserSelected} from "../App/App";
import {DataLoadingState, useUsers} from "../ListBoxes/userService";
import {useFloatingMenuContext} from "./floatingMenu";
import {filterUsersByScope} from "../Users/userUtils";

export const OuterHourCircleContainer = styled.div`
    position: relative;
    width: 50%; // or whatever percentage you desire
    margin: auto; // this will help center the container
    height: 0;
    padding-bottom: 50%; // this keeps the aspect ratio square
`;

export const Today = ({onCloseWindow}) => {
    const {me} = useOnlineUsers();
    const sessionUser = me();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedCode, setSelectedCode] = useState('');
    const {codes} = useCodes();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [day, setDay] = useState('')
    const [hour, setHour] = useState(-1)
    const [displayOptions, setDisplayOptions] = useState(defaultSelectedOptions);
    const [selectedTherapist, setSelectedTherapist] = useState(null);
    const {data: dayData, loading, error} = useFetchAndSubscribeDayData(selectedTherapist, year, month, day);
    const {showMenu, hideMenu, menuState, updateMenuState} = useFloatingMenuContext();

    const hourRef = useRef(hour);

    const scopeFilter = "therapist";
    const { users, loadingState } = useUsers(true);

    useEffect(() => {
        if (loadingState === DataLoadingState.LOADED_REMOTE) {
            const filteredUsers = filterUsersByScope(users, scopeFilter)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            if(filteredUsers.some(t => t.id === sessionUser.id)){
                setSelectedTherapist(sessionUser);
            } else {
                setSelectedTherapist(filteredUsers[0]);
            }
        }
    }, [users, loadingState, scopeFilter]);

    const [combinedData, setCombinedData] = useState([]);

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const canEdit = sessionUser.scopes?.includes('manage') || sessionUser.id === selectedTherapist.id;


    useEffect(() => {
        setSelectedCode(codes && codes.length > 0 ? codes[0] : "")
    }, [codes]);

    useEffect(() => {
        if (dayData && codes) {
            const combinedArray = Object.keys(dayData.hours).map(hour => {
                const hourData = dayData.hours[hour];
                const status = getCellStatus(hourData, 0)
                const codeInfo = codes.find(code => code.id === status);
                return {
                    hourData: hourData,
                    hour: hour,
                    code: codeInfo ? codeInfo.code : null,
                    background: codeInfo ? codeInfo.background : '#eeeeee',
                    note: hourData.notes || '',
                    label: codeInfo?.text,
                    foreground: codeInfo?.foreground,
                    emoji: codeInfo?.emoji
                };
            });

            const fullCombinedArray = [];
            for (let i = 0; i < 24; i++) {
                const existingEntry = combinedArray.find(entry => parseInt(entry.hour) === i);
                if (existingEntry) {
                    fullCombinedArray.push(existingEntry);
                } else {

                    let entry = {
                        hourData: null,
                        hour: i.toString(),
                        code: null,
                        background: '#eeeeee',
                        note: '',
                        label: '',
                        foreground: '#000000',
                        emoji: ''
                    }

                    if (isDarkMode) {
                        entry.background = '#1e1e1e'
                        entry.foreground = 'white'
                    }

                    fullCombinedArray.push(entry);
                }
            }

            setCombinedData(fullCombinedArray);
        } else {

            let baseEntry = {
                hourData: null,
                hour: 0,
                code: null,
                background: '#FFFFFF',
                note: '',
                label: '',
                foreground: '#000000',
                emoji: ''
            };

            if (isDarkMode) {
                baseEntry.background = '#1e1e1e';
                baseEntry.foreground = 'white';
            }

            const defaultArray = new Array(24).fill(null).map((_, i) => ({
                ...baseEntry, // Spread the baseEntry to clone its properties
                hour: i.toString() // Set the current hour
            }));

            setCombinedData(defaultArray);
        }
    }, [dayData, codes, isDarkMode]);


    const handleDateChange = (year, month, day) => {
        const newDate = new Date(Date.UTC(year, month, day));
        // Compare the time values of the dates
        if (newDate.getTime() !== selectedDate.getTime()) {
            setSelectedDate(newDate);
            const isoDate = newDate.toISOString().substr(0, 10)
            const year = isoDate.substring(0, 4);
            const month = isoDate.substring(5, 7);
            const day = isoDate.substring(8, 10);
            setYear(year)
            setMonth(month)
            setDay(day)
        }
    };

    const handleActiveTherapistSelection = (therapist) => {
        setSelectedTherapist(therapist);
    };

    const applyCodeToSelection = async (e) => {
        e.preventDefault();
        await setHourCode(sessionUser, selectedTherapist, year, month, day, hour, [{
            idx: 0,
            code: selectedCode,
            minutes: 60
        }])
    };

    useEffect(() => {
        hourRef.current = hour;
    }, [hour]);


    const splitSelection = async (code, time) => {
        const isoDate = selectedDate.toISOString().substr(0, 10);
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10);

        const segments = 60 / time;
        const timeSlots = Array.from({length: segments}, (_, idx) => ({
            idx,
            code: code,
            minutes: time
        }));

        await setHourCode(sessionUser, selectedTherapist, year, month, day, hourRef.current, timeSlots);
    };


    const clearSelection = async (e) => {
        await setHourCode(sessionUser, selectedTherapist, year, month, day, hourRef.current, [{idx: 0, code: null, minutes: 60}])
    };


    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({x: 0, y: 0});


    const handleDoubleClick = (e) => {
        if (hour >= 0) {
            console.log("--> ", hour)
            hideMenu()
            setPopupPosition({x: e.clientX, y: e.clientY});
            setPopupVisible(true);
        }
    };

    const handleOptions = (options) => {
        setDisplayOptions(options)
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [saveFunc, setSaveFunc] = useState(null);

    const handleSetSaveFunc = useCallback((fn) => {
        console.log("Recieved onSave from child", fn)
        setSaveFunc(() => fn);
    }, []);

    useEffect(() => {
        updateUserSelected(selectedTherapist?.email ?? '');
    }, [selectedTherapist]);

    const [userData, setUserData] = useState(null);


    return (
        <Paper sx={{width: '95%', padding: 2, userSelect: 'none'}}>
            <AppBar position="static">
                <Toolbar variant="dense" sx={{padding: 1, display: 'flex', justifyContent: 'center'}}>
                    <Grid container alignItems="center">
                        {/* Left-aligned section */}
                        <Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
                            <TodayIcon/>
                            <Typography variant="h6" sx={{paddingLeft: 1, userSelect: 'none'}}>
                                Today
                            </Typography>
                        </Grid>

                        {/* Centered UserDropdownSelect */}
                        <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                            <NoDefaultsUserDropdownSelect
                                isActive={true}
                                onSelectUser={handleActiveTherapistSelection}
                                selectedUser={selectedTherapist}
                                size={0}
                                scopeFilter={scopeFilter}
                                baseTimezone={sessionUser?.timeZone}
                            />
                        </Grid>

                        <Grid item xs={3} sx={{flexGrow: 1, zIndex: 20}}>
                            <DatePickerComponent onDateChange={handleDateChange} mode={'day'}/>
                        </Grid>


                        {/* Right-aligned section */}
                        <Grid item xs={3} sx={{display: 'flex', justifyContent: 'flex-end', gap: 1}}>
                            <UsersWithSameActivity currentActivity={'today'} email={selectedTherapist?.email ?? ''}
                                                   setUserData={setUserData}/>
                            <Tooltip title="Customise the view" arrow>
                                <IconButton color="inherit" onClick={handleClick}>
                                    <SettingsIcon/>
                                </IconButton>
                            </Tooltip>
                            <CloseButton onCloseWindow={onCloseWindow}/>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                padding={2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div style={{padding: '20px', minWidth: '250px'}}> {/* Inline style for padding */}
                    <TodayOptions selectedOptions={displayOptions}
                                  setSelectedOptions={setDisplayOptions}/>
                </div>

            </Popover>

            <div style={{padding: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <OuterHourCircleContainer margin={10}>
                        <HourCircle style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
                                    data={combinedData}
                                    hasLabels={true} currentIndex={currentIndex}
                                    currentDate={selectedDate} selectHour={setHour} onDoubleClick={handleDoubleClick}
                                    displayOptions={displayOptions}
                                    isDarkMode={isDarkMode}
                                    dayData={dayData}
                                    hour={hour}
                                    sessionUser={sessionUser}
                                    selectedTherapist={selectedTherapist}
                                    clearSelection={clearSelection}
                                    splitSelection={splitSelection}
                                    setSelectedCode={setSelectedCode}
                                    selectedCode={selectedCode}
                        />

                    </OuterHourCircleContainer>
                </div>
            </div>

            <CustomiseHourSlotModal
                isOpen={popupVisible}
                onClose={() => setPopupVisible(false)}
                sessionUser={sessionUser}
                selectedTherapist={selectedTherapist}
                year={selectedDate.toISOString().substring(0, 4)}
                month={selectedDate.toISOString().substring(5, 7)}
                day={selectedDate.toISOString().substring(8, 10)}
                hour={hour}
                data={dayData?.hours[hour]}
                codes={codes}
                onSave={handleSetSaveFunc}
            />

        </Paper>

    );
};


const HourCircle = ({
                        data,
                        hasLabels,
                        currentIndex,
                        currentDate,
                        selectHour,
                        onDoubleClick,
                        displayOptions,
                        isDarkMode = false,
                        dayData,
                        sessionUser,
                        selectedTherapist,
                        clearSelection,
                        splitSelection,
                        setSelectedCode,
                        selectedCode,
                    }) => {
    const segmentMap = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 23, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    const containerRef = useRef(null);
    const [radius, setRadius] = useState(0);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [centerX, setCenterX] = useState(null);
    const [centerY, setCenterY] = useState(null);
    const {showMenu, hideMenu, menuState, updateMenuState} = useFloatingMenuContext();

    const isDisplayOption = (valueToCheck) => {
        const result = displayOptions.some(option => option.value === valueToCheck);
        return result
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            // Define a function to update the radius
            const updateRadius = () => {
                const width = container.offsetWidth;
                const height = container.offsetHeight; // Assuming a square, but adjust if not
                // Update state for radius, centerX, and centerY
                setRadius(width / 2);
                setCenterX(width / 2);
                setCenterY(height / 2); // Adjust this if your container is not a squar
            };

            // Create a ResizeObserver to observe the container
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    updateRadius(); // Call updateRadius whenever the container size changes
                }
            });

            // Start observing the container
            resizeObserver.observe(container);

            // Cleanup function to stop observing when the component unmounts
            return () => resizeObserver.disconnect();
        }
    }, []); // Empty dependency array ensures this effect runs only on mount and unmount


    // const [debugLine, setDebugLine] = useState({x1: 0, y1: 0, x2: 0, y2: 0, show: false});


    useEffect(() => {
        showMenu(menuState.x, menuState.y, {
            ...menuState,
            selectedCode: selectedCode,
        });
    }, [selectedCode]);



    const handleCircleClick = (e) => {





        const containerRect = e.currentTarget.getBoundingClientRect();
        const centerX = containerRect.left + containerRect.width / 2;
        const centerY = containerRect.top + containerRect.height / 2;
        const clickX = e.clientX;
        const clickY = e.clientY;

        const dx = clickX - centerX;
        // Original: const dy = centerY - clickY; Adjusting back for clarity
        const dy = clickY - centerY;

        const distanceFromCenter = Math.sqrt(dx * dx + dy * dy);
        const outerCircleRadius = radius;
        const innerCircleRadius = radius * 0.7; // Inner circle radius

        if (distanceFromCenter <= outerCircleRadius && distanceFromCenter >= radius * 0.4) {
            // Correcting angle to start from the top and increase clockwise
            // Inside handleCircleClick, after calculating dx and dy
            let angle = Math.atan2(dy, dx) * (180 / Math.PI)
            angle = (angle + 360 + 60) % 360; // Normalize angle

            // Calculate segment index; note that segments are 0-indexed but displayed as 1-indexed
            const segmentPerCircle = 12;
            let segmentIndex = Math.floor(angle / (360 / segmentPerCircle));

            // Adjust for clicks on the outer circle, if necessary
            if (distanceFromCenter < innerCircleRadius) {
                segmentIndex += 12; // For outer circle segments
            }

            setSelectedSegment(segmentIndex);
            selectHour(segmentMap.indexOf(segmentIndex))

            console.log("Hour Selected :", segmentMap.indexOf(segmentIndex))

            const handleCloseContextMenu = () => {
                hideMenu();
            };



            let title = "Selection"
            showMenu(clickX + 50, clickY, {
                title: title,
                selectedCode: selectedCode,
                setSelectedCode: setSelectedCode,
                onClear: clearSelection,
                onSplit: splitSelection,
                onClose: handleCloseContextMenu
            });

        } else {
            hideMenu()
            setSelectedSegment(null)
            selectHour(-1)
        }
    };

    const [animatedSegments, setAnimatedSegments] = useState([
        {segment: new Date().getHours() - 1, color: 'white'},
        {segment: selectedSegment, color: 'black'}
    ]);


    // Assuming setSelectedSegment stores the selected segment index
    const DrawSelectedSegment = (segment, background, color, drawOverlay = false, visible = true) => {

        const pathRef = useRef(null); // Ref to access the SVG path element
        const [pathLength, setPathLength] = useState(0); // State to store the path length

        useEffect(() => {
            if (pathRef.current) {
                const length = pathRef.current.getTotalLength();
                setPathLength(length);
            }
        }, [segment]); // Depend on `segment` to recalculate when it changes

        if (segment == null) return null;

        // Start and end angles adjusted to start from the top
        const segmentAngleStart = ((segment % 12) * 30) - 60;
        const segmentAngleEnd = segmentAngleStart + 30;

        // Convert angles to radians
        const startRadians = (Math.PI / 180) * segmentAngleStart;
        const endRadians = (Math.PI / 180) * segmentAngleEnd;

        // Radii
        const outerRadius = radius * 0.98; // Outer circle radius
        const innerRadius = radius * 0.7; // Inner circle radius
        const innermostRadius = radius * 0.4; // New innermost circle radius, for drawing inner segments

        // Points on the outer arc
        const outerStartX = outerRadius * Math.cos(startRadians) + centerX;
        const outerStartY = outerRadius * Math.sin(startRadians) + centerY;
        const outerEndX = outerRadius * Math.cos(endRadians) + centerX;
        const outerEndY = outerRadius * Math.sin(endRadians) + centerY;

        // Points on the inner arc
        const innerStartX = innerRadius * Math.cos(startRadians) + centerX;
        const innerStartY = innerRadius * Math.sin(startRadians) + centerY;
        const innerEndX = innerRadius * Math.cos(endRadians) + centerX;
        const innerEndY = innerRadius * Math.sin(endRadians) + centerY;

        // Points on the innermost arc, for inner segment adjustments
        const innermostStartX = innermostRadius * Math.cos(startRadians) + centerX;
        const innermostStartY = innermostRadius * Math.sin(startRadians) + centerY;
        const innermostEndX = innermostRadius * Math.cos(endRadians) + centerX;
        const innermostEndY = innermostRadius * Math.sin(endRadians) + centerY;

        let pathD;
        if (segment >= 12) { // Adjusting only the inner segment drawing
            // Path for inner segments now draws to the innermostRadius instead of the center
            pathD = `M${innermostStartX},${innermostStartY} ` +
                `L${innerStartX},${innerStartY} ` +
                `A${innerRadius},${innerRadius} 0 0,1 ${innerEndX},${innerEndY} ` +
                `L${innermostEndX},${innermostEndY} ` +
                `A${innermostRadius},${innermostRadius} 0 0,0 ${innermostStartX},${innermostStartY} ` +
                `Z`;
        } else { // Outer segment drawing remains as originally intended
            pathD = `M${outerStartX},${outerStartY} ` +
                `A${outerRadius},${outerRadius} 0 0,1 ${outerEndX},${outerEndY} ` +
                `L${innerEndX},${innerEndY} ` +
                `A${innerRadius},${innerRadius} 0 0,0 ${innerStartX},${innerStartY} ` +
                `L${outerStartX},${outerStartY} ` + // Closing the path back to the outer start
                `Z`;
        }


        let overlayPathD = '';
        if (drawOverlay) {
            const now = new Date();
            const minutes = now.getMinutes();
            const fillPercentage = minutes / 60; // Convert current minutes to a percentage of the hour

            // Determine end angle for the overlay based on fill percentage
            const overlayAngleEnd = segmentAngleStart + (30 * fillPercentage);
            const overlayEndRadians = (Math.PI / 180) * overlayAngleEnd;

            if (segment >= 12) {
                // Inner segment overlay
                const overlayInnermostEndX = innermostRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayInnermostEndY = innermostRadius * Math.sin(overlayEndRadians) + centerY;
                const overlayInnerEndX = innerRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayInnerEndY = innerRadius * Math.sin(overlayEndRadians) + centerY;

                overlayPathD = `M${innermostStartX},${innermostStartY} ` +
                    `L${innerStartX},${innerStartY} ` +
                    `A${innerRadius},${innerRadius} 0 0,1 ${overlayInnerEndX},${overlayInnerEndY} ` +
                    `L${overlayInnermostEndX},${overlayInnermostEndY} ` +
                    `A${innermostRadius},${innermostRadius} 0 0,0 ${innermostStartX},${innermostStartY} ` +
                    `Z`;
            } else {
                // Outer segment overlay
                const overlayOuterEndX = outerRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayOuterEndY = outerRadius * Math.sin(overlayEndRadians) + centerY;
                const overlayInnerEndX = innerRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayInnerEndY = innerRadius * Math.sin(overlayEndRadians) + centerY;

                overlayPathD = `M${outerStartX},${outerStartY} ` +
                    `A${outerRadius},${outerRadius} 0 0,1 ${overlayOuterEndX},${overlayOuterEndY} ` +
                    `L${overlayInnerEndX},${overlayInnerEndY} ` +
                    `A${innerRadius},${innerRadius} 0 0,0 ${innerStartX},${innerStartY} ` +
                    `L${outerStartX},${outerStartY} ` +
                    `Z`;
            }
        }

        const dashLength = pathLength / 4;
        const dashArray = `${dashLength} ${dashLength}`;

        if (!visible) {
            return null
        }

        let fillColor = 'white'
        if (isDarkMode) {
            fillColor = '#1e1e1e'
        }

        return (

            <>
                {/* Original Path */}
                <path
                    d={pathD}
                    fill="none"
                    stroke={background}
                    strokeWidth="0.4vw"
                />
                <path
                    d={pathD}
                    fill="none"
                    stroke={color}
                    strokeWidth="0.3vw"
                />
                {/* Overlay path element */}
                {drawOverlay && (
                    <path
                        d={overlayPathD}
                        fill={fillColor}
                        fillOpacity="0.5" // Adjust opacity as needed
                    />
                )}
                {/*}  <path d={pathD}
                      fill="none"
                      stroke={'white'}
                      strokeWidth="0.1vw"
                      ref={pathRef}
                      strokeDasharray={dashArray}
                      strokeDashoffset={dashLength} // Initial offset
                >
                    {pathLength > 0 && (
                        <animate
                            attributeName="stroke-dashoffset"
                            from={dashLength}
                            to={`-${dashLength}`} // Move the dash along the path
                            dur="4s"
                            repeatCount="indefinite"
                        />
                    )}
                </path> */}
            </>
        );
    };

    const generateGradientString = (colors) => {
        const segmentSize = 360 / colors.length; // Assuming 24 colors for a full circle
        return colors.map((color, index) => `${color} ${index * segmentSize}deg ${(index + 1) * segmentSize}deg`).join(', ');
    };

    const toRoman = (num) => {
        const lookup = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
        };
        let roman = '';
        for (let i in lookup) {
            while (num >= lookup[i]) {
                roman += i;
                num -= lookup[i];
            }
        }
        return roman;
    };

    const drawLabels = ({
                            hour,
                            radius,
                            currentIndex,
                            useRomanNumerals = false,
                            isDarkMode = false,
                            timeZoneOffset = 0
                        }) => {
        const calculateLabelPosition = (hour, radius, isSmallCircle) => {
            const adjustmentFactor = isSmallCircle ? 0.55 : 0.85; // Adjust if the smaller circle is a different size
            const adjustedRadius = radius * adjustmentFactor;

            // Assuming the circle's center is (0,0)
            const angleRad = ((hour % 12) * 30) * (Math.PI / 180) - (90 * (Math.PI / 180)); // Convert degrees to radians
            const x = adjustedRadius * Math.cos(angleRad);
            const y = adjustedRadius * Math.sin(angleRad);

            return {
                left: `calc(50% + ${x}px)`,
                top: `calc(50% + ${y}px)`,
                transform: `translate(-50%, -50%) rotate(${(hour % 12) * 30}deg)` // Add rotation
            };
        };

        if (currentIndex < hour) return null;

        let color = isDarkMode ? 'white' : '#1e1e1e';

        return (
            <CalendarLabel key={hour} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 7,
                color: color,
                userSelect: 'none',
            }}>
                {hasLabels && [...Array(24).keys()].map(hour => {
                    // Adjust hour based on timezone offset
                    const adjustedHour = (hour + timeZoneOffset + 24) % 24;

                    return (
                        <>
                            {/* Local Time Labels */}
                            <CalendarLabel key={`local-${hour}`} style={{
                                ...calculateLabelPosition(hour + 1, radius, hour >= 12),
                                fontSize: '1.5vw',
                                color: color,
                                fontFamily: "'Arial', sans-serif"
                            }}>
                                <CalendarNumbers>
                                    {useRomanNumerals ? (hour < 12 && toRoman(hour + 1)) : hour + 1}
                                </CalendarNumbers>
                            </CalendarLabel>

                            {/* Draw timezone-adjusted labels ONLY if the offset is non-zero */}
                            {timeZoneOffset !== 0 && (
                                <CalendarLabel key={`tz-${hour}`} style={{
                                    ...calculateLabelPosition(hour, radius * (hour >= 12 ? 0.55 : 0.9)),
                                    fontSize: '1vw',
                                    color: isDarkMode ? 'gray' : 'gray',
                                    fontFamily: "'Arial', sans-serif"
                                }}>
                                    <CalendarNumbers>
                                        {adjustedHour}
                                    </CalendarNumbers>
                                </CalendarLabel>
                            )}
                        </>
                    );
                })}
            </CalendarLabel>
        );
    };

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const tick = () => {
            setCurrentTime(new Date());
        };

        // Update the time every second (1000 milliseconds)
        const intervalId = setInterval(tick, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const formatDate = (date) => {
        const dayOfWeek = date.toLocaleDateString('en-GB', {weekday: 'long'});
        const month = date.toLocaleDateString('en-GB', {month: 'long'});
        const dayOfMonth = date.getDate();

        let ordinalIndicator;
        switch (dayOfMonth) {
            case 1:
            case 21:
            case 31:
                ordinalIndicator = 'st';
                break;
            case 2:
            case 22:
                ordinalIndicator = 'nd';
                break;
            case 3:
            case 23:
                ordinalIndicator = 'rd';
                break;
            default:
                ordinalIndicator = 'th';
        }

        const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}${ordinalIndicator}`;

        return formattedDate;
    };

    const formatTime = (date) => {
        const options = {hour: '2-digit', minute: '2-digit'};
        return date.toLocaleTimeString('en-GB', options);
    };

    const SegmentTextPath = ({
                                 segmentIndex,
                                 text,
                                 centerX,
                                 centerY,
                                 inner,
                                 outer,
                                 fontsize,
                                 color = 'red',
                                 isOuter = true,
                                 noRotation = false,
                                 offset = 0,

                             }) => {
        const totalSegments = 12;
        const degreesPerSegment = 360 / totalSegments;
        const outerRadius = isOuter ? outer : inner; // Adjust these radii as needed
        const pathRadius = centerX * outerRadius; // Assuming centerX is half of the clock's width

        const segmentAngleStart = ((segmentIndex % 12) * degreesPerSegment) - 90 - offset;
        const startAngleRadians = (Math.PI / 180) * segmentAngleStart;
        const endAngleRadians = (Math.PI / 180) * (segmentAngleStart + degreesPerSegment);

        const startX = centerX + pathRadius * Math.cos(startAngleRadians);
        const startY = centerY + pathRadius * Math.sin(startAngleRadians);
        const endX = centerX + pathRadius * Math.cos(endAngleRadians);
        const endY = centerY + pathRadius * Math.sin(endAngleRadians);

        const largeArcFlag = segmentIndex % 12 > 12 ? 1 : 0; // Use large arc flag for segments spanning more than half the circle

        const pathId = `path-${segmentIndex + text + startX + endX}`;
        const pathD = `M ${startX} ${startY} A ${pathRadius} ${pathRadius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;

        const midAngleRadians = startAngleRadians + (endAngleRadians - startAngleRadians) / 2;
        const emojiX = centerX + (pathRadius * Math.cos(midAngleRadians)) - fontsize / 2;
        const emojiY = centerY + (pathRadius * Math.sin(midAngleRadians)) + fontsize / 2;

        return !noRotation ? (
                <g>
                    <path id={pathId} d={pathD} fill="none" stroke="transparent"/>
                    <text fontSize={fontsize} fill={color} style={{userSelect: "none"}}>
                        <textPath href={`#${pathId}`} startOffset="50%" style={{textAnchor: 'middle'}}>
                            {text}
                        </textPath>
                    </text>
                </g>
            ) :
            <text fontSize={fontsize} x={emojiX} y={emojiY} fill={color} style={{userSelect: "none"}}
                  transform={`rotate(${0},${emojiX},${emojiY})`}>
                {text}
            </text>
            ;
    };

    const generateGradientStringFromCombinedData = (data, codes) => {
        let defaultColor = '#eeeeee'; // Fallback color for blank segments

        if (isDarkMode) {
            defaultColor = '#000000'
        }

        return data
            .map((hourData, hourIndex) => {
                const segments = generateGradientForHour(hourData, codes, true) || [];

                // Fill in any missing degrees in the hour
                let currentDegree = 0;
                const filledSegments = segments.flatMap((segment) => {
                    const blankSegment = {
                        color: defaultColor,
                        start: currentDegree,
                        end: segment.start,
                    };
                    currentDegree = segment.end;
                    return currentDegree > segment.start
                        ? [blankSegment, segment]
                        : [segment];
                });

                // Add a blank segment for the remainder of the hour
                if (currentDegree < 30) {
                    filledSegments.push({
                        color: defaultColor,
                        start: currentDegree,
                        end: 30,
                    });
                }

                // Offset each hour's degrees by 30 per hour
                const hourOffset = hourIndex * 30;
                return filledSegments
                    .map((segment) => ({
                        color: segment.color,
                        start: segment.start + hourOffset,
                        end: segment.end + hourOffset,
                    }))
                    .map((segment) => `${segment.color} ${segment.start}deg ${segment.end}deg`)
                    .join(', ');
            })
            .filter(Boolean) // Remove empty hours
            .join(', ');
    };


    const generateGradientForHour = (hourData, codes, includeDividers = true) => {
        const totalMinutes = 60; // Total minutes in an hour
        const degreesPerHour = 30; // Max degrees allocated for one hour
        const dividerColor = '#ffffff'; // Adjust based on theme
        const dividerThickness = 0.25; // Thin divider within existing space

        let currentDegree = 0;
        const changes = Array.isArray(hourData?.hourData?.status)
            ? hourData.hourData.status
            : Object.values(hourData?.hourData?.status || []);

        let gradientSegments = [];

        // Calculate how much space is available for actual segments after dividers
        const totalDividers = includeDividers ? Math.max(0, changes.length - 1) : 0;
        const totalDividerSpace = includeDividers ? totalDividers * dividerThickness : 0;
        const availableDegrees = degreesPerHour - totalDividerSpace;

        changes.forEach((change, index) => {
            if (!change || !change.minutes) return;

            // Adjust segment size to fit within available space
            const segmentDegrees = (change.minutes / totalMinutes) * availableDegrees;
            const startDegree = currentDegree;
            const endDegree = currentDegree + segmentDegrees;
            currentDegree += segmentDegrees;

            const codeInfo = codes.find((code) => code.id === change.codeId);

            // Add gradient segment
            gradientSegments.push({
                color: codeInfo ? codeInfo.background : '#eeeeee',
                start: startDegree,
                end: endDegree,
            });

            // Insert a thin divider **only if there's another segment and dividers are included**
            if (includeDividers && index < changes.length - 1) {
                gradientSegments.push({
                    color: dividerColor,
                    start: endDegree,
                    end: endDegree + dividerThickness,
                });

                currentDegree += dividerThickness; // Adjust for divider width
            }
        });

        return gradientSegments;
    };

    let fillcolor = 'white'
    if (isDarkMode) {
        fillcolor = '#1e1e1e'
    }

    const {codes} = useCodes();

    let allCodes = [];
    if (dayData != null) {
        const hour = new Date().getHours()
        let cellData = getCellData(dayData, hour, codes, "code", isDarkMode, false)
        allCodes = cellData.map(data => ({
            code: data.code,
            duration: data.duration || 60,
            background: data.backgroundColor,
            foreground: data.foregroundColor,
            halfLuminance: data.halfLuminance,
        }));
    }


    const MinuteArc = ({centerX, centerY, radius, isDarkMode}) => {
        const [currentMinutes, setCurrentMinutes] = useState(new Date().getMinutes());

        useEffect(() => {
            const interval = setInterval(() => {
                setCurrentMinutes(new Date().getMinutes());
            }, 1000); // Update every second for smooth transitions
            return () => clearInterval(interval);
        }, []);

        const startAngle = -90; // Start at 12 o'clock
        const endAngle = startAngle + (currentMinutes / 60) * 360; // Extend based on minutes
        const largeArcFlag = currentMinutes > 30 ? 1 : 0; // Large arc for > 180 degrees

        // Convert angles to radians
        const startRadians = (Math.PI / 180) * startAngle;
        const endRadians = (Math.PI / 180) * endAngle;

        // Calculate start and end points
        const startX = centerX + radius * Math.cos(startRadians);
        const startY = centerY + radius * Math.sin(startRadians);
        const endX = centerX + radius * Math.cos(endRadians);
        const endY = centerY + radius * Math.sin(endRadians);

        const pathD = `
        M ${centerX} ${centerY}
        L ${startX} ${startY}
        A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
        Z
    `;

        let fillColor = 'rgba(255, 0, 0, 0.3)'; // Red with transparency
        if (isDarkMode) {
            fillColor = 'rgba(255, 255, 255, 0.3)'; // White with transparency for dark mode
        }

        return <path d={pathD} fill={fillColor}/>;
    };


    const getTimeZoneOffset = (timeZone) => {
        if (!timeZone) return 0; // Default to 0 if no timezone is set
        return DateTime.now().setZone(timeZone).offset / 60; // Convert minutes to hours
    };


    return (

        <HourCircleContainer
            ref={containerRef}
            onClick={handleCircleClick}
            onDoubleClick={onDoubleClick}
            style={{
                margin: 'auto',
                position: 'relative',
            }}
        >

            <Circle className="large"
                    style={{background: `conic-gradient(${generateGradientStringFromCombinedData(data.slice(0, 12), codes)})`}}></Circle>
            <Circle style={{width: '70%', height: '70%'}}/>
            <Circle className="small"
                    style={{background: `conic-gradient(${generateGradientStringFromCombinedData(data.slice(12, 24), codes)})`}}></Circle>
            <Circle style={{width: '40%', height: '40%', background: fillcolor}}/>
            {!isDisplayOption('nohands') && (
                <Circle style={{width: '5%', height: '5%', background: fillcolor}}/>)}

            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 6}}
                 pointerEvents="none">
                {DrawSelectedSegment(segmentMap[new Date().getHours()], 'white', 'red', true, isToday(currentDate))}
                {DrawSelectedSegment(selectedSegment, 'white', '#aaaaaa', false)}
            </svg>

            {selectedTherapist && hasLabels && [...Array(24).keys()].map(hour => (
                drawLabels({
                    hour,
                    radius,
                    currentIndex,
                    useRomanNumerals: isDisplayOption('numerals'),
                    isDarkMode,
                    timeZoneOffset: getTimeZoneOffset(selectedTherapist.timeZone)
                })
            ))}

            {isDisplayOption('labels') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}>
                    {[...Array(24).keys()].map(hour => (
                        <SegmentTextPath
                            key={hour}
                            segmentIndex={hour}
                            text={data[hour]?.label}
                            centerX={centerX}
                            centerY={centerY}
                            inner={0.63}
                            outer={0.9}
                            fontsize={'0.6vw'}
                            color={data[hour]?.foreground}
                            isOuter={hour < 12}/>
                    ))}
                </svg>
            )}

            {isDisplayOption('emojis') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}>
                    {[...Array(24).keys()].map(hour => (
                        <SegmentTextPath
                            key={hour}
                            segmentIndex={hour}
                            text={data[hour]?.emoji}
                            centerX={centerX}
                            centerY={centerY}
                            inner={0.53}
                            outer={0.8}
                            fontsize={'32'}
                            isOuter={hour < 12}
                            noRotation={isDisplayOption('aligned')}
                        />
                    ))}
                </svg>
            )}

            {isDisplayOption('labels') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}>
                    {[...Array(24).keys()].map(hour => (
                        <SegmentTextPath
                            key={hour}
                            segmentIndex={hour}
                            text={data[hour]?.note && data[hour]?.note.trim() !== "" && data[hour]?.note.trim() !== "<p></p>" ? "🗒️" : ""}
                            centerX={centerX}
                            centerY={centerY}
                            inner={0.63}
                            outer={0.93}
                            fontsize={'16'}
                            color={data[hour]?.foreground}
                            isOuter={hour < 12}
                            offset={12}
                            noRotation={isDisplayOption('aligned')}/>
                    ))}
                </svg>
            )}

            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 5}}
                 pointerEvents="none">
                <ClockMinuteDividers centerX={centerX} centerY={centerY} radius={radius} isDarkMode={isDarkMode}/>
                <ClockMinuteDividers centerX={centerX} centerY={centerY} radius={radius * 0.71}
                                     isDarkMode={isDarkMode}/>
                {!isDisplayOption('nohands') && (
                    <ClockSecondDividers centerX={centerX} centerY={centerY} radius={radius * 0.4}
                                         isDarkMode={isDarkMode}/>
                )}


            </svg>

            {!isDisplayOption('nohands') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}
                     pointerEvents="none">
                    <ClockHands style={{zIndex: 8}} centerX={centerX} centerY={centerY} radius={radius}
                                smooth={isDisplayOption('smooth')} isDarkMode={isDarkMode}/>
                </svg>
            )}

            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 11}}
                 pointerEvents="none">
                <RenderCodeBadge width={radius * 2} height={radius * 2} radius={(radius / 3)} codes={allCodes}
                                 hasBackground={false}/>
            </svg>

            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}>
                <MinuteArc centerX={centerX} centerY={centerY} radius={radius * 0.4} isDarkMode={isDarkMode}/>
            </svg>


        </HourCircleContainer>


    );
};


const ClockHands = ({centerX, centerY, radius, smooth = true, isDarkMode = false}) => {
    const now = new Date();
    const hours = now.getHours() % 12 + now.getMinutes() / 60;
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const hourAngle = (hours / 12) * 360;
    const minuteAngle = (minutes / 60) * 360;
    const secondAngle = (seconds / 60) * 360;
    const handWidth = radius * 0.02;

    // Light source angle (dynamic for testing)
    const [lightAngle, setLightAngle] = useState(135);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                setLightAngle((prev) => (prev - 5) % 360);
            } else if (event.key === 'ArrowRight') {
                setLightAngle((prev) => (prev + 5) % 360);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        console.log(`Light Angle: ${lightAngle}°`);
    }, [lightAngle]);

    // Convert light angle to x, y offset
    const lightRadians = (lightAngle * Math.PI) / 180;
    const shadowOffsetX = Math.cos(lightRadians) * radius * 0.03;
    const shadowOffsetY = Math.sin(lightRadians) * radius * 0.03;

    const createHandPath = (length) => `M ${centerX - handWidth} ${centerY} 
                                        L ${centerX + handWidth} ${centerY} 
                                        L ${centerX} ${centerY - length} Z`;

    const hourHandPath = createHandPath(radius * 0.65);
    const minuteHandPath = createHandPath(radius * 0.95);
    const secondHandPath = createHandPath(radius * 0.95);

    let color = isDarkMode ? 'black' : 'black';
    let shadowColor = 'rgba(0.2, 0.2, 0.2, 0.1)';

    const startTimeRef = useRef(performance.now());
    const requestRef = useRef();
    const [smoothSecondAngle, setSmoothSecondAngle] = useState(0);
    const [smoothHourAngle, setSmoothHourAngle] = useState(0);
    const [smoothMinuteAngle, setSmoothMinuteAngle] = useState(0);

    const updateClockHands = () => {
        const nowDate = new Date();
        const elapsed = (performance.now() - startTimeRef.current) / 1000; // Convert ms to seconds
        const preciseSeconds = nowDate.getSeconds() + nowDate.getMilliseconds() / 1000;
        const preciseMinutes = nowDate.getMinutes() + preciseSeconds / 60;
        const preciseHours = nowDate.getHours() % 12 + preciseMinutes / 60;

        setSmoothHourAngle((preciseHours / 12) * 360);
        setSmoothMinuteAngle((preciseMinutes / 60) * 360);
        setSmoothSecondAngle((preciseSeconds / 60) * 360);

        requestRef.current = requestAnimationFrame(updateClockHands);
    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(updateClockHands);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    let selectedSecondAngle = smooth ? smoothSecondAngle : secondAngle;
    let selectedHourAngle = smooth ? smoothHourAngle : hourAngle;
    let selectedMinuteAngle = smooth ? smoothMinuteAngle : minuteAngle;


    return (
        <>
            <p style={{position: 'absolute', top: '10px', left: '10px', color: color, zIndex: 999}}>
                Light Angle: {lightAngle}° (Use ← → to rotate)
            </p>
            <svg style={{
                transformOrigin: 'center center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 5
            }} pointerEvents="none">
                <path d={hourHandPath} fill={shadowColor}
                      transform={`translate(${shadowOffsetX}, ${shadowOffsetY}) rotate(${selectedHourAngle} ${centerX} ${centerY})`}/>
                <path d={minuteHandPath} fill={shadowColor}
                      transform={`translate(${shadowOffsetX}, ${shadowOffsetY}) rotate(${selectedMinuteAngle} ${centerX} ${centerY})`}/>
                <path d={secondHandPath} fill={shadowColor}
                      transform={`translate(${shadowOffsetX}, ${shadowOffsetY}) rotate(${selectedSecondAngle} ${centerX} ${centerY})`}/>

                <path d={hourHandPath} fill="none" stroke='#ffffff' strokeWidth={2}
                      transform={`rotate(${selectedHourAngle} ${centerX} ${centerY})`}/>
                <path d={minuteHandPath} fill="none" stroke='#ffffff' strokeWidth={2}
                      transform={`rotate(${selectedMinuteAngle} ${centerX} ${centerY})`}/>
                <path d={secondHandPath} fill="none" stroke='#ffffff' strokeWidth={2}
                      transform={`rotate(${selectedSecondAngle} ${centerX} ${centerY})`}/>

                <path d={hourHandPath} fill={color} transform={`rotate(${selectedHourAngle} ${centerX} ${centerY})`}/>
                <path d={minuteHandPath} fill={color}
                      transform={`rotate(${selectedMinuteAngle} ${centerX} ${centerY})`}/>
                <path d={secondHandPath} fill="red" transform={`rotate(${selectedSecondAngle} ${centerX} ${centerY})`}/>
            </svg>
        </>
    );
};


const ClockSecondDividers = ({centerX, centerY, radius, isDarkMode = false}) => {
    const dividers = [];

    let color = 'black'
    if (isDarkMode) {
        color = 'white'
    }

    // Total dividers: 60 (for each minute)
    for (let i = 0; i < 60; i++) {
        const angle = (i / 60) * 2 * Math.PI; // Convert divider index to radians
        const isHourMarker = i % 5 === 0; // Larger dividers for hours

        const outerRadius = radius * 0.98; // Outer point of divider on clock edge
        const innerRadius = isHourMarker ? radius * 0.91 : radius * 0.95; // Inner point set back a bit more for hours

        const x1 = centerX + outerRadius * Math.cos(angle);
        const y1 = centerY + outerRadius * Math.sin(angle);
        const x2 = centerX + innerRadius * Math.cos(angle);
        const y2 = centerY + innerRadius * Math.sin(angle);

        dividers.push(
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={isHourMarker ? color : color} // Different color or thickness for hour markers
                strokeWidth={isHourMarker ? "2" : "1"}
                key={i}
            />
        );
    }

    return <>{dividers}</>;
};


const ClockMinuteDividers = ({centerX, centerY, radius, isDarkMode = false}) => {
    const dividers = [];

    let color = 'black';
    if (isDarkMode) {
        color = 'white';
    }

    const segmentsPerHour = 12; // Total segments for hours (30 degrees per segment)
    const divisionsPerSegment = 12; // Total divisions in each hour segment (5 minutes each)

    // Loop through all hour segments and their 5-minute intervals
    for (let segment = 0; segment < segmentsPerHour; segment++) {
        const segmentStartAngle = (segment / segmentsPerHour) * 2 * Math.PI; // Start angle for this hour segment

        for (let division = 0; division < divisionsPerSegment; division++) {
            const angle = segmentStartAngle + (division / divisionsPerSegment) * (2 * Math.PI / segmentsPerHour);

            const isMajorDivider = division === 0; // Mark the start of each hour segment

            const outerRadius = radius * 0.98; // Outer point of divider
            const innerRadius = isMajorDivider ? radius * 0.91 : radius * 0.95; // Shorter dividers for non-major intervals

            const x1 = centerX + outerRadius * Math.cos(angle);
            const y1 = centerY + outerRadius * Math.sin(angle);
            const x2 = centerX + innerRadius * Math.cos(angle);
            const y2 = centerY + innerRadius * Math.sin(angle);

            dividers.push(
                <line
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={color}
                    strokeWidth={isMajorDivider ? "2" : "0"}
                    key={`${segment}-${division}`}
                />
            );
        }
    }

    return <>{dividers}</>;
};


export default HourCircle;
