import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import {UserListSelect} from "../ListBoxes/UserSelect";
import {
    Box,
    Button, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    useTheme
} from "@mui/material";
import firebase from "firebase/compat/app";
import {userCollectionPath} from "../Firestore/paths";
import {useFirebase} from "../App/App";
import {InviteModal, InvitesList, sendEmail} from "./invite";
import {httpsCallable} from "firebase/functions";
import {isUserOnline, useOnlineUsers, userLastActive} from "../Presence/presence";
import {useUsers} from "../ListBoxes/userService";
import {OnlineAvatar} from "./OnlineAvatar";
import BuildIcon from "@mui/icons-material/Build";
import {collection, onSnapshot} from "firebase/firestore";
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import DeleteIcon from "@mui/icons-material/Delete"
import PersonOffIcon from "@mui/icons-material/PersonOff"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PersonIcon from "@mui/icons-material/Person"

export default function Users({showActive, showInactive, showInvited}) {
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();

    const { functions } = useFirebase();
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');
    const deleteAuthUser = httpsCallable(functions, 'deleteAuthUser');
    const toggleAccount = httpsCallable(functions, 'toggleAccountStatus');
    const { me } = useOnlineUsers();
    const user = me();
    const isAdmin = user?.scopes?.includes('manage');

    const { users: activeUsers, auLoading } = useUsers(true);
    const { users: inactiveUsers, iuLoading } = useUsers(false);
    const [invites, setInvites] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const {onlineUsers} = useOnlineUsers();

    useEffect(() => {
        setFilteredUsers([
            ...(showActive ? activeUsers : []),
            ...(showInactive ? inactiveUsers : []),
            ...(showInvited ? invites : []),
        ]);
    }, [activeUsers, inactiveUsers, invites, auLoading, iuLoading, showActive, showInactive, showInvited]);

    useEffect(() => {
        const db = firebase.firestore();
        const unsubscribe = onSnapshot(collection(db, 'invites'), (snapshot) => {
            const invitesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInvites(invitesData);
        });

        return () => unsubscribe(); // Detach listener on unmount
    }, []);

    const onClickSendInvite = (user) => {
        sendEmail(sendEmailFunction,user.email)
    }

    const deleteDocument = async (collectionPath, documentId) => {
        try {
            await firebase.firestore().collection(collectionPath).doc(documentId).delete();
            console.log(`Document with ID ${documentId} has been deleted from ${collectionPath}.`);
        } catch (error) {
            console.error(`Error deleting document from ${collectionPath}:`, error);
            throw error;
        }
    };

    const deleteInvite = async (user) => {
        if (!user) {
            alert("Please select an invite to delete.");
            return;
        }
        await deleteDocument('invites', user.id);
    };


    const deactivateTherapist = async (user) => {
        if (!user) {
            alert("Please select a therapist to deactivate.");
            return;
        }
        try {
            await firebase.firestore().collection(userCollectionPath).doc(user.id).update({
                active: false
            });
            console.log(`Therapist with ID ${user.id} has been deactivated.`);

            await toggleAccount({ uid: user.id, disable: true });

        } catch (error) {
            console.error("Error deactivating therapist: ", error);
        }
    };


    const activateTherapist = async (user) => {
        if (!user) {
            alert("Please select a therapist to activate.");
            return;
        }
        try {
            await firebase.firestore().collection(userCollectionPath).doc(user.id).update({
                active: true
            });

            console.log(`Therapist with ID ${user.id} has been activated.`);

            await toggleAccount({ uid: user.id, disable: false });
        } catch (error) {
            console.error("Error activating therapist: ", error);
        }
    };


    const deleteSelectedInActiveUser = async (user) => {
        if (!user) {
            alert("Please select a therapist to remove.");
            return;
        }
        deleteAuthUser({ userId: user.id })
            .then((result) => {
                // Read result of the Cloud Function.
                console.log(result.data); // { success: true, message: 'User deleted successfully.' }
            })
            .catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.error("Error calling deleteAuthUser function:", message, details, code);
            });
    };

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell className="sm-removed">Role</TableCell>
                        <TableCell className="sm-removed">Email</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">{isAdmin && (<Tooltip title="Invite User"><IconButton onClick={() => setModalOpen(true)}><PersonAddIcon /></IconButton></Tooltip>)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { filteredUsers.map((user) => {
                        return (<TableRow className={user.active ? "" : "user-table-disabled"}>
                            <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <span className="xs-removed">
                                        <OnlineAvatar
                                            user={user}
                                            online={isUserOnline(user, onlineUsers)}
                                            lastActive={userLastActive(user, onlineUsers)}
                                        />
                                    </span>
                                    <Typography noWrap sx={{ display: 'flex', alignItems: 'center' }}>
                                        {user?.name}
                                        {user?.scopes?.includes('developer') && (
                                            <BuildIcon fontSize="small" sx={{ marginLeft: 0.5, verticalAlign: 'middle' }} />
                                        )}
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell className="sm-removed">{user.userType}</TableCell>
                            <TableCell className="sm-removed">{user.email}</TableCell>
                            <TableCell>{user.status === "pending" ? "Invited" : user.active ? "Active" : "Inactive"}</TableCell>
                            <TableCell align="right" className="actions">
                                {user.status !== 'pending' && user.active && isAdmin && (<Tooltip title="Deactivate"><IconButton onClick={(e) => deactivateTherapist(user)}><PersonOffIcon /></IconButton></Tooltip>)}
                                {user.status !== 'pending' && !user.active && isAdmin && (<Tooltip title="Reactivate"><IconButton onClick={(e) => activateTherapist(user)}><PersonIcon /></IconButton></Tooltip>)}
                                {user.status !== 'pending' && !user.active && isAdmin && (<Tooltip title="Delete"><IconButton onClick={(e) => deleteSelectedInActiveUser(user)} sx={{ml:1}}><DeleteIcon /></IconButton></Tooltip>)}
                                {user.status === 'pending' && isAdmin && (<Tooltip title="Resend Invite"><IconButton onClick={(e) => onClickSendInvite(user)} sx={{mr:1}}><ForwardToInboxIcon /></IconButton></Tooltip>)}
                                {user.status === 'pending' && isAdmin && (<Tooltip title="Delete"><IconButton onClick={(e) => deleteInvite(user)}><DeleteIcon /></IconButton></Tooltip>)}
                            </TableCell>
                        </TableRow>);
                    }) }
                </TableBody>
            </Table>
            <InviteModal open={modalOpen} onClose={() => setModalOpen(false)} />
        </TableContainer>
    );
}
