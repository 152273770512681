import React, { createContext, useContext, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';

const FeatureFlagsContext = createContext({});

export const FeatureFlagsProvider = ({ children }) => {
    const [flags, setFlags] = useState({});

    useEffect(() => {
        const fetchFlags = async () => {
            const remoteConfig = firebase.remoteConfig();
            remoteConfig.settings = {
                minimumFetchIntervalMillis: 60000, // Fetch updates every 1 min
            };

            try {
                await remoteConfig.fetchAndActivate();
                setFlags({
                    heatmap:
                        process.env.DEV_HEATMAP !== undefined
                            ? process.env.DEV_HEATMAP === "true"
                            : remoteConfig.getBoolean('heatmap'),
                    bookinginfo:
                        process.env.DEV_BOOKINGINFO !== undefined
                            ? process.env.DEV_BOOKINGINFO === "true"
                            : remoteConfig.getBoolean('bookinginfo'),
                });
            } catch (error) {
                console.error("Error fetching feature flags:", error);
            }
        };

        fetchFlags();
    }, []);

    return (
        <FeatureFlagsContext.Provider value={flags}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export const useFeatureFlags = () => {
    return useContext(FeatureFlagsContext);
};
