import React, { createContext, useContext } from 'react';

const SessionContext = createContext();

export const SessionProvider = ({ children, sessionUser }) => {
    return (
        <SessionContext.Provider value={sessionUser}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSession = () => useContext(SessionContext);

//const { scopes } = useSession();
