import {useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import {doc, getDoc, onSnapshot} from "firebase/firestore";

export const saveReport = async (name,chart,therapists,codes,unit,start,end) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    const reportsCollectionPath = `savedReports/${user.uid}/reports/`;

    try {
        const docRef = await db.collection(reportsCollectionPath).add({
            name: name,
            chart: chart,
            therapists: therapists.map((t => doc(db, `users/${t}`))),
            codes: codes.map((c) => doc(db, `codes/${c}`)),
            unit: unit,
            start: firebase.firestore.Timestamp.fromDate(start),
            end: firebase.firestore.Timestamp.fromDate(end),
        });

        return docRef.id;
    }
    catch(err){
        console.error(err);
    }
}

export const deleteReport = (id) => {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    const savedReportPath = `savedReports/${user.uid}/reports/${id}`;
    db.doc(savedReportPath).delete().catch(err => {
        console.error(err);
    });
}

export const useCustomSavedReports = () => {
    const [allSavedReports, setAllSavedReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const user = firebase.auth().currentUser;

    useEffect(() => {
        if (!user) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const db = firebase.firestore();

        console.log(`Saved report snapshot register for: ${user?.uid}`);

        const reportsCollectionPath = `savedReports/${user.uid}/reports`;
        const unsubscribe = db.collection(reportsCollectionPath).onSnapshot(querySnapshot => {
            console.log(`Saved report snapshot updated for: ${user?.uid}`);
            const reports = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    name: data.name,
                    chart: data.chart,
                    codes: data.codes.map(c => c.id),
                    start: data.start.toDate(),
                    end: data.end.toDate(),
                    therapists: data.therapists.map(t => t.id),
                    unit: data.unit,
                };
            });

            setAllSavedReports(reports);
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return { allSavedReports, isLoading, error };
};