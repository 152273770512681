// timeZoneService.js
import { getTimeZones } from "@vvo/tzdb";
import { DateTime } from "luxon";

class TimeZoneService {
    constructor() {
        this.timeZones = getTimeZones();
        this.validTimeZoneNames = new Set(this.timeZones.map((tz) => tz.name));

        // Basic mapping of time zones to default locales
        this.timeZoneToLocaleMap = {
            "America/New_York": "en-US",
            "America/Los_Angeles": "en-US",
            "Europe/London": "en-GB",
            "Europe/Paris": "fr-FR",
            "Europe/Berlin": "de-DE",
            "Asia/Tokyo": "ja-JP",
            "Asia/Shanghai": "zh-CN",
            "Australia/Sydney": "en-AU",
            "UTC": "en-GB",
        };
    }

    /**
     * Get all available time zones.
     * @returns {Array} List of time zones.
     */
    getAllTimeZones() {
        return this.timeZones;
    }

    /**
     * Get the best matching locale for a given time zone.
     * @param {string} timeZone - The time zone string (e.g., "America/New_York").
     * @returns {string} The matching locale.
     */
    getLocaleForTimeZone(timeZone) {
        return this.timeZoneToLocaleMap[timeZone] || "en-GB"; // Default fallback
    }

    /**
     * Converts a date-time from one time zone to another.
     * @param {string | Date} dateTime - The date-time string (ISO format) or Date object.
     * @param {string} fromTimeZone - Source time zone string (e.g., "America/New_York").
     * @param {string} toTimeZone - Target time zone string (e.g., "Europe/London").
     * @returns {string} The converted time in the target time zone.
     */


    convertTimeZone(dateTimeInput, fromTimeZone, toTimeZone) {
        // Validate time zones
        if (!fromTimeZone || !toTimeZone) {
            throw new Error("Both fromTimeZone and toTimeZone must be provided.");
        }

        // Convert the input date string to a JavaScript Date object
        const date = new Date(dateTimeInput);
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date-time input");
        }

        // Convert the JavaScript Date object to a Luxon DateTime in UTC
        const utcDateTime = DateTime.fromJSDate(date, { zone: "utc" });

        // Convert from UTC to the original time zone, then to the target time zone
        const zonedDate = utcDateTime.setZone(fromTimeZone);
        const convertedDate = zonedDate.setZone(toTimeZone);

        return convertedDate // Returns ISO 8601 format with correct time zone
    }

    isDSTActive(timeZone) {
        if (!timeZone) return false;
        return DateTime.now().setZone(timeZone).isInDST;
    };

    getTimezoneOffset(baseTimezone, targetTimezone) {
        if (!this.validTimeZoneNames.has(baseTimezone)) {
            throw new Error(`Invalid timeZone: ${baseTimezone}.`);
        }

        if (!this.validTimeZoneNames.has(targetTimezone)) {
            throw new Error(`Invalid timeZone: ${targetTimezone}.`);
        }

        const now = new Date();

        const baseTime = new Date(now.toLocaleString("en-US", { timeZone: baseTimezone }));

        // Convert `now` to the target time zone using `toLocaleString` and create a new Date
        const targetTime = new Date(now.toLocaleString("en-US", { timeZone: targetTimezone }));

        // Calculate the exact difference in minutes
        const offsetMinutes = Math.round((targetTime - baseTime) / 60000); // Fix rounding issues

        // Format offset into ±HH:MM format
        const sign = offsetMinutes >= 0 ? "+" : "-";
        const hours = String(Math.floor(Math.abs(offsetMinutes) / 60)).padStart(2, "0");
        const minutes = String(Math.abs(offsetMinutes) % 60).padStart(2, "0");

        return `${sign}${hours}:${minutes}`;
    }


    getFormattedDate(year, month, day, hour, startMinute, endMinute, fromTimeZone, toTimeZone) {
        // Convert inputs to integers
        year = parseInt(year, 10);
        month = parseInt(month, 10);
        day = parseInt(day, 10);
        hour = parseInt(hour, 10);
        startMinute = startMinute !== undefined ? parseInt(startMinute, 10) : 0;
        endMinute = endMinute !== undefined ? parseInt(endMinute, 10) : null; // Use null to check if supplied

        // Create a DateTime object in the source time zone
        let dateTime = DateTime.fromObject(
            { year, month, day, hour, minute: startMinute },
            { zone: fromTimeZone }
        );

        // Convert to target time zone
        let convertedDateTime = dateTime.setZone(toTimeZone);

        // Function to determine ordinal suffix
        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return day + "th";
            switch (day % 10) {
                case 1: return day + "st";
                case 2: return day + "nd";
                case 3: return day + "rd";
                default: return day + "th";
            }
        }

        // Function to format time in 24-hour format
        function formatTime(dt) {
            return dt.toFormat("HH:mm"); // 24-hour format (00:00 - 23:59)
        }

        // Get formatted day with suffix
        const dayWithSuffix = getOrdinalSuffix(convertedDateTime.day);

        // Format the final date string
        let formattedDate = `${convertedDateTime.toFormat("LLLL")} ${dayWithSuffix} ${convertedDateTime.year} ${formatTime(convertedDateTime)}`;

        // If endMinute is supplied, add "to xx:xx" at the end
        if (endMinute !== null) {
            // Calculate adjusted hours and minutes correctly
            let totalMinutes = hour * 60 + endMinute; // Convert to total minutes from start of the day
            let adjustedHour = Math.floor(totalMinutes / 60); // Get new hour after overflow
            let adjustedMinute = totalMinutes % 60; // Get remaining minutes

            let endDateTime = DateTime.fromObject(
                { year, month, day, hour: adjustedHour, minute: adjustedMinute },
                { zone: fromTimeZone }
            ).setZone(toTimeZone);

            formattedDate += ` to ${formatTime(endDateTime)}`;
        }

        return formattedDate;
    }

    formatTime(dateTimeInput, localTimeZone, dataTimeZone, showPlusOne = true) {
        // Ensure input is a valid Luxon DateTime object
        if (!dateTimeInput || !(dateTimeInput instanceof DateTime)) {
            console.error("Invalid dateTime passed to formatTime:", dateTimeInput);
            return "Invalid Time";
        }

        // Convert to local and data time zones
        const localDateTime = dateTimeInput.setZone(localTimeZone);
        const dataDateTime = dateTimeInput.setZone(dataTimeZone);

        // Extract hours and minutes
        const h = localDateTime.hour;
        const m = localDateTime.minute;

        // Extract **calendar date only** (YYYY-MM-DD format)
        const localDateStr = localDateTime.toISODate();
        const dataDateStr = dataDateTime.toISODate();

        // Convert to JavaScript Date objects
        const localDate = new Date(localDateStr);
        const dataDate = new Date(dataDateStr);

        // Compute exact **calendar day difference**
        const dayDifference = Math.round((localDate - dataDate) / (1000 * 60 * 60 * 24));


        // Format day difference with +/- notation
        let dayMarker = dayDifference !== 0 ? ` ${dayDifference > 0 ? "+" : ""}${dayDifference}` : "";

        if(showPlusOne) {
            return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}${dayMarker}`;
        }

        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
    };
}

// Export as a singleton
export default new TimeZoneService();
