import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Typography,
    IconButton,
    Card,
    CardContent,
    Menu,
    MenuItem,
    CardActions,

} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {saveUser} from "../Users/create";

const days = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

const hours = Array.from({length: 24}, (_, i) => `${i < 10 ? `0${i}` : i}:00`);

const initialHours = {
    'Sunday': [],
    'Monday': [{start: '08:00', end: '20:00'}],
    'Tuesday': [{start: '08:00', end: '20:00'}],
    'Wednesday': [{start: '08:00', end: '20:00'}],
    'Thursday': [{start: '08:00', end: '20:00'}],
    'Friday': [{start: '08:00', end: '20:00'}],
    'Saturday': [],
};

function boolsToTimeSpans(bools) {
    const timeSpans = [];
    let startTime = null;
    bools.forEach((isWorked, hour) => {
        if (isWorked && startTime === null) {
            // Work starts at this hour
            startTime = hour;
        } else if (!isWorked && startTime !== null) {
            // Work ends at this hour
            timeSpans.push({
                start: `${startTime < 10 ? '0' : ''}${startTime}:00`,
                end: `${hour < 10 ? '0' : ''}${hour}:00`
            });
            startTime = null;
        }
    });
    // Handle case where the last hour is worked
    if (startTime !== null) {
        timeSpans.push({start: `${startTime < 10 ? '0' : ''}${startTime}:00`, end: '24:00'});
    }
    return timeSpans;
}

// Converts time spans back to an array of 24 booleans
function timeSpansToBools(timeSpans) {
    const bools = new Array(24).fill(false);
    timeSpans.forEach(({start, end}) => {
        const startHour = parseInt(start.split(':')[0], 10);
        const endHour = parseInt(end.split(':')[0], 10);
        for (let hour = startHour; hour < endHour; hour++) {
            bools[hour] = true;
        }
    });
    return bools;
}


export const WorkingHoursSelector = ({selectedUser, onCloseWindow}) => {
    const [workingHours, setWorkingHours] = useState(initialHours);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState({left: 0, top: 0});
    const [currentSelection, setCurrentSelection] = useState({day: '', index: -1, type: ''});


    const openMenu = (event, day, index, type) => {
        setMenuPosition({
            left: event.currentTarget.getBoundingClientRect().left,
            top: event.currentTarget.getBoundingClientRect().bottom,
        });
        setCurrentSelection({day, index, type});
        setMenuOpen(true);
    };

    const handleMenuItemClick = (hour) => {
        const updatedHours = {...workingHours};
        if (currentSelection.index !== -1) {
            updatedHours[currentSelection.day][currentSelection.index][currentSelection.type] = hour;
            updateWorkingHours(updatedHours);
        }
        setMenuOpen(false);

    };

    useEffect(() => {
        // Convert selectedUser's workingHours from 24-hour bools to time spans
        const convertedWorkingHours = {};
        Object.entries(selectedUser.workingHours).forEach(([day, bools]) => {
            convertedWorkingHours[day] = boolsToTimeSpans(bools);
        });
        setWorkingHours(convertedWorkingHours);
    }, [selectedUser]);


    const updateWorkingHours = async (newWorkingHours) => {
        // First, update the state
        setWorkingHours(newWorkingHours);

        // Convert the updated state to the 24-hour boolean format
        const updatedWorkingHoursBoolFormat = {};
        Object.entries(newWorkingHours).forEach(([day, spans]) => {
            updatedWorkingHoursBoolFormat[day] = timeSpansToBools(spans);
        });

        // Update the selectedUser's workingHours
        selectedUser.workingHours = updatedWorkingHoursBoolFormat;

        // Wait for saveUser to complete
        try {
            await saveUser(selectedUser);
            console.log("User saved successfully.");
        } catch (error) {
            console.error("Failed to save user:", error);
        }
    };



    const addTimeRange = (day) => {
        const updatedHours = {...workingHours, [day]: [...workingHours[day], {start: '08:00', end: '20:00'}]};
        updateWorkingHours(updatedHours);
    };

    const removeTimeRange = (day, index) => {
        const updatedRanges = [...workingHours[day]];
        updatedRanges.splice(index, 1);
        updateWorkingHours({...workingHours, [day]: updatedRanges});
    };

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: 2, gap: 0}}>
                {days.map(day => (
                    <Card key={day} variant="outlined" sx={{
                        minWidth: 200,
                        maxWidth: 'calc(9% - 8px)',
                        flexBasis: 'calc(9% - 8px)',
                        flexGrow: 1,
                        m: 1
                    }}>
                        <CardContent sx={{
                            '&:last-child': {pb: '8px'},
                            pt: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0.5
                        }}>
                            <Typography variant="subtitle1" gutterBottom>{day}</Typography>
                            {workingHours[day].length === 0 ? (
                                <Typography>No ranges set</Typography>
                            ) : (
                                workingHours[day].map((range, index) => (
                                    <Box key={index} sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                                        <IconButton color='primary' onClick={(e) => openMenu(e, day, index, 'start')} size="small">
                                            <AccessTimeIcon fontSize="inherit"/>
                                        </IconButton>
                                        <Typography sx={{
                                            minWidth: '40px',
                                            textAlign: 'center',
                                            fontSize: '0.875rem'
                                        }}>{range.start}</Typography>
                                        <IconButton color='primary'  onClick={(e) => openMenu(e, day, index, 'end')} size="small">
                                            <AccessTimeIcon fontSize="inherit"/>
                                        </IconButton>
                                        <Typography sx={{
                                            minWidth: '40px',
                                            textAlign: 'center',
                                            fontSize: '0.875rem'
                                        }}>{range.end}</Typography>
                                        <IconButton color = 'error' onClick={() => removeTimeRange(day, index)} size="small">
                                            <DeleteIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Box>
                                ))
                            )}
                        </CardContent>
                        <CardActions sx={{justifyContent: 'flex-start', pb: '8px'}}>
                            <Button size="small" startIcon={<AddIcon/>} onClick={() => addTimeRange(day)}>Add Range</Button>
                        </CardActions>
                    </Card>
                ))}
            </Box>
            <Menu
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                anchorReference="anchorPosition"
                anchorPosition={menuOpen ? {top: menuPosition.top, left: menuPosition.left} : undefined}>
                {hours.map((hour) => (
                    <MenuItem key={hour} onClick={() => handleMenuItemClick(hour)}>
                        {hour}
                    </MenuItem>
                ))}
            </Menu>
        </>

    );
};

export default WorkingHoursSelector;
