import React, {
    createContext,
    memo,
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Box, Divider, IconButton, MenuItem, MenuList, Paper, ToggleButton, Typography} from "@mui/material";
import {RenderCodeBadge} from "./codebadge";
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import CloseIcon from "@mui/icons-material/Close";
import {halfLuminance} from "../Codes/data";
import {CodeDropdownSelect} from "../ListBoxes/CodeSelect";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeOutlinedIcon from '@mui/icons-material/WatchLater';

const FloatingMenuContext = createContext(null);

export const ContextMenuProvider = ({ children }) => {
    const [menuState, setMenuState] = useState({
        x: null,
        y: null,
        visible: false,
        title: "",
        selectedCode: null,
        showClock: false,
        clockToggle: () => {},
        onClose: () => {},
        onClear: () => {},
        onSplit: () => {},
        setSelectedCode: () => {}
    })

    const [pendingMenuState, setPendingMenuState] = useState(null); // Holds pending updates

    const showMenu = (x, y, options = {}) => {
        setMenuState({
            x,
            y,
            visible: true,
            ...options,
        });

        // Apply pending updates when opening
        if (pendingMenuState) {
            setMenuState((prev) => ({ ...prev, ...pendingMenuState }));
            setPendingMenuState(null);
        }
    };

    const hideMenu = () => {
        setMenuState((prev) => ({ ...prev, visible: false }));
    };

    const updateMenuState = (updates, defer = false) => {
        if (defer && menuState.visible) {
            // Store changes to apply later
            setPendingMenuState((prev) => ({ ...prev, ...updates }));
        } else {
            // Apply immediately
            setMenuState((prev) => ({ ...prev, ...updates }));
        }
    };

    useLayoutEffect(() => {
        if (pendingMenuState) {
            setMenuState((prev) => ({ ...prev, ...pendingMenuState }));
            setPendingMenuState(null);
        }
    }, [pendingMenuState]);

    return (
        <FloatingMenuContext.Provider value={{ showMenu, hideMenu, menuState, updateMenuState }}>
            {children}
            <FloatingMenu {...menuState} />
        </FloatingMenuContext.Provider>
    );
};


export const useFloatingMenuContext = () => useContext(FloatingMenuContext);

export const FloatingMenu = memo(() => {

    const { menuState } = useFloatingMenuContext();

    const {
        x,
        y,
        visible,
        title,
        selectedCode,
        setSelectedCode,
        onClear,
        onSplit,
        showClock,
        clockToggle
    } = menuState;


    const listBoxRef = useRef(null);
    const [position, setPosition] = useState({ x, y });
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [pinned, setPinned] = useState(false);
    const [localTime, setLocalTime] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [shouldRender, setShouldRender] = useState(visible);
    const [bufferedVisible, setBufferedVisible] = useState(visible);
    const [menuKey, setMenuKey] = useState(`${x}-${y}`);

    useEffect(() => {
        if (!pinned && visible) {
            setPosition({ x, y });
        }
    }, [x, y, pinned, visible]);

    useEffect(() => {
        if (visible) {
            setBufferedVisible(true);
            setShouldRender(true);
        } else if (!animating && !pinned) {
            setShouldRender(false);
        }
    }, [visible, animating, pinned]);

    useEffect(() => {
        if (visible && !pinned) {
            setMenuKey(`${x}-${y}`);
        }
    }, [visible, x, y, pinned]);

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [dragging]);

    const handleMouseDown = (e) => {
        e.preventDefault();
        if (e.button !== 0) return;
        setDragging(true);
        setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (!dragging) return;
        setPosition({ x: e.clientX - dragStart.x, y: e.clientY - dragStart.y });
    };

    const handleMouseUp = () => {
        if (!dragging) return;
        setDragging(false);
    };

    const handlePinToggle = () => setPinned((prev) => !prev);

    const handleClockToggle = () => {
        setLocalTime((prev) => {
            const newValue = !prev;
            clockToggle(newValue);
            return newValue;
        });
    };

    if (!shouldRender) return null;

    return (
        <AnimatePresence>
            {shouldRender && (
                <motion.div
                    key={menuKey}
                    initial={pinned ? false : { opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={pinned ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0 }}
                    transition={pinned ? { duration: 0, delay: 0 } : { duration: 0.5, ease: "easeInOut" }}
                    style={{
                        position: "absolute",
                        top: position.y,
                        left: position.x,
                        zIndex: 1000,
                    }}
                    onAnimationStart={() => setAnimating(true)}
                    onAnimationComplete={() => {
                        setAnimating(false);
                        if (!bufferedVisible && !pinned) {
                            setShouldRender(false);
                        }
                    }}
                >
                    <Paper sx={{ boxShadow: 3, borderRadius: 2, padding: 1 }}>
                        <Box
                            sx={{
                                backgroundColor: "#ddd",
                                padding: "6px",
                                cursor: "grab",
                                borderRadius: "2px 2px 0 0",
                                userSelect: "none",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                            onMouseDown={handleMouseDown}
                        >
                            <Typography variant="body2" align="left">
                                {(title || "").split("\n").map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </Typography>
                            <Box sx={{ display: "flex", gap: 0.5, ml: "auto", alignItems: "center" }}>
                                {showClock && (
                                    <ToggleButton value="clock" selected={localTime} onChange={handleClockToggle} size="small" sx={{ border: "none", padding: "4px", minWidth: "auto" }}>
                                        {localTime ? <AccessTimeOutlinedIcon fontSize="small" /> : <AccessTimeIcon fontSize="small" />}
                                    </ToggleButton>
                                )}
                                <ToggleButton value="pin" selected={pinned} onChange={handlePinToggle} size="small" sx={{ border: "none", padding: "4px", minWidth: "auto" }}>
                                    {pinned ? <PushPinIcon fontSize="small" /> : <PushPinOutlinedIcon fontSize="small" />}
                                </ToggleButton>
                                <IconButton size="small" onClick={menuState.onClose} sx={{ padding: "4px", minWidth: "auto" }}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>

                        <MenuList dense>
                            <MenuItem disableRipple onMouseDown={(e) => e.stopPropagation()}>
                                <Box sx={{width: "100%"}} ref={listBoxRef}>
                                    <CodeDropdownSelect
                                        selectedCode={selectedCode}
                                        setSelectedCode={setSelectedCode}
                                        size={0}
                                        onMouseDown={(e) => e.stopPropagation()}
                                    />
                                </Box>
                            </MenuItem>

                            <Divider sx={{ my: 1 }} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
                                <Box sx={{ flex: 2 }}>
                                    {[60, 30, 20].map((time) => (
                                        <MenuItem key={time} onClick={() => onSplit(selectedCode,time)}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, width: "100%" }}>
                                                <RenderCodeBadge
                                                    width={32}
                                                    height={32}
                                                    radius={15}
                                                    codes={Array.from({ length: 60 / time }, (_, idx) => ({
                                                        idx,
                                                        code: selectedCode?.code || '',
                                                        duration: time,
                                                        background: selectedCode?.background || '#fff',
                                                        foreground: selectedCode?.foreground || '#000',
                                                        halfLuminance: selectedCode?.halfLuminance || halfLuminance(selectedCode?.background || '#fff'),
                                                    }))}
                                                    hasBackground={false}
                                                />
                                                <Box sx={{ flexGrow: 1, textAlign: "left" , ml: 1}}>{time} mins</Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                    {[15, 10, 5].map((time) => (
                                        <MenuItem key={time} onClick={() => onSplit(selectedCode,time)}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, width: "100%" }}>
                                                <RenderCodeBadge
                                                    width={32}
                                                    height={32}
                                                    radius={15}
                                                    codes={Array.from({ length: 60 / time }, (_, idx) => ({
                                                        idx,
                                                        code: selectedCode?.code || '',
                                                        duration: time,
                                                        background: selectedCode?.background || '#fff',
                                                        foreground: selectedCode?.foreground || '#000',
                                                        halfLuminance: selectedCode?.halfLuminance || halfLuminance(selectedCode?.background || '#fff'),
                                                    }))}
                                                    hasBackground={false}
                                                />
                                                <Box sx={{ flexGrow: 1, textAlign: "left", ml: 1 }}>{time} mins</Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Box>
                            </Box>
                            <Divider sx={{ my: 1 }} />
                            <Box sx={{ flex: 2, px: 1 }}>
                                <MenuItem onClick={(e) => onClear(e)} sx={{ width: "100%" }}>
                                    <RenderCodeBadge width={32} height={32} radius={15} codes={[{ code: '',background: '#fff',foreground:'#000',duration: 60 }]} hasBackground={false} />
                                    <Box sx={{ flexGrow: 1, textAlign: "left", ml: 1 }}>Clear</Box>
                                </MenuItem>
                            </Box>
                        </MenuList>
                    </Paper>
                </motion.div>
            )}
        </AnimatePresence>
    );
});
