import React from 'react';
import {
    Box, Button, Divider,
    FormControl,
    InputLabel, List, ListItemButton,
    MenuItem,
    Paper,
    Select, Tooltip,
} from '@mui/material';

export function GenericSelector({ allValues, selectedValues, setSelectedValues, renderOption, tooltipText, inputLabelText, changeObserver = null }){
    const multiselect = Array.isArray(selectedValues);

    const handleChange = (event) => {
        let selection;

        if(multiselect){
            let newValues = event.target.value ?? [];

            if(newValues.includes(undefined)){
                return;
            }

            selection = allValues.filter(t => newValues.includes(t.id));
        } else {
            selection = allValues.find(code => code.id === event.target.value) || '';
        }

        setSelectedValues(selection);

        if(changeObserver){
            changeObserver(selection);
        }
    };

    function clearAllCodes(){
        setSelectedValues([]);
    }

    function selectAllCodes(){
        setSelectedValues(allValues);
    }

    let renderValue = null;
    let menuProps = {};

    if(multiselect){
        renderValue = () => selectedValues?.length > 0 ? `${selectedValues.length} selected` : null;
        menuProps = {PaperProps:{style:{maxHeight: 300,overflowY:'auto'}}};
    }

    return (
        <Paper sx={{width: '100%', padding: 1, userSelect : 'none'}}>
            <FormControl sx={{minWidth: 240}} variant="standard" fullWidth>
                <Tooltip title={tooltipText} arrow>
                    <InputLabel id="code-select-label">{inputLabelText}</InputLabel>
                </Tooltip>
                <Select
                    labelId="code-select-label"
                    id="code-select"
                    value={multiselect ? selectedValues.map(c=>c.id) ?? [] : selectedValues?.id || ''} // Prevents null errors
                    onChange={handleChange}
                    renderValue={renderValue}
                    multiple={multiselect}
                    MenuProps={menuProps}
                >
                    {/* Select All & Clear All Buttons */}
                    {multiselect && (<>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
                            <Button onClick={selectAllCodes} size="small">Select All</Button>
                            <Button onClick={clearAllCodes} size="small" color="secondary">Clear All</Button>
                        </Box>
                        <Divider />
                    </>)}

                    {/* Code Options */}
                    {allValues.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {renderOption(option)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
}

export function GenericListSelector({allValues, selectedValues, setSelectedValues, renderOption}){
    return (
        <List sx={{
            width: '100%', // Adjust width as needed
            maxHeight: '100%',
            overflowY: 'auto' // Enable vertical scrolling
        }}>
            {allValues.map((v) => (
                <ListItemButton
                    key={v.id}
                    selected={selectedValues?.id === v.id}
                    onClick={() => (setSelectedValues(v))}
                >{renderOption(v)}
                </ListItemButton>
            ))}
        </List>
    );
}

export default GenericSelector;
