import React, {useEffect, useState} from 'react';
import {addCode, createDefaultCode, deleteCode, editCode, useCodes} from './data';
import {CodeDropdownSelect} from "../ListBoxes/CodeSelect";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Paper,
    Toolbar, Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import DeleteIcon from "@mui/icons-material/Delete";
import {useOnlineUsers, UsersWithSameActivity} from "../Presence/presence";
import {JsonForms} from "@jsonforms/react";
import {materialCells} from "@jsonforms/material-renderers";
import renderers from "../FormUtils/FormRenderers";
import schema from "./schema.json"
import uischema from "./uischema.json"
import {getContrastingColor} from "../Color/color";
import SaveIcon from "@mui/icons-material/Save";
import CloseButton from "../Components/closeButton";
import AddIcon from "@mui/icons-material/Add";

const CodeList = ({onCloseWindow}) => {
    const [selectedCode, setSelectedCode] = useState('');
    const { me } = useOnlineUsers();
    const user = me();
    const isAdmin = user?.scopes?.includes('manage');
    const [canSave, setCanSave] = useState(true);
    const { codes } = useCodes();

    useEffect(() => {
        if(!selectedCode){
            setSelectedCode(codes[0]);
        }
    }, [codes]);

    const saveButtonClicked = async (e) => {
        e.preventDefault();
        if (selectedCode.id === -1) {
            await addCode(selectedCode);
            setSelectedCode(selectedCode);
        } else {
            if (selectedCode.id !== '') {
                await editCode(selectedCode.id, selectedCode);
                setSelectedCode(selectedCode);
            }
        }
    };

    const handleDeleteCode = async (codeId) => {
        if (!codeId) {
            console.log("No code selected for deletion");
            return;
        }
        await deleteCode(codeId);
        setSelectedCode(codes[0]);
    };

    return (
        <Paper className="window-container" sx={{ width: '80%', padding: 2, marginTop: 2}}>
            <AppBar position="static">
                <Toolbar variant='dense' padding={1}>
                    <div className="toolbar-container">
                        <div className="toolbar-element toolbar-start">
                            <div className="toolbar-icon">
                                <Tooltip
                                    title="Explore the Monthly Overview to see therapists' bookings at a glance. Each cell represents an hour of the day, organised by dates, providing a comprehensive view of availability and appointments throughout the month. Perfect for planning and managing your sessions efficiently."
                                    arrow>
                                    <IconButton color="inherit">
                                        <InsertEmoticonIcon/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Typography variant="h6">
                                Booking Codes
                            </Typography>
                        </div>
                        <div className="toolbar-element toolbar-centre">
                            <div style={{maxWidth: '300px'}}>
                                <CodeDropdownSelect
                                    selectedCode={selectedCode}
                                    setSelectedCode={setSelectedCode}
                                    inputLabelText="Code"
                                    tooltipText=""
                                />
                            </div>
                            {isAdmin && ( <Button style={{height: '40px'}} component="label" variant="contained" startIcon={<AddIcon/>} sx={{marginRight: 1}} onClick={(e) => {
                                createDefaultCode(e).then(value => setSelectedCode(value));
                            }}>New</Button>)}
                        </div>
                        <div className="toolbar-element toolbar-end">
                            <UsersWithSameActivity currentActivity={'codes'}/>
                            <CloseButton onCloseWindow={onCloseWindow}/>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Box mt={4} border={1} borderColor="grey.500" borderRadius={2} p={2} sx={{height: '500px', mb: 2, overflowY: 'auto'}}>
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    data={selectedCode}
                    renderers={renderers}
                    cells={materialCells}
                    onChange={({ data, errors }) => {
                        if(data === "") {
                            return;
                        }

                        if(errors.length > 0) {
                            setCanSave(false);
                            console.error(errors);
                            return;
                        }

                        if(data.extraFields && data.extraFields.find((e) => !e.format)) {
                            setCanSave(false);
                            return;
                        }

                        for(const i in data.extraFields) {
                            if(!data.extraFields[i].label) {
                                continue;
                            }

                            data.extraFields[i].field_id = data.extraFields[i].label.replaceAll(' ', '_').toLowerCase();

                            if(data.extraFields[i].format === "uri" || data.extraFields[i].format === "patient_id" || !data.extraFields[i].format) {
                                data.extraFields[i].type = "string";
                            } else {
                                data.extraFields[i].type = data.extraFields[i].format;
                            }
                        }

                        if(data.foreground !== selectedCode.foreground){
                            data.background = getContrastingColor(data.foreground);
                        } else if(data.background !== selectedCode.background){
                            data.foreground = getContrastingColor(data.background);
                        }

                        setCanSave(true);
                        setSelectedCode(data);
                        console.log("Changed Code: ", data);
                    }}
                />
            </Box>
            {isAdmin && ( <Button disabled={!canSave} variant='contained' type='button' startIcon={<SaveIcon/>} onClick={saveButtonClicked}>Save</Button>)}
            {isAdmin && ( <Button sx={{ml:1}} variant='contained' type="button" onClick={() => handleDeleteCode(selectedCode.id)} color="error" startIcon={<DeleteIcon/>}>Remove</Button>)}
        </Paper>
    );
};

export default CodeList;