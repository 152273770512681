import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Button, Popover} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PickEmoji from "../../Codes/emojiPicker";
import React, {useRef} from "react";


const EmojiSelectComponent = ({value, onChange}) => {
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const popoverAnchorRef = useRef(null);

    return (
        <Grid item container spacing={2}>
            <Grid item>
                {value ? (
                    <div>
                        <Typography>Emoji</Typography>
                        <span style={{fontSize: '32px'}}>{value}</span>
                    </div>
                ) : (
                    <div>
                        <Typography>Emoji</Typography>
                        <span style={{fontSize: '12px'}}>None</span>
                    </div>
                )}
            </Grid>
            <Grid item ref={popoverAnchorRef}>
                <Button startIcon={<AddIcon/>} aria-describedby="emoji-popover" onClick={() => {setPopoverOpen(true)}}>
                    Add Emoji
                </Button>
            </Grid>
            <Popover
                id="emoji-popover"
                open={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                anchorEl={popoverAnchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{p: 2}}>
                    <PickEmoji chosenEmoji={value} setChosenEmoji={(newValue) => onChange(newValue)} />
                </Typography>
            </Popover>
        </Grid>
    );
}

export default EmojiSelectComponent;