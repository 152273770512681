import React, { useEffect } from 'react';
import {MenuItem, FormControl, InputLabel, Select, Paper} from '@mui/material';

const options = [
    { value: 'pie', label: 'Pie' },
    { value: 'bar', label: 'Bar' },
    { value: 'stacked', label: 'Stacked' },
    { value: 'group', label: 'Grouped' },
];

export const ReportOptions = ({ onOptionsChange, customStyles, defaultSelection = 'pie' }) => {
    useEffect(() => {
        // Trigger initial option change with the default option
        onOptionsChange({ value: defaultSelection, label: options.find(option => option.value === defaultSelection).label });
    }, []);

    return (
        <Paper sx={{width: '100%', padding: 1, userSelect : 'none'}}>
            <FormControl variant="standard" style={customStyles} fullWidth>
                <InputLabel id="report-option-select-label">Chart Options</InputLabel>
                <Select
                    labelId="report-option-select-label"
                    id="report-option-select"
                    defaultValue={defaultSelection}
                    onChange={(e) => onOptionsChange(options.find(option => option.value === e.target.value))}
                    label="Chart Options"
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
};
