export const unpackSchema = (schemaData) => {
    let required = [];
    let schema = {};

    for(const e of schemaData) {
        let id = e.field_id;

        if(e.is_required){
            required.push(id);
        }

        schema[id] = e;
    }

    return {"type":"object","properties": schema,"required":required};
}

export const generateUISchema = (schemaData) => {
    let elements = [];

    console.log(schemaData);

    const typeReplacements = {
        "uri": "open_link",
        "patient_id": "patient_id"
    }

    for(let k in schemaData) {
        let type = "Control";
        if(typeReplacements.hasOwnProperty(schemaData[k].format)){
            type = typeReplacements[schemaData[k].format];
        }

        elements.push({
            "type": type,
            "scope": `#/properties/${schemaData[k].field_id}`,
            "label": schemaData[k].label || k,
        });
    }

    return {
        "type": "VerticalLayout",
        "elements": elements,
    };
}