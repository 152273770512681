import { ResponsivePie } from "@nivo/pie";
import { useTheme } from "@mui/material";
import {GraphContainerStyle} from "../Styles/reporting";
import {calculateChartLabel} from "./helpers"

export const PieChart = ({ data, reportUnit }) => {
    const theme = useTheme();
    const textColor = theme.palette.mode === 'dark' ? '#ffffff' : '#333333';

    return (
        <div style={GraphContainerStyle}>
            <ResponsivePie
                data={data}
                colors={({data}) => data.color}
                margin={{top: 40, right: 40, bottom: 40, left: 40}}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={2}
                borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                arcLabel={e=> calculateChartLabel(e.value, reportUnit, true)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor={textColor}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{from: 'color'}}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
                tooltip={({datum}) => (
                    <div
                        style={{
                            padding: 12,
                            color: theme.palette.text.primary,
                            background: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                        }}
                    >
                        <strong>{datum.id}:</strong> {calculateChartLabel(datum.value, reportUnit)}
                    </div>
                )}
            />
        </div>
    );
};
