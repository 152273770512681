import React from 'react';
import {Grid, Button, Tooltip, TextField, FormControl, Paper} from '@mui/material';
import {CodeDropdownSelect} from "../ListBoxes/CodeSelect";

const GridActionsComponent = ({
                                  selectedCode,
                                  setSelectedCode,
                                  applyTitle,
                                  clearTitle,
                                  applyEnabled = true,
                                  onApply,
                                  onClear,
                                  displayMode,
                                  toggleDisplayMode,
                                  centerButtons = false,
                                  direction = 'row',
                                  canEdit = true,
                                    childContent = null
                              }) => {

    const buttons = (
        <>
            <Grid item>
                <Tooltip title={applyTitle} arrow>
                    {canEdit && (<Button variant="contained" size="small" disabled={!applyEnabled} onClick={onApply}>Apply</Button>)}
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title={clearTitle} arrow>
                    {canEdit && (<Button variant="contained" size="small" onClick={onClear}>Clear</Button>)}
                </Tooltip>
            </Grid>
            {displayMode !== undefined && (
                <Grid item>
                    <Tooltip title={
                        displayMode === 'code'
                            ? "Switch the cell views to the emojis representing the codes, go on, you know you want to."
                            : "Switch back to text codes, informative and for when you need to be serious."
                    } arrow>
                        <Button variant="contained" size="small" onClick={toggleDisplayMode}>
                            {displayMode === 'code' ? 'Emojis' : 'Codes'}
                        </Button>
                    </Tooltip>
                </Grid>
            )}
        </>
    );


    return (
        <div style={{display: 'flex', flexDirection: direction, height: '100%', gap: '20px'}}>
            <div>
                <CodeDropdownSelect
                    selectedCode={selectedCode}
                    setSelectedCode={setSelectedCode}
                    size={0}
                />
            </div>
            <div style={{display: 'flex', flex: '1 1 auto', alignItems: 'flex-start'}}>{childContent}</div>
            {centerButtons ? (
                <div>
                    <Grid item xs={12} container justifyContent={centerButtons ? "center" : "flex-start"} spacing={2}>
                        {buttons}
                    </Grid>
                </div>
            ) : (
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    {buttons}
                </div>
            )}


        </div>
    );
};

// Default props for optional titles
GridActionsComponent.defaultProps = {
    applyTitle: "Apply the selected code to the current selection.",
    clearTitle: "Clear the current selection",
};

export default GridActionsComponent;
